// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const LodgingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.21 7.08H15.27V2.9H16.25C16.5206 2.9 16.74 2.72985 16.74 2.52V1.38C16.74 1.17015 16.5206 1 16.25 1H8.41C8.1394 1 7.92 1.17015 7.92 1.38V2.52C7.92 2.72985 8.1394 2.9 8.41 2.9H9.39V7.08H6.45C5.09748 7.0813 4.00167 7.9311 4 8.98V20.76C4.00167 21.8089 5.09748 22.6587 6.45 22.66V23.42C6.45 23.6299 6.6694 23.8 6.94 23.8H8.9C9.1706 23.8 9.39 23.6299 9.39 23.42V22.66H15.27V23.42C15.27 23.6299 15.4894 23.8 15.76 23.8H17.72C17.9906 23.8 18.21 23.6299 18.21 23.42V22.66C19.5625 22.6587 20.6583 21.8089 20.66 20.76V8.98C20.6583 7.9311 19.5625 7.0813 18.21 7.08ZM8.9 1.76H15.76V2.14H8.9V1.76ZM10.37 2.9H14.29V7.08H10.37V2.9ZM8.41 23.04H7.43V22.66H8.41V23.04ZM17.23 23.04H16.25V22.66H17.23V23.04ZM19.68 20.76C19.68 21.3896 19.0218 21.9 18.21 21.9H6.45C5.6382 21.9 4.98 21.3896 4.98 20.76V8.98C4.98 8.35044 5.6382 7.84 6.45 7.84H18.21C19.0218 7.84 19.68 8.35044 19.68 8.98V20.76Z" />
      <path d="M12.33 8.60001C12.0594 8.60001 11.84 8.77015 11.84 8.98001V20.76C11.84 20.9699 12.0594 21.14 12.33 21.14C12.6006 21.14 12.82 20.9699 12.82 20.76V8.98001C12.82 8.77015 12.6006 8.60001 12.33 8.60001Z" />
      <path d="M16.25 9.36C15.9794 9.36 15.76 9.53015 15.76 9.74V20C15.76 20.2099 15.9794 20.38 16.25 20.38C16.5206 20.38 16.74 20.2099 16.74 20V9.74C16.74 9.53015 16.5206 9.36 16.25 9.36Z" />
      <path d="M8.41 9.36C8.1394 9.36 7.92 9.53015 7.92 9.74V20C7.92 20.2099 8.1394 20.38 8.41 20.38C8.6806 20.38 8.9 20.2099 8.9 20V9.74C8.9 9.53015 8.6806 9.36 8.41 9.36Z" />
    </svg>
  </SvgIcon>
)
