import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MediaDetails } from 'src/components/templates/explore/MediaDetails'
import { MediaState } from 'src/state/media'
import useStoreon from 'storeon/react'

export const MediaDetailsPage: FC = () => {
  const { id, mediaId } = useParams<{ id?: string; mediaId?: string }>()
  const history = useHistory()

  const { dispatch, media, isLoadingMedia } = useStoreon<MediaState>(
    'media',
    'isLoadingMedia'
  )

  const initialMediaItem = media.find(media => media.id === mediaId)

  useEffect(() => {
    dispatch('media/load', id)
    // mediaId invalid, probably a bad link. throw a 404 error
    if (!initialMediaItem && !isLoadingMedia) {
      dispatch('error/404')
    }
  }, [dispatch, id, initialMediaItem, isLoadingMedia])

  return (
    <MediaDetails
      media={media}
      isLoading={isLoadingMedia}
      initialMedia={initialMediaItem}
      onBack={() => history.goBack()}
    />
  )
}
