// @flow
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export type UserMarkerStyles = {
  marker: string
  beaconRipple: string
  markerWrapper: string
  arrow: string
}

export default makeStyles(theme => ({
  markerWrapper: {
    position: 'relative',
    transformOrigin: 'center'
    // transform: props => `skew(0, ${props.direction}deg)`
    // transform: props => `rotate(${props.direction}deg)`
  },
  marker: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 25,
    height: 25,
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '-1px 10px 10px -14px rgba(41,41,41,0.75)'
  },
  arrow: {
    position: 'absolute',
    top: -10,
    left: 4,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: `8px solid ${theme.palette.primary.main}`
  },
  beaconRipple: {
    width: 25,
    height: 25,
    borderRadius: 25,
    transformOrigin: 'center',
    position: 'absolute',
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    border: '1px solid #fff',
    animationName: '$beacon',
    animationDelay: '1s',
    animationDuration: '2s',
    animationIterationCount: 'infinite'
  },
  '@keyframes beacon': {
    from: {
      transform: 'scale(1)',
      opacity: 1
    },
    to: {
      transform: 'scale(2)',
      opacity: 0
    }
  }
}))
