import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { TourCardLoading } from 'src/components/atoms/loading/TourCardLoading'
import DestinationCard from 'src/components/molecules/DestinationCard'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import { Map } from 'src/components/organisms/Map'
import { PushNotification } from 'src/components/organisms/PushNotification'
import useStyles from 'src/components/templates/tours/Navigation/index.styles'
import { useAnalytics } from 'src/hooks/useAnalytics'
import Logo from 'src/images/logos/cu.png'
import { NavigationEvents, NavigationState } from 'src/state/navigation'
import { Location, MapCoordinates, Tour } from 'src/utils/types'
import useStoreon from 'storeon/react'

type NavigationProps = {
  schoolIcon?: any
  surveyLink?: string
  destination?: Location
  isLoadingDestination?: boolean
  universityCoordinates?: MapCoordinates
  universityName: string
}

export const Navigation = ({
  schoolIcon = Logo,
  destination,
  surveyLink,
  isLoadingDestination,
  universityCoordinates,
  universityName
}: NavigationProps) => {
  const [, setShowSurveyPopup] = useState(false)
  const [requestPermission, setRequestPermission] = useState(false)

  const mapWrapperRef = useRef<HTMLDivElement | null>(null)

  const history = useHistory()

  const { dispatch } = useStoreon<NavigationState & NavigationEvents>(
    'navigation/isHidden'
  )

  const styles = useStyles({
    navigationOn: !!destination,
    smallBackButton: true,
    toursExist: true
  })

  // state for analytics
  const [tourStartTime, setTourStartTime] = useState(-1)
  const { analytics } = useAnalytics()

  const requestDeviceAccess = async () => {
    try {
      if (window.DeviceOrientationEvent?.requestPermission) {
        const res = await window.DeviceOrientationEvent.requestPermission()
        if (res === 'granted') {
          setRequestPermission(false)
        } else {
          setRequestPermission(true)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setRequestPermission(false)
    }
  }

  useEffect(() => {
    requestDeviceAccess()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PushNotification
        visible={requestPermission}
        primaryAction={async () => {
          requestDeviceAccess()
        }}
        secondaryAction={() => setRequestPermission(false)}
        message="This app requires access to your device's motion events in order to properly orient you on tours."
      />
      <Header title={universityName} variant="HOME" schoolIcon={schoolIcon} />
      <Content
        title="My Tours"
        description="Explore everything UMich campus has to offer"
      >
        <div ref={mapWrapperRef} aria-hidden={destination ? 'false' : 'true'}>
          <Map initialPosition={universityCoordinates} />
        </div>

        <button onClick={history.goBack} className={styles.backButton}>
          <ChevronLeft fontSize="large" /> Back
        </button>

        <div className={styles.tourListWrapper}>
          <div className={styles.tourList}>
            <AnimatePresence>
              {isLoadingDestination && <TourCardLoading />}
            </AnimatePresence>

            {!!destination && (
              <DestinationCard
                title={destination.name}
                eventTitle={destination.eventTitle}
                coverImage={destination.coverImage}
                onTourEnd={() => {
                  analytics.logEvent({
                    category: 'Tours',
                    action: 'End Tour',
                    label: 'Tour Duration',
                    value: (Date.now() - tourStartTime) / 1000
                  })
                  dispatch('navigation/end')
                  setTourStartTime(-1)
                }}
                onTourClick={() => {
                  ReactGA.event({
                    category: "select_tour",
                    action: "select_tour",
                    label: destination.name,
                  })
                  history.push(`/explore/location/${destination.id}`)
                }}
                onEnd={() => {
                  analytics.logEvent({
                    category: 'Tours',
                    action: 'Completed Tour',
                    value: 1
                  })
                  surveyLink && setShowSurveyPopup(true)
                }}
              />
            )}
          </div>
        </div>
      </Content>
    </>
  )
}

/************* HELPER FUNCTIONS DEALING WITH LOCATIONS *****************/

export const isFirstDestination = (
  locations: Location[],
  currentDestination: Location
) => locations.findIndex(loc => loc.id === currentDestination.id) === 0
export const isLastDestination = (
  locations: Location[],
  currentDestination: Location
) =>
  locations.findIndex(loc => loc.id === currentDestination.id) ===
  locations.length - 1

export const getSiblingDestination = (
  locations: Location[],
  currentDestination: Location,
  dir: 1 | -1
) => {
  if (!currentDestination) {
    return undefined
  }
  const index = locations.findIndex(loc => loc.id === currentDestination.id)
  if (dir < 0) {
    return index > 0 ? locations[index + dir] : locations[index]
  }
  if (dir > 0) {
    return index < locations.length - 1
      ? locations[index + dir]
      : locations[index]
  }
}

export const getTourById = (id: string, tours?: Tour[]) =>
  tours && tours.find(tour => tour.id === id)

/***************************************************************************/
