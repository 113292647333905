// @flow
import MUIButton from '@material-ui/core/Button'
import React, { FC } from 'react'
import useStyles from 'src/components/atoms/buttons/Button/index.styles'

export const colorOptions = {
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  subtext: 'subtext',
  warning: 'warning',
  link: 'link'
}

export type ButtonColor = keyof typeof colorOptions

type ButtonProps = {
  className?: string
  onClick?: () => void
  removeMargin?: boolean
  variant?: 'text' | 'contained' | 'outlined'
  color?: ButtonColor
  lowerCase?: boolean
  disabled?: boolean
  fullWidth?: boolean
  tabIndex?: number
}

export const Button: FC<ButtonProps> = ({
  onClick,
  variant = 'text',
  color = 'inherit',
  lowerCase,
  removeMargin,
  children,
  tabIndex,
  ...props
}) => {
  const styles = useStyles({
    color,
    variant,
    lowerCase,
    removeMargin
  })
  return (
    <MUIButton
      {...props}
      variant={variant}
      tabIndex={tabIndex}
      classes={{ root: styles.root }}
      onClick={onClick}
    >
      {children}
    </MUIButton>
  )
}
