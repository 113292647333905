// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  tripDataLabel: {
    textAlign: 'center',
    //@ts-ignore
    color: theme.palette.text.subtext
  },
  tripDataValue: {
    textAlign: 'center',
    fontWeight: 500
  }
}))
