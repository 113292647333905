// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  tabsRoot: {
    minHeight: '40px !important',
    height: '42px !important'
  },
  tabRoot: {
    //@ts-ignore
    color: theme.palette.text.nonselected,
    whiteSpace: 'nowrap',
    fontSize: '0.75rem'
  },
  tabSelected: {
    color: theme.palette.primary.main
  },
  campusFilterContainer: {
    width: '75%',
    margin: 'auto'
  }
}))
