// @flow
import { getMediaByLocation } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import { Media } from 'src/utils/types'
import { Module } from 'storeon'

export type MediaState = {
  media: Media[]
  isLoadingMedia: boolean
  locationId: string
  loadMediaError: string
}

export const mediaModule: Module<MediaState & ConfigState> = store => {
  store.on('@init', () => ({
    media: [],
    locationId: '',
    isLoadingMedia: false,
    loadMediaError: ''
  }))

  store.on('media/load', async (state, locationId: string) => {
    try {
      if (locationId !== state.locationId && state.config) {
        store.dispatch('media/is-loading')
        const { data } = await getMediaByLocation(
          state.config.urls.wordpress_api_url,
          locationId
        )
        store.dispatch('media/loaded', { mediaItems: data, locationId })
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('media/is-loading', state => ({
    isLoadingMedia: true
  }))
  store.on(
    'media/loaded',
    (state, { mediaItems, locationId }: MediaLoadedEvent) => ({
      media: mediaItems,
      locationId,
      isLoadingMedia: false
    })
  )
}

type MediaLoadedEvent = {
  mediaItems: Media[]
  locationId: string
}
