import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import Modal from 'src/components/organisms/Modal'
import useStyles from 'src/components/templates/tours/TourSurvey/index.styles'

type TourSurveyProps = {
  open?: boolean
  onClose: () => void
  surveyLink: string
}

export const TourSurvey = ({
  surveyLink,
  open = false,
  onClose
}: TourSurveyProps) => {
  const styles = useStyles()
  return (
    <Modal
      id="create-tour-form"
      onClose={onClose}
      isOpen={open}
      withCloseButton
    >
      <Flex
        container
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="16px"
      >
        <Typography variant="h2" className={styles.header} align="center">
          How was your Visit?
        </Typography>
        <Typography variant="h6" className={styles.subheader} align="center">
          Take a quick survey and let us know how it went.
        </Typography>
        <a aria-label="Take Survey" href={surveyLink}>
          <Button tabIndex={-1} color="primary" variant="contained">
            Take Survey
          </Button>
        </a>
      </Flex>
    </Modal>
  )
}
