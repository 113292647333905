/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// @flow
import React, { FC, useRef, useState } from 'react'
import DraggableTourItem from 'src/components/molecules/DraggableTourItem'
import useStyles from 'src/components/organisms/EditTourList/index.styles'
import { Location, Tour } from 'src/utils/types'

type EditTourListProps = {
  tour: Tour
  onItemClick: (id: string) => void
  onOrderChange?: (locs: Location[]) => void
}

export const EditTourList: FC<EditTourListProps> = ({
  tour,
  onOrderChange,
  onItemClick
}) => {
  const styles = useStyles()
  const constraintRef = useRef(null)
  const [selectedItem, setSelectedItem] = useState(-1)
  const [entryArray, setEntryArray] = useState(tour.locations)

  const onSwap = (a: number, b: number) => {
    const tourArrayCopy = [...entryArray]
    const indexAValue = tourArrayCopy[a]
    tourArrayCopy[a] = tourArrayCopy[b]
    tourArrayCopy[b] = indexAValue
    setEntryArray(tourArrayCopy)
    onOrderChange && onOrderChange(tourArrayCopy)
  }

  return (
    <ul role="listbox" ref={constraintRef} className={styles.editTourList}>
      {entryArray.map((val, index) => {
        return (
          <DraggableTourItem
            key={val.id}
            length={entryArray.length}
            ref={constraintRef}
            selectedItem={selectedItem}
            onSelect={item => setSelectedItem(item)}
            onClick={id => onItemClick(id)}
            onSwap={onSwap}
            position={index}
            location={val}
          />
        )
      })}
    </ul>
  )
}
