// @flow
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ExploreCategories } from 'src/components/templates/explore/ExploreCategories'
import { CategoryState } from 'src/state/categories'
import { ConfigState } from 'src/state/config'
import useStoreon from 'storeon/react'

export const ExploreCategoriesPage: FC = () => {
  const {
    dispatch,
    config,
    categories,
    logoUrl,
    isLoadingCategories
  } = useStoreon<CategoryState & ConfigState>(
    'config',
    'categories',
    'logoUrl',
    'isLoadingCategories'
  )

  const history = useHistory()

  useEffect(() => {
    dispatch('categories/load')
  }, [dispatch])

  useEffect(() => {
    if (categories?.length === 1) {
      history.replace('/explore/list/0')
    }
  }, [categories, history])

  return (
    <ExploreCategories
      schoolIcon={logoUrl}
      universityName={config?.name || ''}
      loading={isLoadingCategories}
      categoryLinks={categories || []}
    />
  )
}
