// @flow
import { ExploreCategory } from 'src/components/templates/explore/ExploreCategories'
import { getCategories } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import { transformCategories } from 'src/utils/transformers'
import { Module } from 'storeon'

export type CategoryState = {
  categories: ExploreCategory[]
  isLoadingCategories: boolean
  loadCategoriesError: string
}

export const categoryModule: Module<CategoryState & ConfigState> = store => {
  store.on('@init', state => ({
    categories: [],
    isLoadingCategories: false,
    loadCategoriesError: ''
  }))
  store.on('categories/load', async state => {
    try {
      if (!state.categories.length && state.config) {
        store.dispatch('categories/is-loading')
        const { data } = await getCategories(
          state.config.urls.wordpress_api_url
        )
        store.dispatch('categories/loaded', transformCategories(data))
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('categories/is-loading', state => ({ isLoadingCategories: true }))
  store.on('categories/loaded', (state, categories: ExploreCategory[]) => ({
    categories,
    isLoadingCategories: false
  }))
}
