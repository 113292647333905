// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  card: {
    minWidth: 300,
    maxWidth: 300,
    color: theme.palette.text.secondary,
    backgroundColor: '#FFF',
    boxShadow: '0px 7px 24px 0px rgba(41,41,41,0.46)'
  },
  cardMedia: {
    height: 140,
    width: '100%',
    background: '#212120',
    backgroundSize: 'cover'
  },
  cardContent: {
    paddingBottom: 0
  },
  cardTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1)
  },
  cardBody: {
    // @ts-ignore
    color: theme.palette.text.subtext,
    minHeight: 45
  },
  tourActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    // @ts-ignore
    color: theme.palette.text.subtext,
    width: '100%',
    margin: `${theme.spacing(2)}px auto`
  },
  actionButton: {
    marginBottom: theme.spacing(1),
    width: '90%',
    marginRight: 0
  },
  tourCardPlaceholder: { position: 'absolute' },
  titleLoading: {
    width: 150,
    height: 12,
    marginBottom: theme.spacing(2),
    // @ts-ignore
    backgroundColor: theme.palette.text.subtext,
    borderRadius: 2
  },
  bodyLoading: {
    width: '100%'
  },
  imagePlaceholder: {
    width: '100%',
    height: 140,
    // @ts-ignore
    backgroundColor: theme.palette.text.subtext
  },
  placeholderLine: {
    width: '100%',
    height: 6,
    // @ts-ignore
    backgroundColor: theme.palette.text.subtext,
    marginBottom: theme.spacing(1.5),
    borderRadius: 2
  },
  buttonPlaceholder: {
    width: 120,
    height: 48,
    // @ts-ignore
    backgroundColor: theme.palette.text.subtext,
    marginRight: theme.spacing(2),
    borderRadius: 5
  }
}))
