/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React, { FC, TouchEvent, useEffect, useRef, useState } from 'react'
import useStyles from 'src/components/organisms/Carousel/index.styles'
import { easeCubic } from 'src/utils/easingFunctions'
import ExternalState from 'src/utils/externalState'

export type CarouselState = {
  target: number // index of target in array of items
  touchStartX: number
  touchX: number
  offsetX: number // offsetX is touch pos change since last frame
  deltaX: number // delta is the change from the start pos to end pos
  transitioning: boolean
}
type CarouselItemProps = {
  styles: string
  state: ExternalState<CarouselState>
  setSnapTarget: (targ: number) => void
  index: number
  initialTarget: number
  children?: any
}

export const CarouselItem = ({
  index,
  styles,
  state,
  initialTarget,
  setSnapTarget,
  children
}: CarouselItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isFullHeight, setIsFullHeight] = useState(true)

  const onUpdate = (nextState: CarouselState) => {
    if (ref.current && nextState.transitioning) {
      // if (nextState.target === index) {
      //   ref.current.setAttribute('aria-disabled', 'false')
      //   ref.current.setAttribute('aria-selected', 'true')
      // } else {
      //   ref.current.setAttribute('aria-disabled', 'true')
      //   ref.current.setAttribute('aria-selected', 'false')
      // }
    }
  }

  useEffect(() => {
    if (!ref.current) return
    const id = `carousel-item-${index}-${Math.random()}`
    state.addListener(id, onUpdate)

    window.addEventListener('touchStart', e => {
      setIsFullHeight(!isFullHeight)
    })

    // if (index === initialTarget) {
    //   ref.current.setAttribute('aria-disabled', 'false')
    //   ref.current.setAttribute('aria-selected', 'true')
    // } else {
    //   ref.current.setAttribute('aria-disabled', 'true')
    //   ref.current.setAttribute('aria-selected', 'false')
    // }
    return () => {
      state.removeListener(id, onUpdate)
    }
  }, [])

  return (
    <div
      ref={ref}
      // role="tab"
      // tabIndex={-1}
      className={styles}
      // onFocus={e => {
      //   setSnapTarget(index)
      // }}
    >
      {children}
    </div>
  )
}

const clampValue = (min: number, max: number, val: number) =>
  val > max ? max : val < min ? min : val

type CarouselProps = {
  initialTarget?: number
  itemWidth: number
  transitionDuration?: number
  onChange?: (index: number) => void
  hideButtons?: boolean
  children?: any
}

const initialState = {
  target: 0,
  touchStartX: 0,
  touchX: 0,
  offsetX: 0,
  deltaX: 0,
  transitioning: false
}
const state = new ExternalState<CarouselState>(initialState)

const Carousel: FC<CarouselProps> = ({
  itemWidth,
  onChange,
  hideButtons,
  children,
  initialTarget = 0,
  transitionDuration = 500
}) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [snapTarget, setSnapTarget] = useState(-1)
  const itemCount = React.Children.count(children)
  const carouselWidth = itemCount * itemWidth
  const styles = useStyles({
    itemWidth,
    carouselWidth
  })

  const animateCarousel = (
    toInitial: boolean = false,
    animStartTime: number | null = null,
    animStartPos: number | null = null
  ) => (timestamp: number) => {
    if (!carouselRef.current) return
    if (!animStartTime) animStartTime = timestamp

    //grab starting translateX() value from css transform property
    if (animStartPos === null)
      animStartPos = Number(
        carouselRef.current.style.transform.match(/(-?[0-9.]+)/g)
      )

    const progress = timestamp - animStartTime
    const t = progress / transitionDuration // 500ms

    if (carouselRef.current && containerRef.current) {
      const center =
        containerRef.current.clientWidth / 2 -
        itemWidth * (toInitial ? initialTarget : state.getState().target) -
        itemWidth / 2 -
        animStartPos
      const step = animStartPos + easeCubic(t) * center // initial + (easeStep * target)

      carouselRef.current.style.transform = `translateX(${step}px)`
    }

    if (t <= 1 && state.getState().transitioning) {
      window.requestAnimationFrame(
        animateCarousel(toInitial, animStartTime, animStartPos)
      )
    }
  }

  const onStateUpdate = (nextState: CarouselState) => {
    if (state.getState().transitioning) {
      window.requestAnimationFrame(animateCarousel())
    } else {
      if (Math.abs(nextState.offsetX) > 0 && carouselRef.current) {
        const carouselPosX =
          carouselRef.current.getBoundingClientRect().left + nextState.offsetX
        carouselRef.current.style.transform = `translateX(${carouselPosX}px)`
      }
    }
  }

  // center the initial target after refs are calculated
  useEffect(() => {
    const target = snapTarget > -1 ? snapTarget : initialTarget
    state.update({
      target
    })
    const id = `carousel-${Math.random()}`
    state.addListener(id, onStateUpdate)
    if (carouselRef.current && containerRef.current) {
      const center =
        containerRef.current.clientWidth / 2 -
        itemWidth * state.getState().target -
        (itemWidth / 2 + state.getState().deltaX)
      carouselRef.current.style.transform = `translateX(${center}px)`
    }
    return () => {
      state.removeListener(id, onStateUpdate)
      state.reset(initialState)
    }
  }, [snapTarget])

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0]
    state.update({
      touchStartX: touch.clientX,
      touchX: touch.clientX,
      transitioning: false
    })
  }

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0]
    const offsetX = touch.clientX - state.getState().touchX
    state.update({
      offsetX,
      touchX: touch.clientX,
      deltaX: touch.clientX - state.getState().touchStartX
    })
  }

  const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    let target = state.getState().target
    if (Math.abs(state.getState().deltaX) > 50) {
      const dir = state.getState().deltaX > 50 ? 1 : -1
      target = clampValue(0, itemCount - 1, state.getState().target - dir)
    }

    state.update({
      offsetX: 0,
      touchStartX: 0,
      touchX: 0,
      deltaX: 0,
      transitioning: true,
      target
    })

    onChange && onChange(target)
  }
  console.log(`${snapTarget}/${itemCount}`)
  return (
    <>
      <div ref={containerRef} className={styles.container}>
        {!hideButtons && (
          <>
            {snapTarget > 0 && (
              <IconButton
                aria-label={`go to tab ${snapTarget}/${itemCount}`}
                onClick={() => {
                  const target = snapTarget > 0 ? snapTarget - 1 : snapTarget
                  onChange && onChange(target)
                  setSnapTarget(target)
                }}
                className={styles.chevronLeft}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            )}
            {snapTarget < itemCount - 1 && (
              <IconButton
                tabIndex={snapTarget === itemCount - 1 ? -1 : 0}
                aria-label={`go to tab ${snapTarget + 2}/${itemCount}`}
                onClick={() => {
                  const target =
                    snapTarget < itemCount - 1 ? snapTarget + 1 : snapTarget
                  onChange && onChange(target)
                  setSnapTarget(target)
                }}
                className={styles.chevronRight}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            )}
          </>
        )}

        <div
          ref={carouselRef}
          className={styles.carousel}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          {children &&
            React.Children.map(children, (child, index) => (
              <CarouselItem
                key={`item-${index}`}
                styles={styles.carouselItem}
                index={index}
                state={state}
                initialTarget={initialTarget}
                setSnapTarget={target => {
                  onChange && onChange(target)
                  setSnapTarget(target)
                }}
              >
                {child}
              </CarouselItem>
            ))}
        </div>
      </div>
    </>
  )
}

export default Carousel
