// @flow
import { makeStyles } from '@material-ui/core/styles'

export type CarouselStyleProps = {
  carouselWidth: number
  itemWidth: number
}

export default makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    width: '100vw',
    flexShrink: 0
  },
  carouselItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: (props: CarouselStyleProps) => props.itemWidth,
    minWidth: (props: CarouselStyleProps) => props.itemWidth,
    height: '100%'
    // transitionProperty: 'opacity',
    // transitionDuration: props => `${props.transitionDuration / 1000}s`
  },
  carousel: {
    display: 'flex',
    width: (props: CarouselStyleProps) => props.carouselWidth,
    height: '100%'
  },
  chevronLeft: {
    width: 'auto',
    position: 'absolute',
    top: '20%',
    left: 10,
    color: '#FFF',
    opacity: 0.6,
    zIndex: 1000
  },
  chevronRight: {
    width: 'auto',
    position: 'absolute',
    top: '20%',
    right: 10,
    color: '#FFF',
    opacity: 0.6,
    zIndex: 1000
  }
}))
