// @flow
import React, { FC } from 'react'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import LocationMedia from 'src/components/molecules/LocationMedia'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles, {
  ExploreMediaStyles
} from 'src/components/templates/explore/ExploreMedia/index.styles'
import { Media } from 'src/utils/types'

type ExploreMediaProps = {
  locationName: string
  media: Media[]
  isLoading?: boolean
  onMediaClick: (id: string) => void
  onBack?: () => void
}

export const ExploreMedia: FC<ExploreMediaProps> = ({
  locationName,
  media,
  isLoading,
  onMediaClick,
  onBack
}) => {
  const styles: ExploreMediaStyles = useStyles()

  const galleryRows = renderGalleryPattern({ styles, media, onMediaClick })

  return (
    <>
      <Header onBack={onBack} title={locationName} variant="DEFAULT" />
      <Content
        title={`Media: ${locationName}`}
        description={`Explore images and videos of ${locationName}`}
      >
        {isLoading ? (
          <FullScreenLoading loading />
        ) : (
          <div className={styles.gallery}>{galleryRows}</div>
        )}
      </Content>
    </>
  )
}

type RenderGalleryGroup = {
  styles: ExploreMediaStyles
  media: Media[]
  size: 'wide' | 'tall'
  onMediaClick: (id: string) => void
}
export const renderGalleryGroup = ({
  styles,
  media,
  size,
  onMediaClick
}: RenderGalleryGroup) => (
  <div className={styles.galleryRow}>
    {media.map((mediaItem, index) => (
      <LocationMedia
        variant={size}
        key={index}
        url={mediaItem.url}
        alt={mediaItem.description}
        onClick={() => onMediaClick(mediaItem.id)}
      />
    ))}
  </div>
)

type RenderGalleryPatternProps = {
  styles: ExploreMediaStyles
  media: Media[]
  onMediaClick: (id: string) => void
  renderGroup?: (group: RenderGalleryGroup) => any
}

export const renderGalleryPattern = ({
  styles,
  media,
  onMediaClick,
  renderGroup = renderGalleryGroup
}: RenderGalleryPatternProps) => {
  const galleryRows = []
  const mediaCopy = [...media]
  while (mediaCopy.length) {
    //creates the wide version of an image
    galleryRows.push(
      renderGroup({
        styles,
        media: [mediaCopy.pop() as Media],
        size: 'wide',
        onMediaClick
      })
    )
    //creates a row of 3 tall images for every one wide image
    let itemsPerRow = 3
    const rowItems = []
    while (mediaCopy.length && itemsPerRow > 0) {
      rowItems.push(mediaCopy.pop() as Media)
      itemsPerRow--
    }
    if (rowItems.length)
      galleryRows.push(
        renderGroup({ styles, media: rowItems, size: 'tall', onMediaClick })
      )
  }
  return galleryRows
}
