import Button from '@material-ui/core/Button'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { TourCardLoading } from 'src/components/atoms/loading/TourCardLoading'
import { AddTourForm } from 'src/components/molecules/AddTour'
import DestinationCard from 'src/components/molecules/DestinationCard'
import TourCard from 'src/components/molecules/TourCard'
import TourEventCard from 'src/components/molecules/TourEventCard'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import { Map } from 'src/components/organisms/Map'
import Modal from 'src/components/organisms/Modal'
import { PushNotification } from 'src/components/organisms/PushNotification'
import useStyles from 'src/components/templates/tours/TourList/index.styles'
import { TourSurvey } from 'src/components/templates/tours/TourSurvey'
import { useAnalytics } from 'src/hooks/useAnalytics'
import Logo from 'src/images/logos/cu.png'
import { NavigationEvents, NavigationState } from 'src/state/navigation'
import {
  Ambassador,
  isTourEvent,
  Location,
  MapCoordinates,
  Tour,
  TourEvent
} from 'src/utils/types'
import useStoreon from 'storeon/react'

type TourListProps = {
  schoolIcon?: any
  activeTour?: Tour
  surveyLink?: string
  allowCustomTours?: boolean
  setAmbassador: (ambassador?: Ambassador) => void
  setActiveTour: (activeTour?: Tour, destination?: Location) => void
  destination?: Location
  universityCoordinates?: MapCoordinates
  universityName: string
  isLoadingTours?: boolean
  onTourEdit: (id: string) => void
  onTourClick?: (id: string) => void
  onTourCreate: (id: string) => void
  setNavOpen: (open: boolean) => void
  tours?: (Tour | TourEvent)[]
}

export const TourList = ({
  schoolIcon = Logo,
  activeTour,
  destination,
  setActiveTour,
  surveyLink,
  setAmbassador,
  universityCoordinates,
  onTourEdit,
  isLoadingTours,
  onTourCreate,
  onTourClick,
  universityName,
  setNavOpen,
  allowCustomTours,
  tours
}: TourListProps) => {
  const [showSurveyPopup, setShowSurveyPopup] = useState(false)
  const [addTourModalOpen, setAddTourModalOpen] = useState(false)
  const [requestPermission, setRequestPermission] = useState(false)

  const mapWrapperRef = useRef<HTMLDivElement | null>(null)

  const { dispatch } = useStoreon<NavigationState & NavigationEvents>(
    'navigation/isHidden'
  )

  const styles = useStyles({
    navigationOn: !!destination,
    toursExist: !!tours?.length
  })

  // state for analytics
  const [tourStartTime, setTourStartTime] = useState(-1)
  const { analytics, dataLayer } = useAnalytics()

  const requestDeviceAccess = async () => {
    try {
      if (window.DeviceOrientationEvent?.requestPermission) {
        const res = await window.DeviceOrientationEvent.requestPermission()
        if (res === 'granted') {
          setRequestPermission(false)
        } else {
          setRequestPermission(true)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setRequestPermission(false)
    }
  }

  const onTourStart = (id: string) => {
    const newActiveTour = getTourById(id, tours)

    const startTime = Date.now()
    setTourStartTime(startTime)

    dataLayer.push({
      event: 'startTime',
      startTime: Date.now(),
      tourLocation: ''
    })
    if (newActiveTour) {
      // set tour and current destination
      setActiveTour(newActiveTour, newActiveTour.locations[0])
    }
  }

  const onAmbassadorSelect = (ambassador?: Ambassador) => {
    if (ambassador) {
      analytics.logEvent({
        category: 'Ambassadors',
        action: 'Ambassador Selected',
        label: ambassador.name
      })
    }
    setAmbassador(ambassador)
  }

  useEffect(() => {
    if (destination) {
      setNavOpen(true)
    } else {
      setNavOpen(false)
    }

    requestDeviceAccess()

    return () => {
      setNavOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination])

  // work around for accessibility issues on android: map canvas is read by screen readers
  useEffect(() => {
    const el = mapWrapperRef.current
    const canvas = el?.getElementsByClassName('mapboxgl-canvas')[0]
    if (!addTourModalOpen && !destination) {
      canvas?.setAttribute('aria-hidden', 'true')
    } else if (!addTourModalOpen && destination) {
      canvas?.setAttribute('aria-hidden', 'false')
    }
  }, [addTourModalOpen, destination])

  return (
    <>
      {surveyLink && (
        <TourSurvey
          surveyLink={surveyLink}
          open={showSurveyPopup}
          onClose={() => setShowSurveyPopup(false)}
        />
      )}

      <PushNotification
        visible={requestPermission}
        primaryAction={async () => {
          requestDeviceAccess()
        }}
        secondaryAction={() => setRequestPermission(false)}
        message="This app requires access to your device's motion events in order to properly orient you on tours."
      />
      {!addTourModalOpen && (
        <Header title={universityName} variant="HOME" schoolIcon={schoolIcon}>
          {allowCustomTours && (
            <Button
              color="inherit"
              onClick={() => {
                setAddTourModalOpen(true)
                dispatch('navigation/isHidden', true)
              }}
              style={{ whiteSpace: 'nowrap', boxShadow: 'none' }}
            >
              CREATE TOUR
            </Button>
          )}
        </Header>
      )}
      <Content
        title="My Tours"
        description="Explore everything UMich campus has to offer"
      >
        {!addTourModalOpen && (
          <div ref={mapWrapperRef} aria-hidden={destination ? 'false' : 'true'}>
            <Map initialPosition={universityCoordinates} />
          </div>
        )}

        {!addTourModalOpen && (
          <div className={styles.tourListWrapper}>
            <div className={styles.tourList}>
              <AnimatePresence>
                {isLoadingTours && <TourCardLoading />}
              </AnimatePresence>

              {tours && !destination
                ? tours.map((tour, index) =>
                    isTourEvent(tour) ? (
                      <TourEventCard
                        key={tour.id}
                        title={tour.title}
                        content={tour.description}
                        coverImage={tour.featuredimg || ''}
                        isVisible={!activeTour}
                      />
                    ) : (
                      <TourCard
                        key={tour.id}
                        isCustom={tour.isCustom}
                        tourId={tour.id}
                        title={tour.title}
                        description={
                          tour.description ||
                          'Swipe through to select your tour guide first. Then when you arrive at each tour point on campus, watch a personal video from them about that location.'
                        }
                        coverImage={tour.locations[0]?.coverImage}
                        onTourEdit={onTourEdit}
                        onTourStart={onTourStart}
                        onAmbassadorSelect={onAmbassadorSelect}
                        isVisible={!activeTour || activeTour.id === tour.id}
                      />
                    )
                  )
                : ''}
              {destination ? (
                <DestinationCard
                  title={destination.name}
                  eventTitle={destination.eventTitle}
                  coverImage={destination.coverImage}
                  onTourClick={() => {
                    ReactGA.event({
                      category: "select_tour",
                      action: "select_tour",
                      label: destination.name,
                    })
                    onTourClick && onTourClick(destination.id)
                  }}
                  onTourEnd={() => {
                    analytics.logEvent({
                      category: 'Tours',
                      action: 'End Tour',
                      label: 'Tour Duration',
                      value: (Date.now() - tourStartTime) / 1000
                    })
                    setActiveTour(undefined, undefined)
                    dispatch('navigation/end')
                    setTourStartTime(-1)
                  }}
                  onPrevious={() => {
                    const dest =
                      activeTour &&
                      getSiblingDestination(
                        activeTour.locations,
                        destination,
                        -1
                      )
                    if (dest && activeTour) setActiveTour(activeTour, dest)
                  }}
                  onNext={() => {
                    const dest =
                      activeTour &&
                      getSiblingDestination(
                        activeTour.locations,
                        destination,
                        1
                      )
                    if (dest && activeTour) setActiveTour(activeTour, dest)
                  }}
                  onEnd={() => {
                    setActiveTour(undefined, undefined)
                    analytics.logEvent({
                      category: 'Tours',
                      action: 'Completed Tour',
                      value: 1
                    })
                    surveyLink && setShowSurveyPopup(true)
                  }}
                  isLastDestination={
                    !!activeTour &&
                    isLastDestination(activeTour.locations, destination)
                  }
                  disablePrev={
                    !!activeTour &&
                    isFirstDestination(activeTour.locations, destination)
                  }
                />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        <Modal
          id="create-tour-form"
          onClose={() => {
            setAddTourModalOpen(false)
            dispatch('navigation/isHidden', false)
          }}
          isOpen={addTourModalOpen}
          withCloseButton
        >
          <AddTourForm
            onSubmit={name => {
              analytics.logEvent({
                category: 'Tours',
                action: 'Tour Created',
                label: name
              })
              onTourCreate(name)
              setAddTourModalOpen(false)
              dispatch('navigation/isHidden', false)
            }}
          />
        </Modal>
      </Content>
    </>
  )
}

/************* HELPER FUNCTIONS DEALING WITH LOCATIONS *****************/

export const isFirstDestination = (
  locations: Location[],
  currentDestination: Location
) => locations.findIndex(loc => loc.id === currentDestination.id) === 0
export const isLastDestination = (
  locations: Location[],
  currentDestination: Location
) =>
  locations.findIndex(loc => loc.id === currentDestination.id) ===
  locations.length - 1

export const getSiblingDestination = (
  locations: Location[],
  currentDestination: Location,
  dir: 1 | -1
) => {
  if (!currentDestination) {
    return undefined
  }
  const index = locations.findIndex(loc => loc.id === currentDestination.id)
  if (dir < 0) {
    return index > 0 ? locations[index + dir] : locations[index]
  }
  if (dir > 0) {
    return index < locations.length - 1
      ? locations[index + dir]
      : locations[index]
  }
}

export const getTourById = (id: string, tours?: (Tour | TourEvent)[]) =>
  tours && tours.find(tour => tour.id === id)

/***************************************************************************/
