// @flow
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { AnimatePresence, motion } from 'framer-motion'
import React, { FC } from 'react'
import Div100vh from 'react-div-100vh'
import useStyles from 'src/components/organisms/Modal/index.styles'

type ModalProps = {
  id?: string
  isOpen: boolean
  onClose: () => void
  opaque?: boolean
  withCloseButton?: boolean
  children?: any
}

const Modal: FC<ModalProps> = ({
  id,
  isOpen,
  onClose,
  opaque = false,
  withCloseButton,
  children
}) => {
  const styles = useStyles({ opaque })
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles.modal}
          key={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3
          }}
        >
          <Div100vh className={styles.paper}>
            <div className={styles.background} />
            {withCloseButton && (
              <IconButton
                color="inherit"
                onClick={onClose}
                aria-label="close add tour form"
                className={styles.closeButton}
              >
                <CloseIcon className={styles.closeIcon} fontSize="large" />
              </IconButton>
            )}
            <div className={styles.modalContent}>{children}</div>
          </Div100vh>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
