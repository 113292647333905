// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  input: {
    '&::placeholder': {
      // @ts-ignore
      color: theme.palette.text.nonselected
    },
    fontSize: '1.5rem',
    textAlign: 'center'
  },
  underline: {
    '&&:before': {
      // @ts-ignore
      borderBottom: `1px solid ${theme.palette.text.nonselected}`
    },
    '&&:hover:not($disabled):before': {
      // @ts-ignore
      borderBottom: `1px solid ${theme.palette.text.nonselected}`
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    '&&$focused:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  label: {
    // @ts-ignore
    color: theme.palette.text.nonselected,
    width: '100%',
    textAlign: 'center',
    transformOrigin: 'center',
    fontSize: '1.5rem'
  },
  shrink: {
    transform: 'translate(0px, 0) scale(0.5)'
  },
  animated: {
    transition: theme.transitions.create(['color', 'transform', 'width'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    })
  }
}))
