// @flow
import { makeStyles } from '@material-ui/core/styles'
import BlurredMap from 'src/images/map-loading.jpg'

export type MapLoadingStyleProps = {
  loading?: boolean
}

export default makeStyles(theme => ({
  mapsLoadingContainer: {
    backgroundImage: `url(${BlurredMap})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    alignContent: 'center',
    opacity: (props: MapLoadingStyleProps) => (props.loading ? 1 : 0),
    pointerEvents: (props: MapLoadingStyleProps) =>
      props.loading ? 'auto' : 'none',
    transition: 'opacity 0.7s'
  }
}))
