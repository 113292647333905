import React, { FC, useCallback } from 'react'
import { MenuLink } from 'src/components/atoms/links/MenuLink'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/menu/ContentMenu/index.styles'
import Logo from 'src/images/logos/cu.png'
import { getLabelFromSlug } from 'src/utils/formatting'
import { MenuItem } from 'src/utils/types'
import { useContentUrl } from 'src/hooks/useContentUrl'

type ContentMenuTemplateProps = {
  schoolIcon?: any
  universityName: string
  section?: string
  onBack?: () => void
  menuItems?: MenuItem[]
}

export const ContentMenu: FC<ContentMenuTemplateProps> = ({
  schoolIcon = Logo,
  section,
  onBack,
  menuItems,
  universityName
}) => {
  const styles = useStyles()
  const contentUrl = useContentUrl(section)

  // const renderParentItems = useCallback(
  //   () =>
  //     menuItems?.map((item, i) => (
  //       <MenuLink
  //         key={item.id}
  //         variant={section ? 'sub' : 'main'}
  //         external={!item.childItems}
  //         to={
  //           item.childItems
  //             ? `menu/${item.slug}`
  //             : item?.url || `${contentUrl}/${item.slug}` // `menu/${String(item.slug)}/${String(item.slug)}`
  //         }
  //       >
  //         {item.label}
  //       </MenuLink>
  //     )),
  //   [menuItems, section, contentUrl]
  // )

  const renderParentItems = useCallback(
    () =>
      menuItems?.map((item, i) => {
        return (
          <MenuLink
            key={item.id}
            variant={section ? 'sub' : 'main'}
            external={!item.childItems && item.slug !== "thanks-for-visiting"}
            to={
              !item.childItems && item.slug === "thanks-for-visiting"
                ? `${item.slug}`
                : item.childItems
                  ? `menu/${item.slug}`
                  : item?.url || `${contentUrl}/${item.slug}`
            }
            url={item.url}
          >
            {item.label}
          </MenuLink>
        );
      }),
    [menuItems, section, contentUrl]
  );


  const renderSubItems = useCallback(
    () =>
      section &&
      menuItems
        ?.find(parent => parent.slug === section)
        ?.childItems?.map(subItem => (
          <MenuLink
            key={subItem.id}
            variant="sub"
            to={subItem?.url || `${contentUrl}/${subItem.slug}`}
            external
          >
            {subItem.label}
          </MenuLink>
        )),
    [menuItems, section, contentUrl]
  )

  return (
    <>
      <Header
        onBack={onBack}
        title={section ? 'Main Menu' : universityName}
        variant={section ? 'DEFAULT' : 'HOME'}
        schoolIcon={schoolIcon}
      />
      <Content
        title="University Content"
        description="Have questions about UMich? You can probably find answers here."
      >
        <div className={styles.contentWrapper}>
          <div className={styles.mainMenu}>
            {section && menuItems && (
              <MenuLink highlight variant="main" to={`/menu/${section}`}>
                {getLabelFromSlug(section, menuItems)}
              </MenuLink>
            )}
            {section ? renderSubItems() : renderParentItems()}
          </div>
        </div>
      </Content>
    </>
  )
}