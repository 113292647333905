// @flow
import React from 'react'
import { Helmet } from 'react-helmet'
import { SchoolConfig } from 'src/utils/types'

type MetaProps = {
  config: SchoolConfig
  storageUrl?: string
}

export const Meta = ({ config, storageUrl }: MetaProps) => {
  return !config ? (
    <div />
  ) : (
    <>
      <Helmet>
        <link rel="manifest" href={`${storageUrl}/manifest.json`} />

        <link
          rel="icon"
          type="image/png"
          sizes="196x196"
          href={`${storageUrl}/favicon-196.png`}
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${storageUrl}/apple-icon-180.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href={`${storageUrl}/apple-icon-167.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`${storageUrl}/apple-icon-152.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${storageUrl}/apple-icon-120.png`}
        />

        <meta name="apple-mobile-web-app-capable" content="yes" />

        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-1024-1366.png`}
          media="(device-width: 512px) and (device-height: 683px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-0-0.png`}
          media="(device-width: 0px) and (device-height: 0px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-834-1194.png`}
          media="(device-width: 417px) and (device-height: 597px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-768-1024.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-0-0.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-834-1112.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-810-1080.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-768-1024.png`}
          media="(device-width: 384px) and (device-height: 512px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-414-896.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-375-812.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-414-896.png`}
          media="(device-width: 138px) and (device-height: 298.6666666666667px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-0-0.png`}
          media="(device-width: 0px) and (device-height: 0px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-375-812.png`}
          media="(device-width: 125px) and (device-height: 270.6666666666667px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-414-896.png`}
          media="(device-width: 207px) and (device-height: 448px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-414-736.png`}
          media="(device-width: 138px) and (device-height: 245.33333333333334px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-375-667.png`}
          media="(device-width: 187.5px) and (device-height: 333.5px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-414-736.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-375-667.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${storageUrl}/apple-splash-320-568.png`}
          media="(device-width: NaNpx) and (device-height: NaNpx) and (-webkit-device-pixel-ratio: undefined) and (orientation: portrait)"
        />
      </Helmet>
    </>
  )
}
