// @flow
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useStyles from 'src/components/organisms/Header/index.styles'
import { ConfigState } from 'src/state/config'
import { NavbarState } from 'src/state/navbar'
import useStoreon from 'storeon/react'

export type HeaderVariant = 'DEFAULT' | 'TRANSPARENT' | 'HOME'
type HeaderProps = {
  onBack?: () => void
  title: string
  schoolIcon?: any
  variant?: HeaderVariant
  className?: string
  children?: any
}

export const Header = ({
  onBack,
  title,
  schoolIcon = '',
  className,
  children,
  variant = 'DEFAULT'
}: HeaderProps) => {
  const styles = useStyles({ variant })
  const history = useHistory()
  const { config } = useStoreon<ConfigState>('config')
  const { dispatch } = useStoreon<NavbarState>()

  const renderHeaderIcon = useCallback(
    () =>
      !config?.features.hide_logo && variant === 'HOME' ? (
        <img
          alt="header-school-icon"
          src={schoolIcon}
          className={styles.schoolIcon}
          data-test="header-school-icon"
        />
      ) : (
        variant !== 'HOME' && <ArrowBackIos data-test="header-back-icon" />
      ),
    [config?.features.hide_logo, schoolIcon, styles.schoolIcon, variant]
  )

  return (
    <AppBar
      position={variant === 'TRANSPARENT' ? 'fixed' : 'relative'}
      color="secondary"
      elevation={0}
      className={classnames(className, styles.appBar)}
    >
      <Toolbar className={styles.toolbar}>
        <Button
          name="home"
          onClick={
            variant === 'HOME'
              ? () => {
                  history.push('/explore')
                  dispatch('navbar/setIndex', 0)
                }
              : onBack
          }
          color="inherit"
          className={styles.backButton}
        >
          {renderHeaderIcon()}
          <Typography
            variant="body1"
            color="inherit"
            className={styles.title}
            data-test="header-title"
          >
            {title}
          </Typography>
        </Button>
        <div className={styles.actionButtonWrapper}>{children}</div>
      </Toolbar>
    </AppBar>
  )
}
