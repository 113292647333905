// @flow
import { getMenus } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import { transformMenus } from 'src/utils/transformers'
import { MenuItem } from 'src/utils/types'
import { Module } from 'storeon'

export type MenuState = {
  menus: MenuItem[]
  isLoadingMenus: boolean
  loadMenusError: string
}

export const menuModule: Module<MenuState & ConfigState> = store => {
  store.on('@init', () => ({
    menus: [],
    isLoadingMenus: false,
    loadMenusError: ''
  }))

  store.on('menus/load', async state => {
    try {
      if (!state.menus.length && state.config) {
        store.dispatch('menus/is-loading')
        const { data } = await getMenus(state.config.urls.wordpress_api_url)
        const menus = transformMenus(data.items)
        store.dispatch('menus/loaded', menus)
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('menus/is-loading', state => ({
    isLoadingMenus: true
  }))
  store.on('menus/loaded', (state, menuItems: MenuItem[]) => ({
    menus: menuItems,
    isLoadingMenus: false
  }))
}
