// @flow
import { makeStyles } from '@material-ui/core/styles'

export type FullScreenStyleProps = {
  loading?: boolean
}

export default makeStyles(theme => ({
  fullScreenContainer: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: (props: FullScreenStyleProps) => (props.loading ? 1 : 0),
    pointerEvents: (props: FullScreenStyleProps) =>
      props.loading ? 'auto' : 'none',
    transition: 'opacity 0.3s'
  }
}))
