// @flow
import { getSchoolConfig } from 'src/services/firebase'
import { SchoolConfig, SchoolDocument } from 'src/utils/types'
import { Module } from 'storeon'

export type ConfigState = {
  config?: SchoolConfig
  doc?: SchoolDocument
  isDraftMode?: boolean
  isLoadingConfig?: boolean
  loadConfigError?: string
  storageUrl?: string
  logoUrl?: string
  preTourFormSuccess?: boolean
  successMessageSeen?: boolean
}

export const configModule: Module<ConfigState> = store => {
  store.on('config/load', async (state, isDraft: boolean) => {
    try {
      store.dispatch('config/is-loading')
      const doc = (await getSchoolConfig()) as SchoolDocument

      store.dispatch('config/loaded', doc)
      store.dispatch('config/mode', true)
      // check if the user has taken and passed the pre tour screening form (if one was required at all)
      const cachedFormResults = localStorage.getItem('preTourFormSuccess')
      const successMessageSeen = localStorage.getItem('successMessageSeen')
      if (cachedFormResults !== null) {
        store.dispatch(
          'config/preTourFormSuccess/set',
          cachedFormResults === 'true'
        )
      }
      if (successMessageSeen !== null) {
        store.dispatch(
          'config/success-message-seen',
          successMessageSeen === 'true'
        )
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('config/mode', (state, isDraft) => ({
    config: isDraft ? state.doc?.draftConfig : state.doc?.liveConfig,
    isDraftMode: isDraft,
    storageUrl: `${process.env.REACT_APP_STORAGE_URL}/${
      isDraft
        ? state.doc?.draftConfig.sub_domain
        : state.doc?.liveConfig.sub_domain
    }/${isDraft ? 'draftConfig' : 'liveConfig'}`,
    logoUrl: `${process.env.REACT_APP_STORAGE_URL}/${
      isDraft
        ? state.doc?.draftConfig.sub_domain
        : state.doc?.liveConfig.sub_domain
    }/${isDraft ? 'draftConfig' : 'liveConfig'}/school_logo.svg`
  }))

  store.on('config/is-loading', state => ({
    isLoadingConfig: true
  }))
  store.on('config/loaded', (state, doc) => ({
    doc,
    isLoadingConfig: false
  }))
  store.on(
    'config/preTourFormSuccess/set',
    (state, preTourFormSuccess: boolean) => {
      localStorage.setItem(
        'preTourFormSuccess',
        preTourFormSuccess ? 'true' : 'false'
      )
      return {
        preTourFormSuccess
      }
    }
  )

  store.on(
    'config/success-message-seen',
    (state, successMessageSeen: boolean) => {
      localStorage.setItem(
        'successMessageSeen',
        successMessageSeen ? 'true' : 'false'
      )
      return {
        successMessageSeen
      }
    }
  )
}
