// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  photoCarouselItem: {
    width: window.innerWidth,
    height: 300
  },
  locationName: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  mainActions: {
    transform: 'scale(0.9)',
    display: 'flex',
    alignItems: 'center',
    minHeight: 75
  },
  detailedHeader: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  tourActionsHeader: {
    fontWeight: 500,
    paddingBottom: theme.spacing(1)
  },
  ambassadorDropdown: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tourActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2)
  },
  //@ts-ignore
  description: { color: theme.palette.text.nonselected },
  descriptionWrapper: {
    margin: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  videos: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginBottom: 0
  }
}))
