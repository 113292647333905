// @flow
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'
import React, { FC } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import { Flex } from 'src/components/molecules/Flex'
import useStyles from 'src/components/organisms/AmbassadorHeader/index.styles'
import { Ambassador } from 'src/utils/types'

type AmbassadorHeaderProps = {
  ambassador: Ambassador
  onAmbassadorSelect: (ambassador: Ambassador) => void
}

export const AmbassadorHeader: FC<AmbassadorHeaderProps> = ({
  ambassador,
  onAmbassadorSelect
}) => {
  const styles = useStyles({
    profileImage: ambassador.profileImage
  })
  return (
    <Flex container flexDirection="column" alignItems="center">
      <div className={styles.image} />
      <Flex
        container
        flex="1"
        alignItems="center"
        margin="0 0 16px 0"
        maxWidth="85%"
      >
        <div>
          <Typography
            component="h1"
            variant="h2"
            className={styles.name}
            align="center"
          >
            {ambassador.name}
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            className={styles.major}
            align="center"
          >
            {ambassador.year} - {ambassador.major}
          </Typography>
        </div>
      </Flex>
      <Flex justifyContent="center">
        <Button
          removeMargin
          onClick={() => onAmbassadorSelect(ambassador)}
          className={styles.tourButton}
          color="primary"
          variant="contained"
        >
          Tour With {ambassador.name}
        </Button>
        {ambassador.email ? (
          <MuiButton
            fullWidth
            onClick={() => {
              window.location.href = `mailto:${String(
                ambassador.email
              )}?subject=Student%Inquiry%20here`
            }}
            className={styles.tourButton}
            startIcon={<EmailIcon />}
            color="primary"
            variant="outlined"
          >
            Connect
          </MuiButton>
        ) : (
          ''
        )}
      </Flex>

      <div className={styles.divider} />
      {ambassador.bio ? (
        <div className={styles.section}>
          <Typography
            component="h3"
            variant="h5"
            className={styles.sectionTitle}
          >
            Fun Fact
          </Typography>
          <Typography variant="body2" className={styles.sectionBody}>
            {ambassador.bio}
          </Typography>
        </div>
      ) : (
        ''
      )}
      {ambassador.hometown ? (
        <div className={styles.section}>
          <Typography
            component="h3"
            variant="h5"
            className={styles.sectionTitle}
          >
            Hometown
          </Typography>
          <Typography variant="body2" className={styles.sectionBody}>
            {ambassador.hometown}
          </Typography>
        </div>
      ) : (
        ''
      )}

      {ambassador.interests.length ? (
        <div className={styles.section}>
          <Typography
            component="h3"
            variant="h5"
            className={styles.sectionTitle}
          >
            Interests
          </Typography>
          <Typography variant="body2" className={styles.sectionBody}>
            {ambassador.interests.length
              ? ambassador.interests.reduce((prev, next) => `${prev} | ${next}`)
              : 'n/a'}
          </Typography>
        </div>
      ) : (
        ''
      )}
    </Flex>
  )
}
