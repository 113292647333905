// @flow
import { makeStyles } from '@material-ui/core/styles'

export type ModalStyleProps = {
  opaque?: boolean
}

export default makeStyles(theme => ({
  modal: {
    position: 'fixed',
    zIndex: 2000,
    width: '100%',
    top: 0,
    left: 0
  },
  paper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`
  },
  modalContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    opacity: (props: ModalStyleProps) => (props.opaque ? 0.99 : 1)
  },
  closeButton: {
    //@ts-ignore
    color: theme.palette.text.nonselected,
    margin: `0 ${theme.spacing(0.5)}px`
  },
  closeIcon: {
    marginRight: theme.spacing(0.5)
  }
}))
