/* eslint-disable react-hooks/exhaustive-deps */

import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactGA from "react-ga4"
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import {
  AddToTourModal,
  AddToTourModalVariant
} from 'src/components/templates/explore/AddToTourModal'
import { ExploreDetails } from 'src/components/templates/explore/ExploreDetails'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { AmbassadorState } from 'src/state/ambassadors'
import { ConfigState } from 'src/state/config'
import { LocationState } from 'src/state/locations'
import { MediaState } from 'src/state/media'
import { NavigationEvents } from 'src/state/navigation'
import { TourState } from 'src/state/tours'
import { Tour } from 'src/utils/types'
import useStoreon from 'storeon/react'

export const ExploreDetailsPage: FC = () => {
  const [timeSpentOnPage, setTimeSpentOnPage] = useState(1);
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const [editTourVariant, setEditTourVariant] = useState<AddToTourModalVariant>(
    ''
  )
  const { analytics } = useAnalytics()

  const {
    dispatch,
    config,
    activeLocation,
    videos,
    isLoadingLocations,
    customTours,
    activeAmbassador,
    media,
    activeTour,
    isLoadingMedia,
    ambassadors
  } = useStoreon<
    LocationState &
      TourState &
      MediaState &
      ConfigState &
      AmbassadorState &
      NavigationEvents
  >(
    'config',
    'activeLocation',
    'activeTour',
    'videos',
    'activeAmbassador',
    'isLoadingLocations',
    'customTours',
    'media',
    'isLoadingMedia',
    'ambassadors',
    'navigation/isHidden'
  )

  const [selectedTour, setSelectedTour] = useState<Tour | null>(customTours[0])

  useEffect(() => {
    dispatch('location/load', id)
    dispatch('media/load', id)
    dispatch('tours/load')
  }, [dispatch])

  useEffect(() => {
    const intervalId = setInterval(() => {
      ReactGA.event({
        category: "visit_tour",
        action: "visit_tour",
        label: "hours stayed",
        value: timeSpentOnPage * 5
      })
      setTimeSpentOnPage(timeSpentOnPage + 1);
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  const isLoading = isLoadingLocations || isLoadingMedia
  return (
    <>
      <FullScreenLoading loading={isLoading} />
      {/* We check for the tour variant, if true we disable this to avoid a tabIndex issue when the AddToTourModal is open */}
      {!editTourVariant && (
        <ExploreDetails
          media={media}
          allowCustomTours={config?.features.custom_tour}
          isGlenwood={config?.sub_domain === 'glenwoodsprings'}
          locationItem={activeLocation}
          onBack={() => (activeTour ? history.goBack() : history.goBack())}
          activeAmbassador={activeAmbassador}
          ambassadors={ambassadors}
          isLoading={isLoading}
          hasActiveTour={!!activeTour}
          videos={videos}
          onAddClick={() => {
            dispatch('navigation/isHidden', true)
            setEditTourVariant('add')
          }}
          onRemoveClick={() => {
            dispatch('navigation/isHidden', true)
            setEditTourVariant('remove')
          }}
          onMediaClick={() => {
            history.push(`${id}/media`)
          }}
        />
      )}
      <AddToTourModal
        variant={editTourVariant}
        locationId={activeLocation?.id}
        tours={customTours}
        isOpen={!!editTourVariant}
        onChange={val => setSelectedTour(val)}
        onAdd={() => {
          analytics.logEvent({
            category: 'Tours',
            action: 'Location Added',
            label: activeLocation?.name
          })
          dispatch('tours/add-location', {
            tourName: selectedTour?.title,
            location: activeLocation
          })
          dispatch('navigation/isHidden', false)
          setEditTourVariant('')
        }}
        onRemove={() => {
          dispatch('tours/remove-location', {
            tourName: selectedTour?.title,
            location: activeLocation
          })
          setEditTourVariant('')
          dispatch('navigation/isHidden', false)
        }}
        onClose={() => {
          dispatch('navigation/isHidden', false)
          setEditTourVariant('')
        }}
      />
    </>
  )
}
