// @flow
import React from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import { PageTransition } from 'src/components/organisms/PageTransition'

export const AnimatedRoute = (props: RouteProps) => {
  const history = useHistory<{ last: number; current: number }>()
  const lastLocation = history.location.state?.last || 0
  const currentLocation = history.location.state?.current || 0
  const navBarDirection = currentLocation - lastLocation
  const direction = history.action === 'POP' || navBarDirection < 0 ? -1 : 1
  return (
    <PageTransition direction={direction}>
      <Route {...props} />
    </PageTransition>
  )
}
