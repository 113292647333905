export const compassHeading = (alpha: number, beta: number, gamma: number) => {
  // Convert degrees to radians
  const alphaRad = alpha * (Math.PI / 180)
  const betaRad = beta * (Math.PI / 180)
  const gammaRad = gamma * (Math.PI / 180)

  // Calculate equation components
  const cA = Math.cos(alphaRad)
  const sA = Math.sin(alphaRad)
  // const cB = Math.cos(betaRad)
  const sB = Math.sin(betaRad)
  const cG = Math.cos(gammaRad)
  const sG = Math.sin(gammaRad)

  // Calculate A, B, C rotation components
  const rA = -cA * sG - sA * sB * cG
  const rB = -sA * sG + cA * sB * cG
  // const rC = -cB * cG

  // Calculate compass heading
  let compassHeading = Math.atan(rA / rB)

  // Convert from half unit circle to whole unit circle
  if (rB < 0) {
    compassHeading += Math.PI
  } else if (rA < 0) {
    compassHeading += 2 * Math.PI
  }

  // Convert radians to degrees
  compassHeading *= 180 / Math.PI

  return compassHeading
}
