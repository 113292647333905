// @flow
import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EditTour } from 'src/components/templates/tours/EditTour'
import { TourState } from 'src/state/tours'
import useStoreon from 'storeon/react'

export const EditTourPage: FC = () => {
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()

  const { dispatch, customTours } = useStoreon<TourState>('customTours')

  const tour = customTours.find(tour => tour.id === id)

  if (!tour) {
    history.push('/')
  }

  return (
    <>
      {tour && (
        <EditTour
          onBack={() => history.goBack()}
          onAddEntry={() => history.push('/explore')}
          onEditTour={locs => {
            dispatch('tours/save', {
              ...tour,
              locations: locs
            })
          }}
          onTourItemClick={id => {
            history.push(`/explore/location/${id}`)
          }}
          tour={tour}
        />
      )}
    </>
  )
}
