// @flow
import { makeStyles } from '@material-ui/core/styles'

export type CategoryCarouselItemStyleProps = {
  fullHeight?: boolean
}

export default makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 260,
    height: 100,
    marginTop: theme.spacing(1)
  },
  categoryHeader: {
    //@ts-ignore
    color: theme.palette.text.link,
    paddingTop: theme.spacing(2),
    fontSize: '1.1rem !important',
    fontWeight: 500,
    letterSpacing: 1
  },
  categoryIcon: {
    transitionProperty: 'width height',
    transitionDuration: '0.5s',
    width: (props: CategoryCarouselItemStyleProps) =>
      props.fullHeight ? 50 : 0,
    height: (props: CategoryCarouselItemStyleProps) =>
      props.fullHeight ? 50 : 0
  }
}))
