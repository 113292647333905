// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const EntertainmentIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M0.4 8.60004C0.1791 8.60004 -3.57628e-07 8.77914 -3.57628e-07 9.00004V12.2C-3.57628e-07 12.4209 0.1791 12.6 0.4 12.6L5.4344 12.6L2.9172 15.1172C2.76105 15.2734 2.76105 15.5266 2.9172 15.6828L5.1028 17.8684C5.259 18.0246 5.5122 18.0246 5.6684 17.8684C6.29335 17.2435 7.30665 17.2435 7.9316 17.8684C8.55655 18.4934 8.55655 19.5067 7.9316 20.1316C7.77545 20.2878 7.77545 20.541 7.9316 20.6972L9.7172 22.4828C9.8734 22.639 10.1266 22.639 10.2828 22.4828L20.1656 12.6H23.6C23.8209 12.6 24 12.4209 24 12.2V9.00004C24 8.77914 23.8209 8.60004 23.6 8.60004C22.7163 8.60004 22 7.88369 22 7.00004C22 6.11639 22.7163 5.40004 23.6 5.40004C23.8209 5.40004 24 5.22094 24 5.00004V1.80004C24 1.57914 23.8209 1.40004 23.6 1.40004L0.4 1.40004C0.1791 1.40004 -3.57628e-07 1.57914 -3.57628e-07 1.80004V5.00004C-3.57628e-07 5.22094 0.1791 5.40004 0.4 5.40004C1.28365 5.40004 2 6.11639 2 7.00004C2 7.88369 1.28365 8.60004 0.4 8.60004ZM10 21.6344L8.7568 20.3912C9.52735 19.3127 9.2777 17.8138 8.1992 17.0432C7.36465 16.4469 6.24335 16.4469 5.4088 17.0432L3.7656 15.4L6.5656 12.6H7.8344L6.9172 13.5172C6.76105 13.6734 6.76105 13.9266 6.9172 14.0828L11.3172 18.4828C11.4734 18.639 11.7266 18.639 11.8828 18.4828L17.7656 12.6H19.0344L10 21.6344ZM8.9656 12.6L16.6344 12.6L11.6 17.6344L7.7656 13.8L8.9656 12.6ZM2.769 6.60224C2.5994 5.59334 1.8089 4.80284 0.8 4.63324V2.20004H4V3.80004H4.8V2.20004L19.2 2.20004V2.60004H20V2.20004H23.2V4.63324C21.8928 4.85294 21.0113 6.09069 21.231 7.39784C21.4006 8.40674 22.191 9.19724 23.2 9.36684V11.8H20V10.6H19.2V11.8L0.8 11.8V9.36684C2.10715 9.14714 2.9887 7.90934 2.769 6.60224Z" />
        <path d="M4.00001 11.0001H4.8V9.40005H4.00001V11.0001Z" />
        <path d="M4.00001 8.60005H4.8V7.00005H4.00001V8.60005Z" />
        <path d="M4.00001 6.20005H4.8V4.60005H4.00001V6.20005Z" />
        <path d="M19.2 9.80006H20V8.20005H19.2V9.80006Z" />
        <path d="M19.2 7.40005H20V5.80005H19.2V7.40005Z" />
        <path d="M19.2 5.00005H20V3.40005H19.2V5.00005Z" />
        <path d="M6.4 9.80005C6.84185 9.80005 7.2 10.1582 7.2 10.6001C7.2 10.821 7.3791 11.0001 7.6 11.0001L16.4 11.0001C16.6209 11.0001 16.8 10.821 16.8 10.6001C16.8 10.1582 17.1581 9.80005 17.6 9.80005C17.8209 9.80005 18 9.62095 18 9.40005V4.60005C18 4.37915 17.8209 4.20005 17.6 4.20005C17.1581 4.20005 16.8 3.8419 16.8 3.40005C16.8 3.17915 16.6209 3.00005 16.4 3.00005L7.6 3.00005C7.3791 3.00005 7.2 3.17915 7.2 3.40005C7.2 3.8419 6.84185 4.20005 6.4 4.20005C6.1791 4.20005 6 4.37915 6 4.60005V9.40005C6 9.62095 6.1791 9.80005 6.4 9.80005ZM6.8 4.94965C7.36315 4.80295 7.8029 4.3632 7.9496 3.80005L16.0504 3.80005C16.1971 4.3632 16.6369 4.80295 17.2 4.94965V9.05045C16.6369 9.19715 16.1971 9.6369 16.0504 10.2001L7.9496 10.2001C7.8029 9.6369 7.36315 9.19715 6.8 9.05045V4.94965Z" />
      </g>
      <defs>
        <clipPath>
          <rect
            width="24"
            height="24"
            transform="translate(24 24) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
