// @flow
import React from 'react'
import useStyles from 'src/components/atoms/loading/MapLoading/index.styles'

type MapLoadingProps = {
  loading?: boolean
}

export const MapLoading = ({ loading }: MapLoadingProps) => {
  const styles = useStyles({ loading })

  return <div className={styles.mapsLoadingContainer}></div>
}
