// @flow
import { Theme } from '@material-ui/core'
import useTheme from '@material-ui/styles/useTheme'
import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useStyles from 'src/components/atoms/loading/FullScreen/index.styles'

type FullScreenLoadingProps = {
  loading: boolean
}

export const FullScreenLoading = ({ loading }: FullScreenLoadingProps) => {
  const styles = useStyles({ loading })
  const theme: Theme = useTheme()
  return (
    <div className={styles.fullScreenContainer}>
      <PulseLoader color={theme.palette.primary.main} loading={loading} />
    </div>
  )
}
