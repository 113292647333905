// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  exploreListContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  locationList: {
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch'
  }
}))
