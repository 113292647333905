// @flow
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Marker } from 'react-mapbox-gl'
import { useParams } from 'react-router-dom'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import CampusFilter from 'src/components/molecules/CampusFilter'
import CarouselItem from 'src/components/molecules/CategoryCarouselItem'
import LocationMarker from 'src/components/molecules/LocationMarker'
import Carousel from 'src/components/organisms/Carousel'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import { ImageList, ImageListItem } from 'src/components/organisms/ImageList'
import { Map } from 'src/components/organisms/Map'
import { ExploreCategory } from 'src/components/templates/explore/ExploreCategories'
import useStyles from 'src/components/templates/explore/ExploreList/index.styles'
import { Location, MapCoordinates } from 'src/utils/types'

type ExploreListProps = {
  universityName: string
  universityCoordinates?: MapCoordinates
  categories?: ExploreCategory[]
  locationList?: Location[]
  onLocationClick?: (loc: Location) => void
  filters: string[]
  isLoading?: boolean
  onBack?: () => void
}

export const ExploreList: FC<ExploreListProps> = ({
  universityCoordinates,
  isLoading,
  filters,
  universityName,
  onLocationClick,
  locationList = [],
  categories,
  onBack
}) => {
  const styles = useStyles()
  const [isMapView, setIsMapView] = useState(false)
  const [campusFilter, setCampusFilter] = useState(filters[0] || '')
  const { initialCategory } = useParams<{ initialCategory?: string }>()
  const [category, setCategory] = useState(
    categories &&
      categories[(initialCategory && parseInt(initialCategory)) || 0]
  )

  const filteredLocations = useMemo(
    () =>
      locationList
        ?.filter(location =>
          category
            ? location.businessCategory.find(
                cat => category?.name === cat.name
              ) || location.categories.find(cat => category?.name === cat.name)
            : true
        )
        ?.filter(
          location =>
            campusFilter === 'All' || location.tags.includes(campusFilter)
        ),
    [campusFilter, category, locationList]
  )
  return (
    <>
      <Header onBack={onBack} title={universityName} variant="DEFAULT">
        <Button
          onClick={() => {
            setIsMapView(!isMapView)
          }}
          color="inherit"
          aria-hidden="true"
          aria-label={isMapView ? 'Go to list view' : 'Go to map view'}
        >
          {isMapView ? 'List' : 'Map'}
        </Button>
      </Header>
      <Content
        title="Explore Campus"
        description={`Discover places around campus in the ${category?.name ||
          ''} category.`}
      >
        {isLoading ? (
          <FullScreenLoading loading />
        ) : (
          <>
            <Carousel
              key="category-carousel"
              initialTarget={categories?.findIndex(
                cat => cat.name === category?.name
              )}
              itemWidth={250}
              onChange={index => {
                categories && setCategory(categories[index])
              }}
            >
              {categories?.map((category, index) => (
                <CarouselItem
                  key={`carousel-item-${index}`}
                  label={category.name}
                  IconComponent={category.icon}
                />
              ))}
            </Carousel>
            <CampusFilter
              filters={filters}
              onChange={val => setCampusFilter(val)}
            />

            <div className={styles.locationList}>
              <AnimatePresence exitBeforeEnter>
                {isMapView ? (
                  <motion.div
                    key="map"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.4
                    }}
                  >
                    <Map initialPosition={universityCoordinates}>
                      {filteredLocations?.map((location, index) => (
                        <Marker
                          key={index}
                          coordinates={
                            (location.cordinates as unknown) as [number, number]
                          }
                        >
                          <LocationMarker
                            label={location.name}
                            onClick={() =>
                              onLocationClick && onLocationClick(location)
                            }
                          />
                        </Marker>
                      ))}
                    </Map>
                  </motion.div>
                ) : (
                  <LocationList
                    locationList={filteredLocations}
                    onLocationClick={onLocationClick}
                  />
                )}
              </AnimatePresence>
            </div>
          </>
        )}
      </Content>
    </>
  )
}

type LocationListProps = {
  locationList: Location[]
  onLocationClick?: (loc: Location) => void
}

const LocationList = ({ locationList, onLocationClick }: LocationListProps) => {
  const listRef = useRef<HTMLUListElement | null>(null)
  useEffect(() => {
    const firstListItem = listRef?.current?.firstElementChild as HTMLDivElement
    firstListItem?.focus() // this isn't working, probably competing with carousel's focus
  }, [locationList])

  return (
    <motion.div
      key="location-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.4
      }}
    >
      <>
        {!locationList.length && (
          <Typography
            variant="h4"
            style={{ textAlign: 'center', marginTop: 40 }}
          >
            No relevant locations
          </Typography>
        )}
        {locationList.length ? (
          <ImageList ref={listRef}>
            {locationList.map((location, index) => (
              <ImageListItem
                label={location.name}
                key={`location-listing-${index}`}
                coverImage={location.coverImage}
                onClick={() => onLocationClick && onLocationClick(location)}
              />
            ))}
          </ImageList>
        ) : (
          ''
        )}
      </>
    </motion.div>
  )
}
