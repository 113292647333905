// @flow
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import classnames from 'classnames'
import React, { FC } from 'react'
import { useHistory } from 'react-router'
import ContactIcon from 'src/components/atoms/icons/navigation/Contact'
import ExploreIcon from 'src/components/atoms/icons/navigation/Explore'
import MenuIcon from 'src/components/atoms/icons/navigation/Menu'
import TourIcon from 'src/components/atoms/icons/navigation/Tour'
import useStyles from 'src/components/organisms/NavBar/index.styles'
import { useContentUrl } from 'src/hooks/useContentUrl'
import { ConfigState } from 'src/state/config'
import { NavbarState } from 'src/state/navbar'
import { NavigationState } from 'src/state/navigation'
import useStoreon from 'storeon/react'

type TabProps = {
  icon?: JSX.Element
  label?: string
}

const TabWithBackground: FC<TabProps> = ({ label, ...props }) => {
  const styles = useStyles()

  return (
    <Tab
      {...props}
      classes={{ selected: styles.selected, root: styles.root }}
      label={label && <span className={styles.tabLabel}>{label}</span>}
      data-test={`navigation-tab-${label ? label : ''}`}
    />
  )
}

type NavBarProps = {
  onSelect?: (next: number, current: number) => void
  showMenu?: boolean
  hide?: boolean
  shrink?: boolean
  activeTab?: number
  className?: string
}

export const NavBar: FC<NavBarProps> = ({
  onSelect,
  shrink,
  showMenu,
  hide,
  className,
  activeTab = 0
}) => {
  const styles = useStyles({ shrink })
  const { dispatch, currentIndex, config } = useStoreon<
    NavbarState & ConfigState
  >('currentIndex', 'config')
  if (hide) return <div />

  return (
    <>
      <Box
        className={classnames(className, styles.appBar)}
        component="nav"
        boxShadow={3}
        role="navigation"
      >
        <Tabs
          classes={{ indicator: styles.indicator }}
          value={currentIndex}
          onChange={(event, val) => {
            // @ts-ignore
            const tabIndex = val !== undefined ? val : event.target.value
            onSelect && onSelect(tabIndex, currentIndex)

            if (tabIndex !== 2) { // we do not set index if clicked on contact
              dispatch('navbar/setIndex', val)
            }
          }}
          variant="fullWidth"
          indicatorColor="primary"
          aria-label="navigation bar"
          data-test="tab-bar"
          textColor="inherit"
          centered
        >
          {config?.sub_domain !== 'glenwoodsprings' ? (
            <TabWithBackground
              icon={<ExploreIcon />}
              label="Explore"
              aria-label="Explore Tab"
            />
          ) : (
            <div />
          )}

          <TabWithBackground
            icon={<TourIcon />}
            label="Tours"
            aria-label="Tours Tab"
          />

          {config?.sub_domain !== 'glenwoodsprings' ? (
            <TabWithBackground
              icon={<ContactIcon />}
              label="Contact"
              aria-label="Contact Tab"
            />
          ) : (
            <div />
          )}

          {showMenu && (
            <TabWithBackground
              icon={<MenuIcon />}
              label="Menu"
              aria-label="Menu Tab"
            />
          )}
        </Tabs>
      </Box>
    </>
  )
}

export const NavBarWithHistory = () => {
  const history = useHistory()
  const { dispatch, config, isHidden } = useStoreon<
    ConfigState & NavigationState
  >('config', 'isHidden', 'isDraftMode')
  // const pathPrefix = isDraftMode ? '/draft' : ''
  const contactUrl = useContentUrl()

  const onNavSelect = (next: number, current: number) => {
    dispatch('error/reset')
    switch (next) {
      case 0:
        history.push(`/explore`, { last: current, current: next })
        break
      case 1:
        history.push(`/`, { last: current, current: next })
        break
      case 2:
        window.open(contactUrl, '_blank')
        // history.push(`/contact`, {
        //   last: current,
        //   current: next
        // })
        break
      case 3:
        history.push(`/menu`, { last: current, current: next })
        break
      default:
        history.push(`/`, { last: current, current: next })
    }
  }
  return (
    <NavBar
      showMenu={config?.features.content_menu}
      onSelect={onNavSelect}
      hide={isHidden}
      shrink
    />
  )
}