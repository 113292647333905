// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  addToTourLayout: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  addToTourActions: {
    width: '60%',
    margin: 'auto'
  },
  cancelButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))
