// @flow
import Button from '@material-ui/core/Button'
import React, { FC } from 'react'
import { Content } from 'src/components/organisms/Content'
import { EditTourList } from 'src/components/organisms/EditTourList'
import { Header } from 'src/components/organisms/Header'
import { Location, Tour } from 'src/utils/types'

type EditTourProps = {
  tour: Tour
  onBack?: () => void
  onAddEntry: () => void
  onEditTour?: (locs: Location[]) => void
  onTourItemClick: (id: string) => void
}

export const EditTour: FC<EditTourProps> = ({
  tour,
  onAddEntry,
  onEditTour,
  onBack,
  onTourItemClick
}) => {
  return (
    <>
      <Header onBack={onBack} title="Tours" variant="DEFAULT">
        <Button color="inherit" onClick={onAddEntry} aria-label="add entry">
          ADD ENTRY
        </Button>
      </Header>
      <Content
        title="Edit Tour"
        description="Edit your campus tours and rearrange the order of your visits."
      >
        {/* Check was added to avoid screen reader picking up the empty UL */}
        {tour.locations.length !== 0 && (
          <EditTourList
            tour={tour}
            onItemClick={onTourItemClick}
            onOrderChange={locs => {
              onEditTour && onEditTour(locs)
            }}
          />
        )}
      </Content>
    </>
  )
}
