import { makeStyles } from '@material-ui/core/styles'
import { ButtonColor } from 'src/components/atoms/buttons/Button'

export const getColor = (theme: any, color: ButtonColor) => {
  switch (color) {
    case 'inherit':
      return 'inherit'
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    case 'subtext':
      return theme.palette.text.subtext
    case 'warning':
      return theme.palette.delete.main
    case 'link':
      return theme.palette.text.link
    default:
      return 'inherit'
  }
}

export type ButtonStyleProps = {
  removeMargin?: boolean
  color: ButtonColor
  lowerCase?: boolean
  variant?: 'text' | 'contained' | 'outlined'
}

export default makeStyles(theme => ({
  root: {
    display: 'block',
    minWidth: 100,
    marginRight: (props: ButtonStyleProps) =>
      props.removeMargin ? 0 : theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    color: (props: ButtonStyleProps) =>
      props.variant !== 'text'
        ? theme.palette.getContrastText(getColor(theme, props.color))
        : getColor(theme, props.color),
    backgroundColor: (props: ButtonStyleProps) =>
      props.variant !== 'text' && getColor(theme, props.color),
    '&:hover': {
      backgroundColor: (props: ButtonStyleProps) =>
        props.variant !== 'text' && getColor(theme, props.color)
    },
    textTransform: (props: ButtonStyleProps) =>
      props.lowerCase ? 'none' : 'uppercase'
  }
}))
