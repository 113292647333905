// @flow
import { getLocationList, getVideosByLocationId } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import {
  extractFiltersFromLocations,
  transformLocations
} from 'src/utils/transformers'
import { Location, Video } from 'src/utils/types'
import { Module } from 'storeon'

export type LocationState = {
  locations: Location[]
  activeLocation?: Location
  videos: Video[]
  isLoadingVideos: boolean
  loadVideosError?: string
  isLoadingLocations: boolean
  isLoadingLocation?: boolean
  loadLocationsError?: string
  filters: string[]
}

type LoadLocationEvent = {
  activeLocation: Location
  videos: Video[]
}

export type LocationEvents = {
  'locations/load': undefined
  'locations/loaded': Location[]
  'locations/is-loading': undefined
  'locations/filters/set': string[]
  'location/load': string
  'location/is-loading': undefined
  'location/loaded': LoadLocationEvent
  'location/error': string
}

export const locationModule: Module<
  LocationState & ConfigState,
  LocationEvents
> = store => {
  store.on('@init', state => ({
    locations: [],
    videos: [],
    isLoadingLocations: false,
    filters: ['All']
  }))
  store.on('locations/load', async state => {
    let locs = state.locations
    if (!state.config) return
    try {
      store.dispatch('locations/is-loading')
      const { data } = await getLocationList(
        state.config?.urls.wordpress_api_url
      )

      locs = transformLocations(data).filter(loc =>
        state.config?.features.off_campus_locations ? true : loc.onCampus
      )
      store.dispatch('locations/loaded', locs)
      store.dispatch('locations/filters/set', extractFiltersFromLocations(locs))
    } catch (err) {
      console.error(err)
    }
  })
  store.on('locations/is-loading', state => ({ isLoadingLocations: true }))
  store.on('locations/loaded', (state, locations: Location[]) => ({
    locations,
    isLoadingLocations: false
  }))

  store.on('location/load', async (state, id: string) => {
    try {
      let locs = state.locations
      // if we haven't loaded locations yet, do so first
      if (!state.locations.length && state.config) {
        store.dispatch('locations/is-loading')
        const { data } = await getLocationList(
          state.config?.urls.wordpress_api_url
        )
        locs = transformLocations(data)
        store.dispatch('locations/loaded', locs)
      }

      //if locations exist, get videos for the desired location
      if (locs.length && state.config) {
        const { data: videos } = await getVideosByLocationId(
          state.config.urls.wordpress_api_url,
          id
        )
        // eslint-disable-next-line eqeqeq
        const activeLocation = locs.find(loc => loc.id == id)
        if (!activeLocation) {
          throw new Error('no active location')
        }
        store.dispatch('location/loaded', { activeLocation, videos })
      }
    } catch (err) {
      console.error(err)
      store.dispatch('location/error', (err as Error).message)
    }
  })
  store.on('locations/filters/set', (state, filters) => ({ filters }))
  store.on('location/is-loading', state => ({ isLoadingLocation: true }))
  store.on('location/loaded', (state, { activeLocation, videos }) => ({
    activeLocation,
    videos,
    isLoadingLocation: false
  }))
  store.on('location/error', (state, error: string) => ({
    loadLocationError: error,
    isLoadingLocation: false
  }))
}
