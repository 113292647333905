// @flow
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import React from 'react'
import ReactDOM from 'react-dom'
// import ReactGA from 'react-ga'
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
import Routes from 'src/routes'
import 'src/services/firebase'
import { store } from 'src/state/store'
import theme from 'src/theme'
import StoreContext from 'storeon/react/context'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID!
}

TagManager.initialize(tagManagerArgs)

// ReactGA.initialize(process.env.REACT_APP_GTAG_UA_ID as string)
// ReactGA.pageview(window.location.pathname + window.location.search)

ReactGA.initialize(process.env.REACT_APP_GTAG_GA4_ID as string)

const App = () => (
  <StoreContext.Provider value={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  </StoreContext.Provider>
)

// if (process.env.NODE_ENV !== 'production') {
//   import('react-axe').then(axe => {
//     axe.default(React, ReactDOM, 1000)

//     ReactDOM.render(<App />, document.getElementById('root'))
//   })
// } else {
//   ReactDOM.render(<App />, document.getElementById('root'))
// }

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// register()
