// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 300
  },
  warningIcon: {
    width: 65,
    height: 65,
    color: theme.palette.primary.main
  },
  errorScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'calc(100% - 50px)',
    textAlign: 'center'
  },
  message: {
    marginTop: theme.spacing(2),
    fontWeight: 100,
    fontSize: '5vw'
  }
}))
