// @flow
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export default makeStyles(theme => ({
  mainLink: {
    // @ts-ignore
    color: theme.palette.text.link,
    fontSize: '2.5rem',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    fontWeight: 'bolder',
    '&:hover': {
      color: '#FFF',
      border: 0,
      background: 'none'
    }
  },
  mainLinkHighlight: {
    color: '#FFF'
  },
  subLink: {
    // @ts-ignore
    color: theme.palette.text.link,
    fontSize: '1.25rem',
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    // @ts-ignore
    borderLeft: `1px solid ${fade(theme.palette.text.link, 0.3)}`,
    paddingLeft: theme.spacing(2)
  }
}))
