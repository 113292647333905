// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

const ContactIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M23.9859 19.272C23.9409 18.5769 23.6058 17.9369 23.0558 17.5119L19.8557 15.0118C18.9757 14.3218 17.7407 14.3268 16.8707 15.0218L16.1557 15.5868C15.5907 16.0318 14.7807 15.9868 14.2706 15.4768L8.54547 9.73666C8.03546 9.22664 7.99045 8.4116 8.43545 7.84659L9.00045 7.13159C9.69046 6.25654 9.69548 5.02153 9.01043 4.14147L6.51536 0.931354C5.69535 -0.123698 4.1753 -0.313689 3.12026 0.506324C3.04024 0.571342 2.96027 0.636313 2.89024 0.711315L0.845267 2.76142C-0.0297695 3.64143 -2.15481 7.24156 7.30044 16.7169C13.3106 22.7321 16.9657 23.9972 18.9757 23.9972C20.1707 23.9972 20.8658 23.5572 21.2358 23.1872L23.2808 21.1371C23.7708 20.642 24.0258 19.967 23.9859 19.272ZM22.6858 20.302C22.6508 20.347 22.6108 20.392 22.5708 20.4271L20.5257 22.4771C20.2908 22.7121 19.8307 22.9971 18.9757 22.9971C17.4856 22.9971 14.0806 22.0921 8.01042 16.0069C1.01027 8.99165 0.155251 4.86651 1.5553 3.46143L3.60036 1.41137C3.86536 1.14638 4.22536 0.996372 4.60539 0.996372C4.63539 0.996372 4.66539 0.996372 4.69539 1.00139C5.1004 1.02637 5.48043 1.2264 5.7254 1.54642L8.22048 4.75653C8.62549 5.27654 8.62047 6.00158 8.21546 6.51661L7.65046 7.22665C6.89046 8.1917 6.97043 9.57175 7.84045 10.4418L13.5656 16.182C14.4306 17.052 15.8156 17.132 16.7757 16.372L17.4907 15.8069C18.0007 15.4019 18.7257 15.3969 19.2407 15.8019L22.4408 18.302C23.0608 18.787 23.1708 19.682 22.6858 20.302Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default ContactIcon
