// @flow
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { FC, useState } from 'react'
import useStyles from 'src/components/molecules/CampusFilter/index.styles'

type CampusFilterProps = {
  initialValue?: string
  filters: string[]
  onChange?: (option: string) => void
}

const CampusFilter: FC<CampusFilterProps> = ({
  onChange,
  filters,
  initialValue = 'All'
}) => {
  const styles = useStyles()
  const [campusFilter, setCampusFilter] = useState(labelToIndex(initialValue))
  return (
    <div className={styles.campusFilterContainer}>
      <Tabs
        variant="fullWidth"
        classes={{ root: styles.tabsRoot }}
        value={campusFilter}
        onChange={(e, val) => {
          // @ts-ignore
          const tabIndex = val !== undefined ? val : e.target.value //use e.target.value for tests
          onChange && onChange(filters[tabIndex])
          setCampusFilter(val)
        }}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {filters.map(filter => (
          <Tab
            key={filter}
            label={filter}
            classes={{ root: styles.tabRoot, selected: styles.tabSelected }}
            data-test={`campus-filter-${filter}-tab`}
          />
        ))}
      </Tabs>
    </div>
  )
}

export const indexToLabel = (index: number) => {
  switch (index) {
    case 0:
      return 'All'
    case 1:
      return 'On Campus'
    case 2:
      return 'Off Campus'
    default:
      return 'All'
  }
}

export const labelToIndex = (label: string) => {
  switch (label) {
    case 'All':
      return 0
    case 'Off Campus':
      return 1
    case 'On Campus':
      return 'Off Campus'
    default:
      return 2
  }
}

export default CampusFilter
