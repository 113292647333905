// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const CampusHighlightsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M13.9187 11.2584C13.7201 11.2584 13.5397 11.1293 13.4751 10.9417C13.4114 10.7566 13.4734 10.5444 13.6272 10.4229C13.779 10.3029 13.9929 10.2893 14.1592 10.3883C14.3272 10.4883 14.4166 10.6898 14.3781 10.8815C14.3347 11.0978 14.1395 11.2584 13.9187 11.2584Z" />
        <path d="M23.4218 6.32047L18.3588 1.24667C17.6107 0.496952 16.4137 0.470656 15.6336 1.18682L3.16842 12.6282C2.9041 12.8708 2.75421 13.202 2.74633 13.5607C2.73846 13.9194 2.87368 14.2568 3.12717 14.5108L3.43095 14.8153L2.54497 15.7031C2.45727 15.791 2.40805 15.9101 2.40805 16.0342V18.6816C2.40805 18.7111 2.41087 18.7403 2.4163 18.7689L0.137086 21.053C-0.0454818 21.2359 -0.0454818 21.5322 0.137086 21.7152L1.61478 23.196C1.70272 23.2841 1.8221 23.3337 1.94659 23.3337H4.70503C4.82953 23.3337 4.94891 23.2841 5.03684 23.196L5.93623 22.2947C5.96449 22.3 5.99341 22.3028 6.02257 22.3028H8.66439C8.78888 22.3028 8.90827 22.2533 8.9962 22.1651L9.88078 21.2787L10.1805 21.5791C10.4277 21.8268 10.7543 21.9622 11.103 21.9622C11.1128 21.9622 11.1227 21.9621 11.1325 21.9619C11.4923 21.954 11.8242 21.8033 12.067 21.5377L23.4818 9.04876C24.1954 8.26764 24.1691 7.0693 23.4218 6.32047V6.32047ZM4.51047 22.3961H2.14093L1.13097 21.384L3.02114 19.4898L5.2159 21.6892L4.51047 22.3961ZM8.46982 21.3652H6.2169L6.21109 21.3594C6.21067 21.3589 6.21029 21.3585 6.20987 21.3581L3.35295 18.4951C3.35272 18.4949 3.35253 18.4947 3.3523 18.4945L3.3455 18.4877V16.228L4.09311 15.4788L9.21847 20.615L8.46982 21.3652ZM22.7897 8.41622L11.3749 20.9052C11.3065 20.9799 11.2131 21.0223 11.1119 21.0246C11.0115 21.0266 10.9155 20.9885 10.844 20.9168L7.6527 17.7188L12.7578 12.6137C12.9408 12.4306 12.9408 12.1339 12.7578 11.9508C12.5747 11.7678 12.278 11.7678 12.0949 11.9508L6.99053 17.0552L3.79074 13.8486C3.71945 13.7772 3.68139 13.6822 3.68354 13.5812C3.68575 13.4803 3.72798 13.3871 3.80237 13.3188L16.2675 1.87744C16.6762 1.50232 17.3033 1.51619 17.6952 1.90884L19.9137 4.132L15.0915 8.95418C14.9085 9.13721 14.9085 9.43401 15.0915 9.61709C15.1831 9.70859 15.303 9.75438 15.423 9.75438C15.543 9.75438 15.6629 9.70859 15.7544 9.61709L20.5759 4.79557L22.7583 6.98263C23.151 7.37612 23.1647 8.00581 22.7897 8.41622V8.41622Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
