// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const FinancialIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M18.4574 27.1832C18.7629 26.8771 18.7629 26.3818 18.4574 26.0757C18.1516 25.7705 17.6563 25.7705 17.3502 26.0757C17.045 26.3818 17.045 26.8771 17.3502 27.1832C17.6563 27.4884 18.1516 27.4884 18.4574 27.1832Z"
          fill="currentColor"
        />
        <path
          d="M14.7514 8.60812C14.7514 13.3546 18.613 17.2165 23.3595 17.2165C28.106 17.2165 31.9676 13.3546 31.9676 8.60812C31.9676 3.86161 28.106 0 23.3595 0C18.613 0 14.7514 3.86161 14.7514 8.60812V8.60812ZM30.4027 8.60812C30.4027 12.4917 27.2431 15.6513 23.3595 15.6513C19.4759 15.6513 16.3164 12.4917 16.3164 8.60812C16.3164 4.7248 19.4759 1.56525 23.3595 1.56525C27.2431 1.56525 30.4027 4.7248 30.4027 8.60812Z"
          fill="currentColor"
        />
        <path
          d="M9.05301 39.8468C9.35634 40.1526 9.85016 40.1559 10.1575 39.8532L13.9515 36.1155C14.5961 35.4709 14.7921 34.5166 14.5086 33.687L15.3247 32.9C15.7641 32.4755 16.3426 32.2416 16.9542 32.2416H27.3575C29.2031 32.2416 30.9476 31.5328 32.2701 30.2458C32.3245 30.1929 31.857 30.7436 39.3673 21.7674C40.4813 20.4495 40.3146 18.4705 38.9955 17.3554C37.6847 16.2506 35.721 16.4093 34.604 17.7046L29.9862 22.4508C29.4031 21.7331 28.5139 21.2858 27.5532 21.2858H18.8267C17.585 20.7663 16.2674 20.5033 14.908 20.5033C11.1396 20.5033 7.84919 22.2438 6.08335 25.507C5.3394 25.366 4.55479 25.5911 3.99033 26.1555L0.267544 29.8893C-0.0363944 30.1942 -0.0370059 30.6874 0.266321 30.9932L9.05301 39.8468ZM14.9076 22.0682C16.1063 22.0682 17.2652 22.3095 18.3528 22.7853C18.4519 22.8287 18.5586 22.851 18.6665 22.851H27.5532C28.4017 22.851 29.1184 23.543 29.1184 24.416C29.1184 25.2792 28.4164 25.9812 27.5532 25.9812H21.1684C20.7363 25.9812 20.3859 26.3316 20.3859 26.7637C20.3859 27.1961 20.7363 27.5462 21.1684 27.5462H27.5532C29.2793 27.5462 30.6834 26.1421 30.6834 24.416C30.6834 24.2784 30.6736 24.142 30.6559 24.0068C35.1229 19.4151 35.7528 18.7699 35.7797 18.7378C36.3374 18.0794 37.3275 17.996 37.9862 18.5512C38.6454 19.1087 38.7289 20.0982 38.1696 20.7602L31.1506 29.1512C30.1257 30.1352 28.78 30.6767 27.3575 30.6767H16.9542C15.9347 30.6767 14.9697 31.0665 14.2377 31.7738L13.5726 32.4156L7.4419 26.2852C8.87475 23.5971 11.5634 22.0682 14.9076 22.0682V22.0682ZM5.09784 27.2615C5.35591 27.0034 5.75219 26.9569 6.06683 27.1514C6.20229 27.234 5.81335 26.8799 12.8449 33.9017C13.1577 34.2145 13.1409 34.7129 12.8492 35.0046L9.61472 38.191L1.92545 30.4431L5.09784 27.2615Z"
          fill="currentColor"
        />
        <path
          d="M22.4205 3.91298V4.83C21.5096 5.15289 20.8552 6.02282 20.8552 7.04318C20.8552 8.33752 21.9083 9.39091 23.203 9.39091C23.6344 9.39091 23.9854 9.74193 23.9854 10.1734C23.9854 10.6048 23.6344 10.9559 23.203 10.9559C22.8688 10.9559 22.5076 10.7458 22.1869 10.3639C21.9086 10.033 21.4151 9.99053 21.0843 10.2685C20.7534 10.5467 20.7106 11.0402 20.9889 11.3711C21.4072 11.8686 21.8992 12.2153 22.4205 12.3902V13.3036C22.4205 13.7359 22.7709 14.086 23.203 14.086C23.635 14.086 23.9854 13.7359 23.9854 13.3036V12.3866C24.8963 12.0634 25.5507 11.1934 25.5507 10.1734C25.5507 8.87874 24.4976 7.82565 23.203 7.82565C22.7715 7.82565 22.4205 7.47463 22.4205 7.04318C22.4205 6.61173 22.7715 6.2604 23.203 6.2604C23.4803 6.2604 23.7729 6.40197 24.0494 6.66953C24.36 6.9701 24.8554 6.96184 25.1559 6.65149C25.4565 6.34082 25.4483 5.84547 25.1379 5.54489C24.7407 5.16054 24.3432 4.94313 23.9854 4.82449V3.91298C23.9854 3.48062 23.635 3.1302 23.203 3.1302C22.7709 3.1302 22.4205 3.48062 22.4205 3.91298V3.91298Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="40" height="40.0783" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
