// @flow
import { makeStyles } from '@material-ui/core/styles'

export type ContentStyleProps = {
  hidden?: boolean
}

export default makeStyles(theme => ({
  content: {
    display: (props: ContentStyleProps) => (props.hidden ? 'none' : 'flex'),
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    // height: 'calc(100% - 56px - 48px)',
    flex: 1,
    backgroundColor: theme.palette.secondary.main
  }
}))
