/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import NavigationIcon from '@material-ui/icons/Navigation'
import React, { FC, useContext, useEffect, useState } from 'react'
import ReactMapboxGl, {
  GeoJSONLayer,
  MapContext,
  Marker
} from 'react-mapbox-gl'
import { MapLoading } from 'src/components/atoms/loading/MapLoading'
import LocationMarker from 'src/components/molecules/LocationMarker'
import UserMarker from 'src/components/molecules/UserMarker'
import useStyles from 'src/components/organisms/Map/index.styles'
import { TIME_BETWEEN_ROUTE_UPDATES } from 'src/settings'
import { NavigationEvents, NavigationState } from 'src/state/navigation'
import { compassHeading } from 'src/utils/mapUtils'
import { MapCoordinates } from 'src/utils/types'
import useStoreon from 'storeon/react'
const Mapbox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibW9tZW50b3VzIiwiYSI6ImNrNTc3YnV5ajAwcWkzb3AzNXRsdmNia3cifQ.kTvImVGuaZ2ILCbFrX3FIA'
})

export type TripData = {
  duration: number
  distance: number
}

type MapProps = {
  initialPosition?: MapCoordinates
  children?: any
}

export const Map: FC<MapProps> = ({ initialPosition, children }) => {
  const styles = useStyles()
  const [isMapLoading, setIsMapLoading] = useState(true)
  // sets the tabindex of the mapbox logo to -1 on page load
  const mapBoxLogo = document.getElementsByClassName('mapboxgl-ctrl-logo')
  const mapBoxCanvas = document.getElementsByClassName('mapboxgl-canvas')
  useEffect(() => {
    // @ts-ignore
    if (mapBoxLogo) mapBoxLogo[0].tabIndex = -1
    if (mapBoxCanvas) {
      // @ts-ignore
      mapBoxCanvas[0].ariaLabel = undefined
      // @ts-ignore
      mapBoxCanvas[0].tabIndex = -1
    }
  }, [mapBoxLogo, mapBoxCanvas])
  return (
    <>
      <MapLoading loading={isMapLoading} />
      <Mapbox
        onStyleLoad={map => {
          setIsMapLoading(false)
          map.resize()
        }}
        //@ts-ignore
        center={initialPosition}
        aria-label=""
        aria-hidden="true"
        tabIndex={-1}
        className={styles.mapContainer}
        movingMethod="easeTo"
        style="mapbox://styles/mapbox/streets-v9"
      >
        <>
          <Navigator />
          {children}
        </>
      </Mapbox>
    </>
  )
}

/*
  We need access to the map object which is provided
  to children of MapContext.Consumer. This proxy component
  handles that.
*/

const Navigator = () => {
  const {
    dispatch,
    currentPosition,
    targetPosition,
    heading,
    destination,
    geojson
  } = useStoreon<NavigationState, NavigationEvents>(
    'currentPosition',
    'targetPosition',
    'heading',
    'destination',
    'geojson'
  )

  const [navModeOn, setNavModeOn] = useState(false)

  const styles = useStyles()

  const map = useContext(MapContext) as mapboxgl.Map

  useEffect(() => {
    console.log('RE-MOUNTED MAP COMPONENT')
    const updateRoute = (pos: GeolocationPosition) => {
      console.log('updating route')
      dispatch('navigation/update', {
        targetPosition: {
          lng: pos.coords.longitude,
          lat: pos.coords.latitude
        }
      })
    }

    // update user position as often as possible...
    window.navigator.geolocation.watchPosition(pos => {
      console.log('updating target position')
      dispatch('navigation/target/set', {
        targetPosition: {
          lng: pos.coords.longitude,
          lat: pos.coords.latitude
        }
      })
    })

    window.navigator.geolocation.getCurrentPosition(updateRoute)

    // ...but only update route every 10 seconds
    setInterval(() => {
      window.navigator.geolocation.getCurrentPosition(updateRoute)
      // map.easeTo({
      //   center: { lat: pos.coords.latitude, lng: pos.coords.longitude }
      // })
    }, TIME_BETWEEN_ROUTE_UPDATES)

    window.addEventListener(
      'deviceorientation',
      (event: any) => {
        let heading: number
        if (event.webkitCompassHeading) {
          // some devices don't understand "alpha" (especially IOS devices)
          heading = event.webkitCompassHeading
        } else {
          heading = compassHeading(event.alpha, event.beta, event.gamma)
        }
        // map.easeTo({ bearing: heading, center: currentPosition })
        dispatch('navigation/heading/set', heading)
      },
      false
    )
  }, [])

  useEffect(() => {
    currentPosition &&
      map.easeTo({ center: targetPosition, bearing: heading || 1 })

    let start: number
    const animate = (timestamp: number) => {
      if (start === undefined) {
        start = timestamp
      }
      const t = (timestamp - start) / 500

      if (t < 1 && currentPosition && targetPosition) {
        dispatch('navigation/position/set', {
          lat:
            currentPosition.lat +
            t * (targetPosition.lat - currentPosition.lat),
          lng:
            currentPosition.lng + t * (targetPosition.lng - currentPosition.lng)
        })
        requestAnimationFrame(animate)
      }
    }

    requestAnimationFrame(animate)
  }, [targetPosition])

  useEffect(() => {
    if (heading !== undefined) {
      // map.setBearing(heading)
    }
  })

  useEffect(() => {
    if (!destination) {
      map.easeTo({ pitch: 0, zoom: 15 })
      setNavModeOn(false)
    }
  }, [destination])
  //render route path
  return (
    <>
      {geojson && (
        <GeoJSONLayer
          data={geojson}
          lineLayout={{
            'line-join': 'round',
            'line-cap': 'round'
          }}
          linePaint={{
            'line-color': '#298ce9',
            'line-width': 12,
            'line-blur': 3
          }}
        />
      )}

      {currentPosition && (
        <Marker coordinates={[currentPosition.lng, currentPosition.lat]}>
          <UserMarker direction={map.getBearing()} />
        </Marker>
      )}
      {destination && (
        <>
          <Marker
            coordinates={[
              destination.cordinates.lng,
              destination.cordinates.lat
            ]}
          >
            <LocationMarker label={destination.name} onClick={() => {}} />
          </Marker>
          <button
            className={
              navModeOn ? styles.navModeButtonOn : styles.navModeButtonOff
            }
            aria-label="Zoom to current location"
            onClick={() => {
              navModeOn
                ? map.easeTo({ pitch: 0, zoom: 15 })
                : map.easeTo({ pitch: 60, center: currentPosition, zoom: 70 })
              setNavModeOn(!navModeOn)
            }}
          >
            <NavigationIcon fontSize="small" />
          </button>
          {navModeOn && (
            <button
              className={styles.centerButton}
              aria-label="Center the map on your location"
              onClick={() => {
                map.flyTo({
                  center: currentPosition,
                  pitch: 60,
                  zoom: 70,
                  speed: 2
                })
              }}
            >
              center
            </button>
          )}
        </>
      )}
    </>
  )
}
