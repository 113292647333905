// @flow
import Axios from 'axios'
import { store } from 'src/state/store'

export const TIME_BETWEEN_ROUTE_UPDATES = 5000

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibW9tZW50b3VzIiwiYSI6ImNrNTc3YnV5ajAwcWkzb3AzNXRsdmNia3cifQ.kTvImVGuaZ2ILCbFrX3FIA'

// export const PageNames = {
//     ''
//   }

// Add a response interceptor
Axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    if (error.response?.status === 404) {
      store.dispatch('error/404')
    }
    if (error.response?.status === 401) {
      store.dispatch('error/401')
    }
    return Promise.reject(error)
  }
)
