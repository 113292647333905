// @flow
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle'
import ChevronRight from '@material-ui/icons/ChevronRight'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import useStyles from 'src/components/molecules/DestinationCard/index.styles'
import TripDataGroup from 'src/components/molecules/TripDataGroup'
import { NavigationState } from 'src/state/navigation'
import { toFixed } from 'src/utils/formatting'
import useStoreon from 'storeon/react'

type DestinationCardProps = {
  coverImage: string
  title: string
  eventTitle?: string
  onTourEnd: () => void
  onPrevious?: () => void
  onNext?: () => void
  onEnd: () => void
  onTourClick?: () => void
  isLastDestination?: boolean
  disablePrev?: boolean
}

const DestinationCard: FC<DestinationCardProps> = ({
  title,
  eventTitle,
  onNext,
  onEnd,
  onTourEnd,
  coverImage,
  onPrevious,
  onTourClick,
  isLastDestination,
  disablePrev
}) => {
  const [minimize, setMinimize] = useState(false)
  const styles = useStyles({ minimize })
  const { tripData: trip } = useStoreon<NavigationState>('tripData')
  const mediaRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (mediaRef.current) {
      mediaRef.current.focus()
    }
  }, [title, mediaRef])

  //const now = moment()
  // const eta = trip ? now.add(trip.duration, 's').format('LT') : '-'
  return (
    <Card className={styles.destinationCard}>
      <div className={styles.cardMediaContainer}>
        <IconButton
          disableRipple
          className={styles.minimize}
          classes={{ label: styles.minimizeLabel }}
          onClick={() => setMinimize(!minimize)}
          aria-label={minimize ? 'expand image' : 'collapse image'}
        >
          <ArrowDropDownCircle fontSize="default" />
        </IconButton>
        <CardActionArea
          aria-labelledby="destination-card"
          ref={mediaRef}
          onClick={onTourClick}
        >
          <CardMedia
            aria-expanded={minimize ? 'false' : 'true'}
            aria-hidden={minimize ? 'true' : 'false'}
            className={styles.media}
            component="img"
            alt={''}
            image={coverImage}
            title={title}
          />
        </CardActionArea>

        <div className={styles.textBackground} />

        <Typography
          id="destination-card"
          className={styles.cardTitle}
          color="inherit"
          variant="h6"
          component="h1"
        >
          <Button className={styles.cardTitleButton} onClick={onTourClick}>
            {title} <ChevronRight />{' '}
          </Button>
          {eventTitle && (
            <span className={styles.eventTitle}>({eventTitle})</span>
          )}
          {/* <IconButton
            aria-labelledby="destination-card"
            color="inherit"
            onClick={onTourClick}
          >
            <ArrowRightAlt />
          </IconButton> */}
        </Typography>
      </div>
      <CardContent className={styles.cardContent}>
        <TripDataGroup
          size={minimize ? 'small' : 'default'}
          dataPoints={[
            {
              label: 'Distance to Destination',
              value: trip ? `${toFixed(trip.distance * 0.000621371, 2)}mi` : '-'
            },
            {
              label: 'Time to Destination',
              value: trip ? `${Math.ceil(trip.duration / 60)} Min` : '-'
            }
          ]}
        />
      </CardContent>
      <div className={styles.tourActions}>
        <>
          {!isLastDestination && (
            <Button
              className="end-tour"
              variant="contained"
              color="warning"
              onClick={() => onTourEnd()}
            >
              EXIT TOUR
            </Button>
          )}

          {onPrevious && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onPrevious()
              }}
              disabled={disablePrev}
            >
              PREVIOUS
            </Button>
          )}
          {onNext && (
            <Button
              className={isLastDestination ? 'finish-tour' : 'next-destination'}
              variant="contained"
              color={isLastDestination ? 'warning' : 'primary'}
              onClick={() => {
                isLastDestination ? onEnd() : onNext()
              }}
            >
              {isLastDestination ? 'FINISH' : 'NEXT'}
            </Button>
          )}
        </>
      </div>
    </Card>
  )
}
export default DestinationCard
