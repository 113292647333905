// @flow

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const palette = {
  background: {
    default: '#141414'
  },
  primary: {
    main: '#CFB87C'
  },
  secondary: {
    main: '#141414'
  },
  delete: {
    main: '#D93A31'
  },
  text: {
    primary: '#ecaa00',
    secondary: '#00274C',
    dark: '#FFFFFF',
    link: fade('#FFF', 1),
    nonselected: fade('#FFF', 0.75),
    subtext: fade('#00274C', 0.65)
  },
  border: {
    main: fade('#FFF', 0.4)
  }
}

let theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 12px',
        boxShadow: 'none'
      }
    },
    MuiTypography: {
      root: {
        color: palette.text.dark
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF'
      }
    },
    MuiSvgIcon: {
      root: {}
    },
    MuiTabs: {
      root: {
        transition: 'height'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 400
      }
    }
  }
})

export function hexToRGBA(hex: string, opacity = 1) {
  var c: any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

export default responsiveFontSizes(theme)
