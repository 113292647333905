// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  actionContainer: {
    padding: theme.spacing(1.5)
  },
  focusHighlight: {
    background: 'none',
    color: '#FFF'
  },
  tourList: {
    position: 'relative',
    flex: 1,
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  tourItem: {
    paddingRight: theme.spacing(3),
    transition: 'transform 0.2s'
  },
  selected: {
    // @ts-ignore
    color: theme.palette.text.dark,
    transform: 'scale(1.50)',
    transformOrigin: '100% 50%'
  },
  nonselected: {
    // @ts-ignore
    color: theme.palette.text.nonselected
  }
}))
