// @flow
import { fade, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  exploreLink: {
    display: 'flex',
    alignItems: 'center',
    '&:active': {
      outline: 0,
      background: 'none'
    },
    '&:focus': {
      outline: 0,
      background: 'none'
    }
  },
  exploreLinkLabel: {
    color: fade('#FFF', 1),
    marginLeft: theme.spacing(4),
    lineHeight: '150%',
    fontWeight: 300
  },
  exploreLinkIcon: {
    width: 45,
    height: 45
  }
}))
