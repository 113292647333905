// @flow
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import React from 'react'
import useStyles from 'src/components/atoms/links/ExploreLink/index.styles'
import { Link } from 'src/components/atoms/links/Link'

type ExploreLinkProps = {
  to: string
  label: string
  className?: string
  onClick?: (label: string) => void
  IconComponent: any
}

const ExploreLink = ({
  to,
  label,
  IconComponent,
  onClick,
  className
}: ExploreLinkProps) => {
  const styles = useStyles()
  return (
    <Link
      className={classnames(styles.exploreLink, className)}
      to={to}
      onClick={() => onClick && onClick(label)}
      data-test="explore-link"
    >
      <IconComponent
        fontSize="large"
        classes={{ root: styles.exploreLinkIcon }}
      />
      <Typography
        className={styles.exploreLinkLabel}
        variant="h4"
        component="p"
        data-test="explore-link-label"
      >
        {label}
      </Typography>
    </Link>
  )
}

export default ExploreLink
