// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

const ExploreIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.4282 23.2329L16.6953 16.2308C18.4126 14.5085 19.4765 12.1341 19.4765 9.51554C19.4765 4.2684 15.2081 0 9.961 0C4.71386 0 0.445465 4.2684 0.445465 9.51554C0.445465 14.7627 4.71386 19.0311 9.961 19.0311C12.2642 19.0311 14.378 18.2078 16.0256 16.8416L22.7748 23.8609C22.8636 23.9538 22.9823 24 23.1015 24C23.2148 24 23.3276 23.9579 23.4155 23.8736C23.5959 23.7 23.6013 23.4132 23.4282 23.2329ZM9.961 18.1248C5.21366 18.1248 1.35171 14.2629 1.35171 9.51554C1.35171 4.76819 5.21366 0.906242 9.961 0.906242C14.7084 0.906242 18.5703 4.76819 18.5703 9.51554C18.5703 14.2629 14.7079 18.1248 9.961 18.1248Z" />
    </svg>
  </SvgIcon>
)

export default ExploreIcon
