// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  tourWithAllButton: {
    color: '#FFF'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    // @ts-ignore
    color: theme.palette.text.nonselected,
    fontWeight: 300
  },
  ambassadorDialog: {
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(3)
  }
}))
