import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

type ThanksForVisitingProps = {
    url?: string,
}

const backBtnStyle = {
    marginTop: '10px',
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    zIndex: 99999,
    marginLeft: '65px'
}

const ThanksForVisiting: FC<ThanksForVisitingProps> = () => {
    const history = useHistory()
    const handleBack = () => {
        history.push('/menu');
        localStorage.removeItem('thanks-for-visitig')
    }

    const url = localStorage.getItem('thanks-for-visitig') || "https://campusvisitorguides.com/ung/thanks-for-visiting/"

    return (
        <>
            <span style={backBtnStyle} role='button'
                tabIndex={0}
                onClick={handleBack}
                onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                        handleBack();
                    }
                }}

            >
                <ArrowBackIos data-test="header-back-icon" />
            </span>
            <iframe src={url}
                title="Thanks For Visiting"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 0,
                    zIndex: 5000,
                    position: 'relative'
                }} >
            </iframe>

        </>
    )
}

export default ThanksForVisiting