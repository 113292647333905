/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Navigation } from 'src/components/templates/tours/Navigation'
import { AmbassadorState } from 'src/state/ambassadors'
import { ConfigState } from 'src/state/config'
import { LocationState } from 'src/state/locations'
import { TourState } from 'src/state/tours'
import useStoreon from 'storeon/react'

export const NavigationPage: FC = () => {
  const {
    dispatch,
    isLoadingLocation,
    activeLocation,
    config,
    logoUrl
  } = useStoreon<TourState & AmbassadorState & ConfigState & LocationState>(
    'isLoadingLocation',
    'activeLocation',
    'config',
    'logoUrl'
  )

  const params = useParams<{ id: string }>()

  useEffect(() => {
    dispatch('location/load', params.id)
  }, [dispatch])

  return (
    <>
      <Navigation
        schoolIcon={logoUrl}
        surveyLink={config?.urls.post_tour_survey_url}
        universityCoordinates={config?.location}
        universityName={config?.name || ''}
        destination={activeLocation}
        isLoadingDestination={isLoadingLocation}
      />
    </>
  )
}
