import Axios from 'axios'
import {
  Ambassador,
  Category,
  Location,
  Media,
  Tour,
  TourEvent,
  Video
} from 'src/utils/types'

export type WPMenuItem = {
  ID: string
  menu_item_parent: string
  post_title: string
  title: string
  url?: string
  object_id?: string
  slug: string
  child_items?: WPMenuItem[]
}

type GetMenusResponse = {
  items: WPMenuItem[]
}

export const getMenus = (apiUrl: string) =>
  Axios.get<GetMenusResponse>(`${apiUrl}/wp-json/menus/v1/menus/pwa-menu`)

export interface WPLocation extends Location {}

export const getLocationList = (apiUrl: string) =>
  Axios.get<WPLocation[]>(`${apiUrl}/wp-json/cvg/v1/locations`)

export const getDefaultTours = (apiUrl: string) =>
  Axios.get<Tour[]>(`${apiUrl}/wp-json/cvg/v1/tours`)

export const getTourEvents = (apiUrl: string) =>
  Axios.get<TourEvent[]>(`${apiUrl}/wp-json/cvg/v1/tourevents`)

export const getMediaByLocation = (apiUrl: string, locationId: string) =>
  Axios.get<Media[]>(`${apiUrl}/wp-json/cvg/v1/media/${locationId}`)

export const getCategories = (apiUrl: string) =>
  Axios.get<Category[]>(`${apiUrl}/wp-json/cvg/v1/categories`)

export const getAmbassadors = (apiUrl: string) =>
  Axios.get<Ambassador[]>(`${apiUrl}/wp-json/cvg/v1/ambassadors`)

export const getAmbassadorsByTourId = (apiUrl: string, tourId: string) =>
  Axios.get<Ambassador[]>(`${apiUrl}/wp-json/cvg/v1/ambassadors/${tourId}`)

export const getVideosByLocationId = (apiUrl: string, locationId: string) =>
  Axios.get<Video[]>(`${apiUrl}/wp-json/cvg/v1/videos/${locationId}`)