type StateCallback<S> = (s: S) => void

type Listener<S> = {
  callback: StateCallback<S>
  id: string
}
class ExternalState<S> {
  private state: S
  listeners: Array<Listener<S>> = []

  constructor(initialState: S) {
    this.state = initialState
  }

  addListener(id: string, callback: StateCallback<S>) {
    this.listeners.push({ callback, id })
  }

  removeListener(id: string, callback: StateCallback<S>) {
    this.listeners = this.listeners.filter(listener => listener.id !== id)
  }

  update(nextState: Partial<S>) {
    this.state = {
      ...this.state,
      ...nextState
    }
    this.listeners.forEach(listener => {
      listener.callback(this.state)
    })
  }

  getState(): S {
    return this.state
  }

  reset(initialState: S) {
    this.state = initialState
  }
}

export default ExternalState
