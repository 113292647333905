// @flow
import { Map } from 'mapbox-gl'
import { getRoute } from 'src/services/mapbox'
import { Location, MapCoordinates } from 'src/utils/types'
import { Module } from 'storeon'

export type NavigationState = {
  isHidden: boolean
  map?: Map
  destination?: Location
  heading?: number
  currentPosition?: MapCoordinates
  targetPosition?: MapCoordinates
  geojson?: Object
  tripData?: { distance: number; duration: number }
}

export type NavigationEvents = {
  'navigation/isHidden': boolean
  'navigation/update': {
    targetPosition?: MapCoordinates
    destination?: Location
  }
  'navigation/end': undefined

  // below actions are only called internally
  'navigation/destination/set': Location
  'navigation/position/set': MapCoordinates
  'navigation/target/set': {
    targetPosition: MapCoordinates
    currentPosition?: MapCoordinates
  }
  'navigation/heading/set': number
  'navigation/meta/set': { distance: number; duration: number; geojson: Object }
}

export const navigationModule: Module<
  NavigationState,
  NavigationEvents
> = store => {
  store.on('@init', () => ({
    isHidden: false
  }))

  store.on('navigation/isHidden', (state, isHidden: boolean) => ({
    isHidden
  }))

  store.on('navigation/position/set', (state, currentPosition) => ({
    currentPosition
  }))

  store.on(
    'navigation/target/set',
    (state, { targetPosition, currentPosition }) => ({
      targetPosition,
      currentPosition: currentPosition || state.currentPosition
    })
  )

  store.on('navigation/heading/set', (state, heading) => ({ heading }))

  store.on(
    'navigation/update',
    async (state, { targetPosition, destination }) => {
      try {
        if (
          (state.currentPosition || targetPosition) &&
          (destination || state.destination)
        ) {
          const { geojson, distance, duration } = await getRoute(
            //@ts-ignore
            targetPosition || state.targetPosition,
            destination?.cordinates || state.destination?.cordinates
          )
          store.dispatch('navigation/meta/set', { distance, duration, geojson })
        }
      } catch (err) {
        console.error(err)
      } finally {
        // if destination has changed, update it
        if (destination && state.destination?.id !== destination.id) {
          store.dispatch('navigation/destination/set', destination)
        }

        // if currentPosition has changed, update it
        if (
          targetPosition &&
          areCoordinatesDifferent(targetPosition, state.targetPosition)
        ) {
          if (!state.currentPosition)
            store.dispatch('navigation/target/set', {
              targetPosition,
              currentPosition: targetPosition
            })
          else store.dispatch('navigation/target/set', { targetPosition })
        }
      }
    }
  )

  store.on('navigation/end', () => ({
    destination: undefined,
    geojson: undefined,
    tripData: undefined
  }))

  store.on('navigation/destination/set', (state, destination) => ({
    destination
  }))

  store.on('navigation/meta/set', (state, { distance, duration, geojson }) => ({
    tripData: { distance, duration },
    geojson
  }))
}

export const areCoordinatesDifferent = (
  a?: MapCoordinates,
  b?: MapCoordinates
) => {
  const lngDif = (a?.lng || 0) - (b?.lng || 0)
  const latDif = (a?.lat || 0) - (b?.lat || 0)
  if (Math.abs(lngDif) > 0 || Math.abs(latDif) > 0) {
    return true
  }
}
