// @flow
import { makeStyles } from '@material-ui/core/styles'

export type LinkStyleProps = {
  disabled?: boolean
}
export default makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    opacity: (props: LinkStyleProps) => (props.disabled ? 0.75 : 1),
    pointerEvents: (props: LinkStyleProps) =>
      props.disabled ? 'none' : 'initial'
  }
}))
