// @flow
import CardActionArea from '@material-ui/core/CardActionArea'
import React, { FC } from 'react'
import useStyles from 'src/components/molecules/LocationMedia/index.styles'

type LocationMediaProps = {
  variant?: 'wide' | 'tall' | 'carousel'
  url: string
  alt: string
  onClick?: () => void
}

const LocationMedia: FC<LocationMediaProps> = ({
  variant = 'carousel',
  url,
  alt,
  onClick
}) => {
  const styles = useStyles()

  const renderPhotoAsButton = () => (
    <CardActionArea
      className={styles.mediaButton}
      onClick={onClick}
      aria-label={alt}
    >
      <img src={url} className={styles.locationMedia} alt={alt} />
    </CardActionArea>
  )

  const renderDefaultPhoto = () => (
    <img src={url} className={styles.locationMedia} alt={alt} />
  )

  return (
    <div className={styles[variant]}>
      {onClick ? renderPhotoAsButton() : renderDefaultPhoto()}
    </div>
  )
}

export default LocationMedia
