/* eslint-disable eqeqeq */
import React, { FC } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import TourSelector from 'src/components/molecules/TourSelector'
import Modal from 'src/components/organisms/Modal'
import useStyles from 'src/components/templates/explore/AddToTourModal/index.styles'
import { Tour } from 'src/utils/types'

export type AddToTourModalVariant = '' | 'add' | 'remove'
type AddToTourModalProps = {
  variant?: AddToTourModalVariant
  tours: Tour[]
  locationId?: string
  isOpen?: boolean
  onChange?: (tour: Tour) => void
  onClose?: () => void
  onAdd?: () => void
  onRemove?: () => void
}

export const AddToTourModal: FC<AddToTourModalProps> = ({
  variant = 'add',
  tours,
  locationId,
  onClose = () => {},
  onChange,
  onAdd,
  onRemove,
  isOpen = false
}: AddToTourModalProps) => {
  const styles = useStyles()

  const toursWithLocation = tours.filter(
    tour => !!tour.locations.find(loc => loc.id == locationId)
  )

  const toursWithoutLocation = tours.filter(
    tour => !!!tour.locations.find(loc => loc.id == locationId)
  )

  return (
    <Modal id="add-to-tour-modal" isOpen={isOpen} onClose={onClose} opaque>
      <div className={styles.addToTourLayout}>
        <TourSelector
          onChange={onChange}
          tours={variant === 'add' ? toursWithoutLocation : toursWithLocation}
        />
        <div className={styles.addToTourActions}>
          {variant === 'add' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={onAdd}
              fullWidth
              aria-label="Yes, add to tour"
            >
              Yes, add to tour
            </Button>
          ) : (
            toursWithLocation.length > 0 && (
              <Button
                variant="contained"
                color="warning"
                onClick={onRemove}
                fullWidth
              >
                Yes, remove from tour
              </Button>
            )
          )}

          <Button
            className={styles.cancelButton}
            color="link"
            onClick={onClose}
            fullWidth
          >
            CANCEL
          </Button>
        </div>
      </div>
    </Modal>
  )
}
