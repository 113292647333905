// @flow
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
// import { LocationMarkerStyles } from 'src/components/molecules/LocationMarker/index.styles'
// import useStyles from 'src/components/molecules/LocationMarker/index.styles'

type LocationMarkerProps = {
  label: string
  onClick: () => void
}
const LocationMarker: FC<LocationMarkerProps> = ({ label, onClick }) => {
  return (
    <Button lowerCase variant="contained" color="secondary" tabIndex={-1} onClick={onClick}>
      <Typography variant="caption"> {label} </Typography>
    </Button>
  )
}
export default LocationMarker
