// @flow
import Typography from '@material-ui/core/Typography'
import React from 'react'
import useStyles from 'src/components/molecules/CategoryCarouselItem/index.styles'

type CategoryCarouselItemProps = {
  label: string
  fullHeight?: boolean
  IconComponent: any
}

// TODO: when carousel is scrolled to top, make icon visible, otherwise, hide it

export default React.forwardRef<HTMLDivElement, CategoryCarouselItemProps>(
  ({ label, fullHeight = true, IconComponent }, ref = null) => {
    const styles = useStyles({ fullHeight })
    return (
      <div ref={ref} className={styles.itemContainer}>
        <IconComponent
          fontSize="large"
          classes={{ root: styles.categoryIcon }}
        />
        <Typography
          variant="h2"
          component="h1"
          aria-hidden="true"
          className={styles.categoryHeader}
        >
          {label}
        </Typography>
      </div>
    )
  }
)
