// @flow
import { makeStyles } from '@material-ui/core/styles'

export type ImageListStyleProps = {
  fullHeight?: boolean
  coverImage?: string
}

export default makeStyles(theme => ({
  imageListContainer: {
    width: '100%',
    height: '100%',
    background: '#212120',
    color: theme.palette.secondary.main,
    padding: 0,
    marginBottom: 0
  },

  item: {
    position: 'relative',
    width: '100%',
    height: (props: ImageListStyleProps) => (props.fullHeight ? 300 : 125),
    backgroundImage: (props: ImageListStyleProps) => `url(${props.coverImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  itemOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(41,41,41,0.3)'
  },
  itemLabel: {
    position: 'relative',
    textAlign: 'center'
  }
}))
