// @flow
import { Module } from 'storeon'

export type NavbarState = {
  currentIndex: number
}

export const navbarModule: Module<NavbarState> = store => {
  store.on('@init', () => ({
    currentIndex: 0
  }))

  store.on('navbar/setIndex', (state, newIndex: number) => ({
    currentIndex: newIndex
  }))
}
