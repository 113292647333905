// @flow
import { makeStyles } from '@material-ui/core/styles'

export type AddTourStyleProps = {
  tourName?: string
}

export default makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}px`,
    paddingBottom: 'env(safe-area-inset-bottom)',
    width: '100%',
    // @ts-ignore
    color: theme.palette.text.nonselected
  },
  closeButton: {
    fontSize: '1rem',
    fontWeight: 300,
    // @ts-ignore
    color: theme.palette.text.nonselected,
    textTransform: 'none',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`
  },
  closeIcon: {
    marginRight: theme.spacing(0.5)
  },
  formFields: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  formSubmit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  addTourPointsLink: {
    marginBottom: theme.spacing(4),
    fontSize: '1.25rem',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    }),
    transformOrigin: 'bottom right',
    transform: (props: AddTourStyleProps) =>
      props.tourName
        ? 'translate(-8px,0) scale(1)'
        : 'translate(0,0) scale(0.75)',
    fontWeight: (props: AddTourStyleProps) => (props.tourName ? 400 : 300)
  },
  arrowIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    }),
    transform: (props: AddTourStyleProps) =>
      props.tourName ? 'translate(16px,0)' : 'translate(8px,0)'
  }
}))
