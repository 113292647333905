import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import useStyles from 'src/components/organisms/ScreeningSuccess/index.styles'
import useStoreon from 'storeon/react'

export const ScreeningSuccess = () => {
  const styles = useStyles()
  const { dispatch } = useStoreon('')
  return (
    <div className={styles.container}>
      <Typography className={styles.header} variant="h2" align="center">
        Enjoy Your Tour!
      </Typography>
      <Typography
        className={styles.paragraph}
        variant="subtitle1"
        align="center"
      >
        You have passed our COVID-19 Panthers Protecting Panthers Screening
        Checklist. We hope you enjoy your self-guided tour!
      </Typography>
      <Typography
        className={styles.paragraph}
        variant="subtitle2"
        align="center"
      >
        Please remember to wear a face covering for the duration of your visit
        and to observe social distancing of at least 6ft.
      </Typography>
      <Button
        className={styles.confirmButton}
        tabIndex={-1}
        color="primary"
        variant="contained"
        onClick={() => dispatch('config/success-message-seen', true)}
      >
        Proceed
      </Button>
    </div>
  )
}
