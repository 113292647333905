// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  notifRoot: {
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    top: 'env(safe-area-inset-top)',
    left: 0,
    width: '100vw'
  },
  notifBody: {
    width: '100%',
    height: '100%',
    minHeight: 100,
    backgroundColor: theme.palette.secondary.main
  }
}))
