// @flow
import { makeStyles } from '@material-ui/core/styles'

export type AmbassadorVideoStyleProps = {
  thumbnail?: string
}

export default makeStyles(theme => ({
  ambassadorVideo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(3)
  },
  videoDesc: {
    flex: 1,
    paddingLeft: theme.spacing(2)
  },
  videoTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  videoBody: {
    // @ts-ignore
    color: theme.palette.text.nonselected,
    lineHeight: '150%'
  },
  videoThumbnail: {
    height: 200,
    width: 115,
    borderRadius: 5,
    backgroundImage: (props: AmbassadorVideoStyleProps) =>
      `url(${props.thumbnail})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      width: 45,
      height: 45
    }
  },
  videoIframe: {
    zIndex: 1,
    border: 'none',
    width: '100%',
    height: '100%'
  }
}))
