import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/menu/ContentView/index.styles'

type ContentViewTemplateProps = {
  universityName: string
  onBack: () => void
  schoolIcon: any
  sectionTitle: string
  url: string
}

export const ContentView: FC<ContentViewTemplateProps> = ({
  url,
  onBack,
  schoolIcon,
  sectionTitle,
  universityName
}) => {
  const styles = useStyles()
  const history = useHistory()

  const frameRef = useRef<HTMLIFrameElement>(null)
  const [frameLoaded, setFrameLoaded] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    frameRef.current?.addEventListener('load', () => setFrameLoaded(true))
  })

  useEffect(() => {
    if (url.includes('.edu')) {
      window.open(url, '_blank')
    }
  }, [history, url])

  return (
    <>
      <FullScreenLoading loading={!frameLoaded} />
      {sectionTitle ? (
        <Header onBack={onBack} title={sectionTitle} variant="DEFAULT" />
      ) : (
        <Header title={universityName} variant="HOME" schoolIcon={schoolIcon} />
      )}
      <Content
        title={sectionTitle}
        description="Description of current section"
      >
        <div className={styles.contentPageContainer}>
          <iframe
            ref={frameRef}
            title={sectionTitle}
            src={url}
            style={{
              width: '100%',
              height: '100%',
              border: 0,
              zIndex: 5000,
              position: 'relative'
            }}
          />
        </div>
      </Content>
    </>
  )
}
