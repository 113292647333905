// @flow
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import React from 'react'
import useStyles from 'src/components/atoms/ErrorScreen/index.styles'

type ErrorScreenProps = { visible?: boolean; message: string }

export const ErrorScreen = ({ visible, message }: ErrorScreenProps) => {
  const styles = useStyles()
  return (
    <div className={styles.errorScreen}>
      <ErrorOutlineIcon className={styles.warningIcon} fontSize="large" />
      <Typography className={styles.title} variant="h1">
        Awkward
      </Typography>
      <Typography className={styles.message} variant="subtitle1">
        {message}
      </Typography>
    </div>
  )
}
