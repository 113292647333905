// @flow
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import useStyles from 'src/components/organisms/PageTransition/index.styles'

type PageTransitionProps = {
  direction?: 1 | -1
  children?: any
}

export const PageTransition: FC<PageTransitionProps> = ({
  direction = 1,
  children
}) => {
  const styles = useStyles()

  return (
    <motion.div
      className={styles.container}
      initial={{
        x: direction * window.innerWidth,
        opacity: 0
      }}
      animate={{ x: 0, opacity: 1 }}
      exit={{
        x: -direction * window.innerWidth,
        position: 'absolute',
        opacity: 0
      }}
      transition={{
        duration: 0.2
      }}
    >
      {children}
    </motion.div>
  )
}
