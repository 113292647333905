import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory
} from 'react-router-dom'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import { Meta } from 'src/components/atoms/Meta'
import { AnimatedRoute } from 'src/components/organisms/AnimatedRoute'
import { NavBarWithHistory } from 'src/components/organisms/NavBar'
import { PageLayout } from 'src/components/organisms/PageLayout'
import { PreTourForm } from 'src/components/organisms/ScreeningForm'
import { ScreeningSuccess } from 'src/components/organisms/ScreeningSuccess'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { ErrorPage } from 'src/pages/Error'
import { ExploreCategoriesPage } from 'src/pages/explore/Categories'
import { ExploreDetailsPage } from 'src/pages/explore/ExploreDetails'
import { ExploreListPage } from 'src/pages/explore/ExploreList'
import { ExploreMediaPage } from 'src/pages/explore/ExploreMedia'
import { MediaDetailsPage } from 'src/pages/explore/MediaDetails'
import { ContentMenuPage } from 'src/pages/menu/ContentMenu'
import { ContentViewPage } from 'src/pages/menu/ContentView'
import { EditTourPage } from 'src/pages/tours/EditTour'
import { NavigationPage } from 'src/pages/tours/Navigation/Navigation'
import { TourListPage } from 'src/pages/tours/TourList'
import { ConfigState } from 'src/state/config'
import { ErrorState } from 'src/state/errors'
import { TourState } from 'src/state/tours'
import theme from 'src/theme'
import useStoreon from 'storeon/react'
import ThanksForVisiting from './components/templates/menu/ThanksForVisiting'

export const Routes = () => {
  const {
    dispatch,
    config,
    storageUrl,
    preTourFormSuccess,
    successMessageSeen
  } = useStoreon<ConfigState>(
    'config',
    'storageUrl',
    'preTourFormSuccess',
    'successMessageSeen'
  )

  // app only runs on devices with touch enabled
  // const isMobile =
  //   'ontouchstart' in window.document.documentElement &&
  //   window.matchMedia('only screen and (max-width: 1024px)').matches

  useEffect(() => {
    dispatch('config/load', window.location.pathname.includes('draft'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  // if (config && !isMobile) {
  //   window.location.href = config.urls.redirect_url
  // }

  useEffect(() => {
    if (config) {
      document.body.style.setProperty(
        '--pwa-primary-color',
        config?.colors.primary
      )
      document.body.style.setProperty(
        '--pwa-primary-color',
        config?.colors.secondary
      )
      document.body.style.setProperty(
        '--pwa-button-text-color',
        theme.palette.getContrastText(config?.colors.secondary)
      )
    }
  }, [config])

  if (config) {
    theme.palette.primary.main = config.colors.secondary
    theme.palette.secondary.main = config.colors.primary
  }

  if (!config) {
    return <FullScreenLoading loading />
  }

  if (
    (config?.urls.pre_tour_form_url && preTourFormSuccess === undefined) ||
    preTourFormSuccess === false
  ) {
    return <PreTourForm />
  }

  if (preTourFormSuccess && !successMessageSeen) {
    return <ScreeningSuccess />
  }

  return (
    <ThemeProvider theme={theme}>
      <Meta config={config} storageUrl={storageUrl} />
      <Router>
        <PageLayout>
          <AppRoutes />
          <NavBarWithHistory />
        </PageLayout>
      </Router>
    </ThemeProvider>
  )
}

const AppRoutes = () => {
  const { analytics, dataLayer } = useAnalytics()
  const history = useHistory()
  const { showError } = useStoreon<ErrorState & TourState>('showError')

  useEffect(() => {
    if (showError) {
      history.push('/error')
    }
  }, [history, showError])

  useEffect(() => {
    const unsubscribe = history.listen(location => {
      analytics.trackPage(location.pathname)
      dataLayer.push({
        event: 'virtualPageview',
        pageUrl: window.location.href,
        pageTitle: document.title
      })
    })
    return () => unsubscribe()
  }, [analytics, dataLayer, history])

  return (
    <Route
      path={['/draft', '/']}
      render={({ match, location }) => {
        // const prefix = match.path.includes('/draft') ? match.path : ''
        return (
          <AnimatePresence initial={false}>
            <Switch location={location} key={location.key}>
              <AnimatedRoute exact path="/error" component={ErrorPage} />
              <AnimatedRoute
                exact
                path={`/explore`}
                component={ExploreCategoriesPage}
              />
              <AnimatedRoute
                exact
                path={`/explore/list/:initialCategory?`}
                component={ExploreListPage}
              />
              <AnimatedRoute
                exact
                path={`/explore/map`}
                component={ExploreListPage}
              />
              <AnimatedRoute
                exact
                path={`/explore/location/:id`}
                component={ExploreDetailsPage}
              />
              <AnimatedRoute
                exact
                path={`/explore/location/:id/media`}
                component={ExploreMediaPage}
              />
              <AnimatedRoute
                exact
                path={`/explore/location/:id/media/:mediaId`}
                component={MediaDetailsPage}
              />
              <AnimatedRoute
                exact
                path={`/`}
                component={TourListPage}
              />
              <AnimatedRoute
                exact
                path={`/thanks-for-visiting`}
                component={ThanksForVisiting}
              />

              <AnimatedRoute
                exact
                path={`/tours/edit/:id`}
                component={EditTourPage}
              />

              <AnimatedRoute
                exact
                path={`/tours/destination/:id`}
                component={NavigationPage}
              />

              <AnimatedRoute
                exact
                path={`/menu/:section?`}
                component={ContentMenuPage}
              />
              <AnimatedRoute
                exact
                path={`/menu/:section/:postSlug`}
                component={ContentViewPage}
              />
              {/* <AnimatedRoute
                exact
                path={`/contact/:postSlug?`}
                component={ContentViewPage}
              /> */}
              <AnimatedRoute
                exact
                path={`/tour-event/:postSlug?`}
                component={ContentViewPage}
              />
            </Switch>
          </AnimatePresence>
        )
      }}
    />
  )
}

export default Routes