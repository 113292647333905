// @flow
import { makeStyles } from '@material-ui/core/styles'

export type LocationActionStyleProps = {
  disabled?: boolean
  isGlenwood?: boolean
}

export default makeStyles(theme => ({
  actionAreaRoot: {
    width: 'auto',
    boxShadow: 'none !important'
  },
  actionIcon: {
    color: (props: LocationActionStyleProps) =>
      props.isGlenwood
        ? '#FFF'
        : props.disabled
        ? // @ts-ignore
          theme.palette.text.nonselected
        : theme.palette.primary.main
  },
  locationActionButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  locationActionLabel: {
    color: (props: LocationActionStyleProps) =>
      props.isGlenwood
        ? '#FFF'
        : props.disabled
        ? // @ts-ignore
          theme.palette.text.nonselected
        : theme.palette.primary.main,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(1)
  }
}))
