// @flow
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import { ExploreList } from 'src/components/templates/explore/ExploreList'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { CategoryState } from 'src/state/categories'
import { ConfigState } from 'src/state/config'
import { LocationState } from 'src/state/locations'
import useStoreon from 'storeon/react'

export const ExploreListPage: FC = () => {
  const history = useHistory()
  const { analytics } = useAnalytics()
  const {
    dispatch,
    config,
    locations,
    categories,
    filters,
    isLoadingLocations,
    isLoadingCategories
  } = useStoreon<ConfigState & LocationState & CategoryState>(
    'config',
    'filters',
    'locations',
    'categories',
    'isLoadingLocations',
    'isLoadingCategories'
  )

  useEffect(() => {
    dispatch('locations/load')
    dispatch('categories/load')
  }, [dispatch])

  const loading = isLoadingLocations || isLoadingCategories

  return loading ? (
    <FullScreenLoading loading />
  ) : (
    <ExploreList
      universityName={config?.name || ''}
      filters={filters}
      universityCoordinates={config?.location}
      categories={categories}
      locationList={locations}
      isLoading={loading}
      onLocationClick={loc => {
        analytics.logEvent({
          category: 'Locations',
          action: 'Location Selected',
          label: loc.name
        })
        history.push(`../../explore/location/${loc.id}`)
      }}
      onBack={() => history.goBack()}
    />
  )
}
