import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import classNames from 'classnames'
import React, { FC, useEffect } from 'react'
import useStyles from 'src/components/organisms/ScreeningForm/index.styles'
import { ConfigState } from 'src/state/config'
import useStoreon from 'storeon/react'

type PreTourFormProps = {}

export const PreTourForm: FC<PreTourFormProps> = () => {
  const styles = useStyles()
  const { dispatch, config, preTourFormSuccess } = useStoreon<ConfigState>(
    'config',
    'preTourFormSuccess',
    'successMessageSeen'
  )

  useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      if (e.origin !== 'https://fiu.secure.force.com') return
      const data = JSON.parse(e.data)
      console.log(data)
      dispatch('config/preTourFormSuccess/set', !!data.p3_success)
    }
    console.log('waiting for message')
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  })

  return config?.urls.pre_tour_form_url && preTourFormSuccess === undefined ? (
    <iframe
      aria-modal="true"
      role="alertdialog"
      className={styles.formFrame}
      title="Self Guided Tour Form"
      src={
        config?.urls.pre_tour_form_url ||
        'https://dev1-fiu.cs26.force.com/rfiform/SelfGuidedTourForm?ac=UGRD&source=Self%20Guided%20Tour'
      }
    />
  ) : preTourFormSuccess === false ? (
    <div
      aria-modal="true"
      role="alertdialog"
      className={classNames(styles.formFrame, styles.noAccess)}
      aria-label="We unfortunately must ask that you leave campus."
    >
      <ErrorOutlineIcon className={styles.warningIcon} fontSize="large" />
      <Typography className={styles.heading} variant="h3">
        Screening Failed
      </Typography>
      <Typography variant="subtitle1">
        You have failed our COVID-19 Panthers Protecting Panthers Screening
        Checklist; therefore, you have not been granted access to the
        self-guided tour. We encourage you to experience campus at a later date
        when it is safe to do so. Visit{' '}
        <a href="go.fiu.edu/visit">go.fiu.edu/visit</a> for more information.
      </Typography>
    </div>
  ) : (
    <> </>
  )
}
