import React, { FC } from 'react'
import ExploreLink from 'src/components/atoms/links/ExploreLink'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/explore/ExploreCategories/index.styles'
import { useAnalytics } from 'src/hooks/useAnalytics'
import Logo from 'src/images/logos/cu.png'

export type ExploreCategory = {
  name: string
  icon: any
}

type ExploreCategoriesProps = {
  universityName: string
  categoryLinks: ExploreCategory[]
  loading?: boolean
  schoolIcon?: any
}

export const ExploreCategories: FC<ExploreCategoriesProps> = ({
  schoolIcon = Logo,
  universityName,
  loading = false,
  categoryLinks
}) => {
  const isShortList = categoryLinks.length < 5
  const styles = useStyles({ isShortList })
  const { analytics } = useAnalytics()
  return (
    <>
      <FullScreenLoading loading={loading} />
      <Header title={universityName} variant="HOME" schoolIcon={schoolIcon} />
      <Content
        title="Explore"
        description="Explore everything UMich campus has to offer"
      >
        <div className={styles.categoryList}>
          <ul>
            {categoryLinks.length &&
              categoryLinks.map((category, index) => (
                <li>
                  <ExploreLink
                    key={category.name}
                    to={`explore/list/${index}`}
                    onClick={label => {
                      analytics.logEvent({
                        category: 'Locations',
                        action: 'Category Selected',
                        label
                      })
                    }}
                    label={category.name}
                    IconComponent={category.icon}
                  />
                </li>
              ))}
          </ul>
        </div>
      </Content>
    </>
  )
}
