// @flow
import { makeStyles } from '@material-ui/core/styles'

export type TourListStyleProps = {
  toursExist?: boolean
  navigationOn?: boolean
}

export default makeStyles(theme => ({
  tourListWrapper: {
    display: (props: TourListStyleProps) =>
      props.toursExist ? 'block' : 'none',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    overflowY: 'visible',
    overflowX: 'auto',
    overflow: (props: TourListStyleProps) =>
      props.navigationOn ? 'initial' : 'auto',
    webkitOverflowScrolling: 'touch',
    padding: `${theme.spacing(4)}px 0`,
    zIndex: theme.zIndex.modal
  },
  tourList: {
    position: 'relative',
    display: 'inline-flex',
    minHeight: (props: TourListStyleProps) => (props.navigationOn ? 0 : 325),
    padding: `0 ${theme.spacing(3)}px`,
    '& > div': {
      marginRight: theme.spacing(3)
    },
    '& > div:last-child': {
      marginRight: 0
    }
  }
}))
