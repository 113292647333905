// @flow
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import useStyles from 'src/components/atoms/buttons/LocationAction/index.styles'

type LocationActionProps = {
  onClick?: () => void
  Icon: any
  disabled?: boolean
  label: string
}

const LocationAction: FC<LocationActionProps> = ({
  onClick,
  Icon,
  label,
  disabled,
  ...props
}) => {
  const styles = useStyles({
    disabled,
    isGlenwood: window.location.href.includes('glenwood')
  })
  return (
    <CardActionArea
      {...props}
      disabled={disabled}
      className={styles.locationActionButton}
      classes={{ root: styles.actionAreaRoot }}
      onClick={onClick}
      aria-label={label}
    >
      <Icon className={styles.actionIcon} fontSize="large" />
      <Typography
        className={styles.locationActionLabel}
        variant="subtitle2"
        component="span"
      >
        {label}
      </Typography>
    </CardActionArea>
  )
}

export default LocationAction
