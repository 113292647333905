// @flow
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link } from 'src/components/atoms/links/Link'
import useStyles from 'src/components/atoms/links/MenuLink/index.styles'
type MenuLinkProps = {
  to: string
  variant?: 'main' | 'sub'
  highlight?: boolean
  children: string
  external?: boolean;
  url?: string
}

export const MenuLink = ({
  to,
  variant = 'main',
  highlight,
  children,
  url,
  ...props
}: MenuLinkProps) => {
  const styles = useStyles();

  const handleMenuLinkClick = (e: any) => {
    if (to.includes('thanks-for-visiting')) {
      console.log('hello');
      console.log(url);
      localStorage.setItem('thanks-for-visitig', url as string);
    }
  };

  return (<>

    <Link {...props} to={to} onClick={handleMenuLinkClick}>
      <Typography
        variant={variant === 'main' ? 'h1' : 'h2'}
        className={`${variant === 'main' ? styles.mainLink : styles.subLink} ${highlight ? styles.mainLinkHighlight : ''
          }`}
      >
        {children}
      </Typography>
    </Link>
  </>)
}

