// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

const TourIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.0782 5.25961C10.3868 5.25961 9.01013 6.63631 9.01013 8.32771C9.01013 10.0191 10.3868 11.3958 12.0782 11.3958C13.7696 11.3958 15.1463 10.0191 15.1463 8.32771C15.1463 6.63631 13.7696 5.25961 12.0782 5.25961ZM12.0782 10.5192C10.8698 10.5192 9.88673 9.53611 9.88673 8.32771C9.88673 7.11932 10.8698 6.13621 12.0782 6.13621C13.2866 6.13621 14.2697 7.11932 14.2697 8.32771C14.2697 9.53611 13.2866 10.5192 12.0782 10.5192Z" />
      <path d="M17.944 2.46193C16.3564 0.874409 14.2456 0 12.0006 0C9.75521 0 7.6448 0.874409 6.05727 2.46193C3.11935 5.39942 2.75424 10.9264 5.26658 14.275L12.0006 24L18.7246 14.2886C21.247 10.9264 20.8819 5.39942 17.944 2.46193ZM18.0137 13.7762L12.0006 22.4598L5.97794 13.7626C3.69922 10.7243 4.02575 5.73297 6.67747 3.08169C8.09931 1.65984 9.9897 0.8766 12.0006 0.8766C14.0115 0.8766 15.9019 1.65984 17.3242 3.08169C19.9759 5.73297 20.3025 10.7243 18.0137 13.7762Z" />
    </svg>
  </SvgIcon>
)

export default TourIcon
