// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    WebkitTouchCallout: 'none',
    userSelect: 'none'
  }
}))
