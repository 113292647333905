// @flow
import MuiLink from '@material-ui/core/Link'
import classnames from 'classnames'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useStyles from 'src/components/atoms/links/Link/index.styles'

type LinkProps = {
  to: string
  className?: string
  children: any
  color?: 'primary' | 'secondary'
  disabled?: boolean
  onClick?: (e?: any) => void
  external?: boolean;
}

export const Link = ({
  to,
  className,
  children,
  disabled,
  external,
  color = 'primary',
  ...props
}: LinkProps) => {
  const styles = useStyles({ disabled })
  return (
    <MuiLink
      {...props}
      component={external ? MuiLink : RouterLink}
      to={to}
      href={to}
      target={external ? '_blank' : undefined}
      underline="none"
      className={classnames(styles.link, className)}
      color={disabled ? 'inherit' : color}
      tabIndex={disabled ? -1 : 0}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </MuiLink>
  )
}