// @flow
import Box from '@material-ui/core/Box'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import useStyles from 'src/components/organisms/Content/index.styles'

type ContentProps = {
  title: string
  description: string
  hidden?: boolean
  children?: any
}

export const Content: FC<ContentProps> = ({
  title,
  description,
  hidden,
  children
}) => {
  const styles = useStyles({ hidden })
  return (
    <>
      <Box className={styles.content} role="main">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {children}
      </Box>
    </>
  )
}
