// @flow
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { motion } from 'framer-motion'
import React from 'react'
import useStyles from 'src/components/atoms/loading/TourCardLoading/index.styles'

export const TourCardLoading = () => {
  const styles = useStyles()
  return (
    <motion.div
      className={styles.tourCardPlaceholder}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2
      }}
    >
      <Card className={styles.card} key="tour-loading-card">
        <div className={styles.imagePlaceholder} />

        <CardContent className={styles.cardContent}>
          <div className={styles.cardTitle}>
            <div className={styles.titleLoading} />
          </div>
          <div className={styles.cardBody}>
            <div className={styles.placeholderLine} />
            <div className={styles.placeholderLine} />
            <div className={styles.placeholderLine} />
            <div className={styles.placeholderLine} />
          </div>
        </CardContent>
        <div className={styles.tourActions}>
          <div className={styles.buttonPlaceholder} />
          <div className={styles.buttonPlaceholder} />
        </div>
      </Card>
    </motion.div>
  )
}
