/* eslint-disable no-unused-expressions */
// @flow
import { getDefaultTours, getTourEvents } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import { NavigationEvents } from 'src/state/navigation'
import { transformDefaultTours } from 'src/utils/transformers'
import { Ambassador, Location, Tour, TourEvent } from 'src/utils/types'
import { Module } from 'storeon'

export type AddLocationParams = {
  tourName: string
  location: Location
}

export type SetActiveTourParams = {
  activeTour: Tour
  destination?: Location
}

export type TourState = {
  defaultTours: (Tour | TourEvent)[]
  customTours: Tour[]
  isLoadingDefaultTours: boolean
  loadDefaultToursError: string
  activeTour?: Tour | TourEvent
  activeAmbassador?: Ambassador
  destination?: Location
  ambassadors: Ambassador[]
}

export type TourEvents = {
  'tours/load': undefined
  'tours/is-loading': undefined
  'tours/loaded': Tour[]
  'tours/save': Tour
  'tours/add-location': AddLocationParams
  'tours/remove-location': AddLocationParams
  'tours/set-active': SetActiveTourParams
  'tours/set-ambassador': Ambassador
}

export const tourModule: Module<
  TourState & ConfigState,
  TourEvents & NavigationEvents
> = store => {
  store.on('@init', state => ({
    defaultTours: [],
    customTours: [],
    isLoadingDefaultTours: false,
    loadDefaultToursError: '',
    ambassadors: []
  }))

  store.on('tours/load', async state => {
    try {
      if (!state.defaultTours.length && state.config) {
        store.dispatch('tours/is-loading')
        const { data: defaultTours } = await getDefaultTours(
          state.config.urls.wordpress_api_url
        )
        const { data: tourEvents } = await getTourEvents(
          state.config.urls.wordpress_api_url
        )
        const tours = transformDefaultTours([...defaultTours, ...tourEvents])
        store.dispatch('tours/loaded', tours)
        //store.dispatch('tours/loaded', TOUR_LIST)
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('tours/is-loading', state => ({ isLoadingDefaultTours: true }))
  store.on('tours/loaded', (state, defaultTours: Tour[]) => ({
    defaultTours,
    isLoadingDefaultTours: false
  }))
  store.on('tours/save', (state, tour: Tour) => {
    return {
      customTours: saveTour(tour, state.customTours)
    }
  })
  store.on('tours/add-location', (state, data: AddLocationParams) => ({
    customTours: addLocation(data.tourName, data.location, state.customTours)
  }))
  store.on('tours/remove-location', (state, data: AddLocationParams) => ({
    customTours: removeLocation(data.tourName, data.location, state.customTours)
  }))
  store.on('tours/set-active', (state, { destination, activeTour }) => {
    if (destination) {
      store.dispatch('navigation/update', { destination })
    }
    return {
      activeTour,
      destination
    }
  })
  store.on('tours/set-ambassador', (state, activeAmbassador?: Ambassador) => ({
    activeAmbassador
  }))
}

export const saveTour = (savedTour: Tour, tours: Tour[]): Tour[] => {
  const index = tours.findIndex(tour => tour.title === savedTour.title)
  if (index > -1) {
    const tourCopy = [...tours]
    tourCopy[index] = savedTour
    return tourCopy
  }
  return tours.concat(savedTour)
}

export const addLocation = (
  tourName: string,
  loc: Location,
  tours: Tour[]
): Tour[] => {
  const toursCopy = [...tours]
  toursCopy.find(tour => tour.title === tourName)?.locations.push(loc)
  return toursCopy
}

export const removeLocation = (
  tourName: string,
  loc: Location,
  tours: Tour[]
): Tour[] => {
  const toursCopy = [...tours]
  const tour = toursCopy.find(tour => tour.title === tourName)
  const locations = tour?.locations.filter(location => location.id !== loc.id)
  if (tour) {
    tour.locations = locations || []
  } else {
    console.error(`${tourName} does not exist`)
  }
  return toursCopy
}
