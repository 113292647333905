// @flow
import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import { ContentMenu } from 'src/components/templates/menu/ContentMenu'
import { ConfigState } from 'src/state/config'
import { MenuState } from 'src/state/menus'
import useStoreon from 'storeon/react'

export const ContentMenuPage: FC = () => {
  const { section } = useParams<{ section?: string }>()
  const history = useHistory()
  const { dispatch, menus, isLoadingMenus, config, logoUrl } = useStoreon<
    MenuState & ConfigState
  >('menus', 'isLoadingMenus', 'logoUrl', 'config')

  useEffect(() => {
    if (!menus.length) dispatch('menus/load')
  }, [dispatch, menus])

  return (
    <>
      <FullScreenLoading loading={isLoadingMenus} />
      <ContentMenu
        schoolIcon={logoUrl}
        universityName={config?.name || ''}
        menuItems={menus}
        section={section || ''}
        onBack={() => history.goBack()}
      />
    </>
  )
}
