// @flow
import React, { FC } from 'react'
import Div100vh from 'react-div-100vh'
import useStyles from 'src/components/organisms/PageLayout/index.styles'

// Detects if device is on iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}
// Detects if device is in standalone mode
const isInStandaloneMode = () =>
  //@ts-ignore
  'standalone' in window.navigator && window.navigator.standalone

export const PageLayout: FC = ({ children }) => {
  const styles = useStyles()
  return (
    <Div100vh
      style={
        isIos() && isInStandaloneMode()
          ? { height: '100vh' }
          : { height: '100rvh' }
      }
    >
      <div className={styles.pageWrapper}>{children}</div>
    </Div100vh>
  )
}
