// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    width: '100%',
    flex: 1,
    overflow: 'hidden',
    display: 'inherit',
    flexDirection: 'inherit'
  }
}))
