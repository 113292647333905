// @flow
import { makeStyles } from '@material-ui/core/styles'
import { HeaderVariant } from 'src/components/organisms/Header'

export type HeaderStyleProps = {
  variant?: HeaderVariant
  schoolIcon?: string
}

export default makeStyles(theme => ({
  appBar: {
    paddingTop: `env(safe-area-inset-top)`,
    background: (props: HeaderStyleProps) =>
      props.variant === 'TRANSPARENT' ? 'transparent' : ''
  },
  backButton: {
    textTransform: 'none',
    boxShadow: 'none',
    width: 'fit-content'
  },
  schoolIcon: {
    objectFit: 'fill',
    marginRight: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    width: 35,
    height: 35
  },
  actionButtonWrapper: {
    color: '#FFF'
  },
  title: {
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: 300
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.text.link,
    justifyContent: 'space-between'
  }
}))
