// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const ShoppingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9174 5.56056C28.4651 2.3907 25.706 0 22.4997 0C20.4377 0 18.4794 0.995025 17.2618 2.66263C17.0131 3.00273 17.0875 3.47979 17.4276 3.72849C17.7665 3.9763 18.2447 3.90278 18.493 3.56225C19.425 2.28701 20.9226 1.5255 22.4994 1.5255C24.9516 1.5255 27.0612 3.35287 27.4071 5.77575C27.4616 6.15628 27.7881 6.4309 28.1615 6.4309C28.197 6.4309 28.2336 6.42822 28.2701 6.4233C28.687 6.36364 28.9768 5.9773 28.9174 5.56056Z"
        fill="currentColor"
      />
      <path
        d="M39.9333 14.0988L37.6452 10.2852C37.5075 10.0553 37.2593 9.91493 36.9911 9.91493H34.7984C34.3774 9.91493 34.0358 10.2566 34.0358 10.6776C34.0358 11.0985 34.3774 11.4402 34.7984 11.4402H36.5594L37.9323 13.7283H7.06687L8.43975 11.4402H9.24752C9.6685 11.4402 10.0102 11.0985 10.0102 10.6776C10.0102 10.2566 9.6685 9.91493 9.24752 9.91493H8.00804C7.7399 9.91493 7.49165 10.0553 7.35401 10.2852L5.06587 14.0988C4.92443 14.3348 4.92108 14.6282 5.05627 14.8673C5.19145 15.1064 5.44485 15.2543 5.71991 15.2543H39.2793C39.5543 15.2543 39.8075 15.1064 39.9429 14.8673C40.0783 14.6282 40.0747 14.3343 39.9333 14.0988Z"
        fill="currentColor"
      />
      <path
        d="M36.1315 7.06618C36.0247 6.8939 35.8542 6.77167 35.6572 6.72564L25.2598 4.29383C25.0629 4.24758 24.8556 4.28199 24.6835 4.38835C24.5119 4.49516 24.3895 4.66565 24.3434 4.86273L23.5593 8.21449C23.4632 8.62452 23.718 9.03478 24.1282 9.13041C24.5383 9.2265 24.949 8.97176 25.0446 8.56151L25.6551 5.95273L34.5669 8.03729L33.1666 14.0246C33.0705 14.4347 33.3252 14.8449 33.7355 14.9406C33.7943 14.9542 33.8526 14.9607 33.9102 14.9607C34.2566 14.9607 34.5701 14.7234 34.6516 14.3719L36.2258 7.64201C36.2723 7.44515 36.2384 7.23779 36.1315 7.06618Z"
        fill="currentColor"
      />
      <path
        d="M22.903 8.8692L22.208 2.99134C22.1843 2.79046 22.0818 2.607 21.9227 2.48187C21.7636 2.35674 21.5627 2.29954 21.3609 2.32322L8.60416 3.83106C8.18608 3.88067 7.8871 4.25964 7.93649 4.67816L9.09508 14.4816C9.14111 14.8697 9.47025 15.1551 9.85168 15.1551C9.8814 15.1551 9.91201 15.1531 9.9424 15.1497C10.3603 15.1001 10.6592 14.7211 10.6101 14.3026L9.54086 5.25601L20.7829 3.92737L21.388 9.04774C21.4376 9.46604 21.8148 9.76323 22.2351 9.71585C22.6531 9.66669 22.9521 9.2875 22.903 8.8692Z"
        fill="currentColor"
      />
      <path
        d="M28.935 14.2016L27.4211 7.90612C27.3738 7.70971 27.2502 7.53966 27.0779 7.43397C26.9056 7.32917 26.699 7.29632 26.5012 7.34325L14.2143 10.2982C13.8048 10.3965 13.5527 10.8085 13.651 11.2181L14.4076 14.3647C14.5059 14.7748 14.918 15.027 15.3273 14.928C15.7369 14.8297 15.989 14.4177 15.8907 14.0081L15.3121 11.6029L26.116 9.00505L27.4515 14.5587C27.5353 14.9084 27.8482 15.143 28.1925 15.143C28.2517 15.143 28.3116 15.1361 28.3717 15.1216C28.7813 15.023 29.0333 14.6112 28.935 14.2016Z"
        fill="currentColor"
      />
      <path
        d="M40.0417 14.4525C40.0207 14.0472 39.6859 13.729 39.2797 13.729H5.72035C5.31412 13.729 4.97939 14.047 4.95839 14.4525L3.43289 44.1983C3.42216 44.407 3.49769 44.6112 3.64159 44.7627C3.78572 44.914 3.98548 45 4.19463 45H40.8047C41.0137 45 41.2137 44.9142 41.3585 44.7627C41.5021 44.6112 41.5777 44.4072 41.5672 44.1983L40.0417 14.4525ZM4.99727 43.4745L6.44455 15.2543H38.5546L40.0019 43.4745H4.99727Z"
        fill="currentColor"
      />
      <path
        d="M28.22 16.7796C26.9582 16.7796 25.9319 17.8059 25.9319 19.0677C25.9319 20.3295 26.9582 21.3558 28.22 21.3558C29.4818 21.3558 30.5082 20.3295 30.5082 19.0677C30.5082 17.8059 29.4818 16.7796 28.22 16.7796ZM28.22 19.8305C27.7993 19.8305 27.4574 19.4884 27.4574 19.0679C27.4574 18.6474 27.7995 18.3053 28.22 18.3053C28.6406 18.3053 28.9827 18.6474 28.9827 19.0679C28.9827 19.4884 28.6406 19.8305 28.22 19.8305Z"
        fill="currentColor"
      />
      <path
        d="M16.7793 16.7796C15.5175 16.7796 14.4912 17.8059 14.4912 19.0677C14.4912 20.3295 15.5175 21.3558 16.7793 21.3558C18.0412 21.3558 19.0675 20.3295 19.0675 19.0677C19.0675 17.8059 18.0412 16.7796 16.7793 16.7796ZM16.7793 19.8305C16.3588 19.8305 16.0167 19.4884 16.0167 19.0679C16.0167 18.6474 16.3588 18.3053 16.7793 18.3053C17.1999 18.3053 17.542 18.6474 17.542 19.0679C17.542 19.4884 17.1999 19.8305 16.7793 19.8305Z"
        fill="currentColor"
      />
      <path
        d="M28.22 19.0677H28.1613C27.7403 19.0677 27.3986 19.4093 27.3986 19.8303C27.3986 19.9345 27.4192 20.0339 27.4574 20.1244V24.0252C27.4574 26.7586 25.2334 28.9828 22.4997 28.9828C19.766 28.9828 17.542 26.7588 17.542 24.0252V19.8305C17.542 19.4096 17.2003 19.0679 16.7794 19.0679C16.3584 19.0679 16.0167 19.4096 16.0167 19.8305V24.0254C16.0167 27.6001 18.9249 30.5083 22.4997 30.5083C26.0744 30.5083 28.9827 27.6001 28.9827 24.0254V19.8305C28.9827 19.4096 28.641 19.0677 28.22 19.0677Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
