// @flow
import { makeStyles } from '@material-ui/core/styles'

export type ExploreCategoriesStyleProps = {
  isShortList: boolean
}

export default makeStyles(theme => {
  return {
    categoryList: {
      width: '100%',
      height: '100%',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      '& > ul': {
        listStyleType: 'none',
        height: '100%',
        width: '100%',
        margin: '0',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (props: ExploreCategoriesStyleProps) =>
          props.isShortList ? 'flex-start' : 'space-around',
        '& > li': {
          margin: (props: ExploreCategoriesStyleProps) =>
            props.isShortList ? '24px 0' : 0
        }
      }
    }
  }
})
