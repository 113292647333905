// @flow
import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ContentView } from 'src/components/templates/menu/ContentView'
import { useContentUrl } from 'src/hooks/useContentUrl'
import { ConfigState } from 'src/state/config'
import { MenuState } from 'src/state/menus'
import { getLabelFromSlug } from 'src/utils/formatting'
import useStoreon from 'storeon/react'

export const ContentViewPage: FC = () => {
  const { section, postSlug = '/' } = useParams<{
    section?: string
    postSlug?: string
  }>()

  const history = useHistory()
  const contentUrl = useContentUrl(section)

  const { dispatch, menus, config, logoUrl } = useStoreon<
    MenuState & ConfigState
  >('menus', 'config', 'logoUrl')

  useEffect(() => {
    // if section exists, we are in the content menu (not contact tab)
    // if (section && !menus.length) {
    //   history.push(`/menu/${String(section)}`)
    // }
  }, [dispatch, history, menus, postSlug, section])

  const menuItem = menus?.find(item => item.slug === postSlug)
  return (
    <>
      {postSlug && (
        <ContentView
          url={menuItem?.url || `${contentUrl}/${postSlug}`}
          schoolIcon={logoUrl}
          universityName={config?.name || ''}
          sectionTitle={section ? getLabelFromSlug(section, menus) : ''}
          onBack={() => history.goBack()}
        />
      )}
    </>
  )
}