// @flow
import Typography from '@material-ui/core/Typography'
import HeightIcon from '@material-ui/icons/Height'
import { motion, PanInfo } from 'framer-motion'
import React, {
  forwardRef,
  KeyboardEvent,
  RefObject,
  useRef,
  useState
} from 'react'
import useStyles from 'src/components/molecules/DraggableTourItem/index.styles'
import { Location } from 'src/utils/types'

type Point = {
  x: number
  y: number
}

type DraggableTourItemProps = {
  position?: number
  selectedItem: number
  onSelect: (index: number) => void
  location: Location
  length: number
  onSwap: (a: number, b: number) => void
  onClick?: (id: string) => void
}

const DraggableTourItem = forwardRef<Element, DraggableTourItemProps>(
  (
    { location, position = 0, selectedItem, length, onSelect, onSwap, onClick },
    constraintRef
  ) => {
    const ref = useRef<HTMLLIElement>(null)
    const [isDragging, setIsDragging] = useState(false)
    const styles = useStyles({
      image: location.coverImage,
      isDragging,
      nothingSelected: selectedItem === -1
    })

    const findItemIndex = (item: HTMLLIElement, point: Point) => {
      const itemIndex = Math.round(
        (item.offsetTop + point.y) / item.clientHeight
      )
      return itemIndex
    }

    const onDragEnd = (
      event: MouseEvent | TouchEvent | PointerEvent,
      info: PanInfo
    ) => {
      if (ref.current) {
        const indexToSwapWith = findItemIndex(ref.current, {
          x: info.point.x,
          y: info.point.y
        })
        onSwap(position, indexToSwapWith)
      }
      setIsDragging(false)
      onSelect(-1)
    }

    const onKeyDown = (e: KeyboardEvent<HTMLLIElement>) => {
      let direction = 0 // -1 is up, 1 is down, 0 is invalid
      if (e.key === 'ArrowDown') {
        direction = 1
      }
      if (e.key === 'ArrowUp') {
        direction = -1
      }
      if (ref.current && direction !== 0) {
        const indexToSwapWith = findItemIndex(ref.current, {
          x: ref.current.offsetLeft,
          y: ref.current.offsetHeight * direction + 10
        })
        if (indexToSwapWith > -1 && indexToSwapWith < length) {
          console.log(`swapping item ${position} with item ${indexToSwapWith}`)
          onSwap(position, indexToSwapWith)
        }
      }
      setIsDragging(false)
      onSelect(-1)
    }

    return (
      <motion.li
        tabIndex={0}
        role="listitem"
        ref={ref}
        className={styles.entryWrapper}
        drag="y"
        dragElastic={0.2}
        dragMomentum={false}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 15 }}
        dragConstraints={(constraintRef as unknown) as RefObject<Element>}
        onDragStart={() => {
          setIsDragging(true)
          onSelect(position)
        }}
        onKeyDown={onKeyDown}
        onDragEnd={onDragEnd}
        onClick={() => onClick && onClick(location.id)}
        positionTransition={true}
      >
        <div className={styles.backgroundTint} />
        <div className={styles.entryTopLayer}>
          <div className={styles.entryTitle}>
            <Typography variant="h5" component="h1">
              <Typography
                className={styles.position}
                variant="h5"
                component="span"
              >
                {position + 1}
              </Typography>
              {location.name}
            </Typography>
          </div>
          <HeightIcon fontSize="large" />
        </div>
      </motion.li>
    )
  }
)

export default DraggableTourItem
