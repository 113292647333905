// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  formFrame: {
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    border: 0,
    zIndex: 100000
  },
  noAccess: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column'
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  warningIcon: {
    width: 65,
    height: 65,
    marginBottom: theme.spacing(2)
  }
}))
