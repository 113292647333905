// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  contentPageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}))
