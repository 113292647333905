/* eslint-disable no-unused-expressions */
// @flow
import { getAmbassadors, getAmbassadorsByTourId } from 'src/services/wordpress'
import { ConfigState } from 'src/state/config'
import { Ambassador } from 'src/utils/types'
import { Module } from 'storeon'

export type AmbassadorsByTour = { [tour: string]: Ambassador[] };
export type AmbassadorState = {
  ambassadors: Ambassador[]
  ambassadorsByTour: AmbassadorsByTour;
  loadAmbassadorsError?: string
  isLoadingAmbassadors: boolean
  isLoadingAmbassadorsByTour: boolean
}

export const ambassadorModule: Module<AmbassadorState &
  ConfigState> = store => {
  store.on('@init', state => ({
    ambassadors: [],
    ambassadorsByTour: {},
    isLoadingAmbassadors: false,
    isLoadingAmbassadorsByTour: false
  }))

  store.on('ambassadors/load', async state => {
    try {
      if (!state.ambassadors.length && state.config) {
        store.dispatch('ambassadors/is-loading')
        const { data } = await getAmbassadors(
          state.config?.urls.wordpress_api_url
        )
        store.dispatch('ambassadors/loaded', data)
      }
    } catch (err) {
      console.error(err)
    }
  })

  store.on('ambassadors/load/by-tour', async (state, tourId: string) => {
    try {
      if (state.config) {
        store.dispatch('ambassadors/is-loading/by-tour')
        const { data } = await getAmbassadorsByTourId(
          state.config?.urls.wordpress_api_url,
          tourId
        )
        store.dispatch('ambassadors/loaded/by-tour', {
          [tourId]: data 
        })
      }
    } catch (err) {
      console.error(err)
    }
  })

  store.on('ambassadors/is-loading', state => ({ isLoadingAmbassadors: true }))
  store.on('ambassadors/is-loading/by-tour', state => ({
    isLoadingAmbassadorsByTour: true
  }))
  store.on(
    'ambassadors/loaded/by-tour',
    (state, ambassadors: AmbassadorsByTour) => ({
      ambassadorsByTour: Object.assign({ ...state.ambassadorsByTour }, ambassadors),
      isLoadingAmbassadorsByTour: false
    })
  )
  store.on('ambassadors/loaded', (state, ambassadors: Ambassador[]) => ({
    ambassadors,
    isLoadingAmbassadors: false
  }))

  store.on('ambassadors/error', (state, error: string) => ({
    loadAmbassadorsError: error,
    isLoadingAmbassadors: false
  }))
}