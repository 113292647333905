// @flow
import { makeStyles } from '@material-ui/core/styles'

export type DraggableTourItemStyleProps = {
  isDragging?: boolean
  image?: string
  nothingSelected?: boolean
}

export default makeStyles(theme => ({
  entryWrapper: {
    display: 'block',
    position: 'relative',
    zIndex: (props: DraggableTourItemStyleProps) =>
      props.isDragging ? theme.zIndex.modal : 0,
    padding: `0 ${theme.spacing(1)}px`,
    width: '100%',
    height: 100,
    backgroundColor: 'grey',
    backgroundImage: (props: DraggableTourItemStyleProps) =>
      `url(${props.image})`,
    backgroundSize: 'cover',
    color: '#FFF',
    opacity: (props: DraggableTourItemStyleProps) =>
      props.isDragging || props.nothingSelected ? 1 : 0.5,
    boxShadow: (props: DraggableTourItemStyleProps) =>
      props.isDragging ? '0px 0px 35px -1px rgba(41,41,41,0.68)' : '',
    transition: theme.transitions.create(['opacity', 'boxShadow'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    })
  },
  backgroundTint: {
    position: 'absolute',
    backgroundColor: 'black',
    left: 0,
    opacity: 0.3,
    width: '100%',
    height: '100%'
  },
  entryTopLayer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  position: {
    paddingRight: theme.spacing(2)
  },
  entryTitle: {
    flex: 1,
    textAlign: 'center'
  }
}))
