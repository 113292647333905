// @flow
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import React, { FC, useState } from 'react'
import useStyles from 'src/components/molecules/TourSelector/index.styles'
import { Tour } from 'src/utils/types'

type TourSelectorProps = {
  tours: Tour[]
  onChange?: (tour: Tour) => void
}

const TourSelector: FC<TourSelectorProps> = ({ tours, onChange }) => {
  const styles = useStyles()
  const [selected, setSelected] = useState(tours[0])
  return (
    <ul className={styles.tourList}>
      {!tours.length && (
        <Typography
          variant="h4"
          className={classnames(styles.tourItem, styles.nonselected)}
        >
          No Tours
        </Typography>
      )}
      {tours.map((tour, index) => (
        <li key={`${tour.title}-index`} className={styles.tourItem}>
          <CardActionArea
            data-test="tour-item-button"
            onClick={() => {
              onChange && onChange(tour)
              setSelected(tour)
            }}
            classes={{ focusHighlight: styles.focusHighlight }}
            className={classnames(
              selected === tour ? styles.selected : styles.nonselected,
              styles.actionContainer
            )}
            disableRipple
          >
            <Typography color="inherit" variant="h5">
              {tour.title}
            </Typography>
          </CardActionArea>
        </li>
      ))}
    </ul>
  )
}

export default TourSelector
