// @flow
import { AttractionsIcon } from 'src/components/atoms/icons/explore/Attractions'
import { CampusHighlightsIcon } from 'src/components/atoms/icons/explore/CampusHighlights'
import { EntertainmentIcon } from 'src/components/atoms/icons/explore/Entertainment'
import { EventsIcon } from 'src/components/atoms/icons/explore/Events'
import { FinancialIcon } from 'src/components/atoms/icons/explore/Financial'
import { FoodIcon } from 'src/components/atoms/icons/explore/Food'
import { HousingIcon } from 'src/components/atoms/icons/explore/Housing'
import { LodgingIcon } from 'src/components/atoms/icons/explore/Lodging'
import { ServicesIcon } from 'src/components/atoms/icons/explore/Services'
import { ShoppingIcon } from 'src/components/atoms/icons/explore/Shopping'
import { ExploreCategory } from 'src/components/templates/explore/ExploreCategories'
import { WPLocation, WPMenuItem } from 'src/services/wordpress'
import { decodeHTMLEntities, toSlug } from 'src/utils/formatting'
import { Category, Location, MenuItem, Tour } from 'src/utils/types'

type MenusTransformer = (item: WPMenuItem[]) => MenuItem[]

export const transformMenus: MenusTransformer = menuItems =>
  menuItems.map(item => {
    const parent = {
      id: item.ID,
      label: item.title,
      url: item.url,
      slug: item.slug || toSlug(item.title),
      childItems: item.child_items?.map(childItem => ({
        id: childItem.ID,
        label: childItem.title,
        slug: childItem.slug || toSlug(childItem.title),
        url: childItem.url,
        postId: childItem.object_id
      }))
    }
    // make sure "parent" field of child items refers to the parent object
    parent.childItems = parent.childItems?.map(childItem => ({
      ...childItem,
      parent: { ...parent }
    }))
    return parent
  })

type DefaultTourTransformer = (tours: Tour[]) => Tour[]

export const transformDefaultTours: DefaultTourTransformer = tours =>
  tours.map(tour => {
    return {
      ...tour,
      isCustom: false,
      locations: transformLocations(tour.locations)
    }
  })

type LocationTransformer = (locs: WPLocation[]) => Location[]

export const transformLocations: LocationTransformer = locations =>
  locations.map(loc => ({
    ...loc,
    categories: sanitizeCategories(loc.categories),
    businessCategory: sanitizeCategories(loc.businessCategory),
    cordinates: {
      lat: Number(loc.cordinates.lat),
      lng: Number(loc.cordinates.lng)
    }
  }))

type CategoryTransformer = (categories: Category[]) => ExploreCategory[]
export const transformCategories: CategoryTransformer = (
  categories: Category[]
) =>
  categories
    .filter(cat => !cat.label.includes('Campus Tour'))
    .map(cat => ({
      name: cat.name.replace(/&amp;/g, '&'),
      icon: inferCategoryIcon(cat.label)
    }))

const inferCategoryIcon = (category: string) => {
  switch (category) {
    case 'Campus Highlights':
      return CampusHighlightsIcon
    case 'Services':
      return ServicesIcon
    case 'Entertainment':
      return EntertainmentIcon
    case 'Housing':
      return HousingIcon
    case 'Lodging':
      return LodgingIcon
    case 'Events':
      return EventsIcon
    case 'Food':
      return FoodIcon
    case 'Shopping':
      return ShoppingIcon
    case 'Financial':
      return FinancialIcon
    case 'Attractions':
      return AttractionsIcon
    default:
      return LodgingIcon
  }
}

const sanitizeCategories = (categories: Category[]) =>
  categories.map(cat => ({
    label: cat.label,
    name: decodeHTMLEntities(cat.name)
  }))

export const extractFiltersFromLocations = (locations: Location[]) => {
  const filters: string[] = ['All']
  locations.forEach(loc => {
    loc.tags.forEach(tag => {
      if (!filters.includes(tag)) {
        filters.push(tag)
      }
    })
  })
  return filters
}