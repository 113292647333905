//@flow
import useStoreon from 'storeon/react'
import { useMemo } from 'react'
import { ConfigState } from 'src/state/config'
import { useLocation } from 'react-router-dom'

export const useContentUrl = (section?: string): string => {
  const { config } = useStoreon<ConfigState>('config')
  const loc = useLocation()

  return useMemo<string>(() => {
    const contentUrl =
      (section || loc.pathname?.includes('tour-event')
        ? config?.urls.wordpress_content_url
        : config?.urls.wordpress_contact_page_url) || ''

    return contentUrl;
  }, [config, loc, section]);
}
