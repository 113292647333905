// @flow
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React, { ChangeEvent, FC, useState } from 'react'
import { Link } from 'src/components/atoms/links/Link'
import TextField from 'src/components/atoms/TextField'
import useStyles from 'src/components/molecules/AddTour/index.styles'

type AddTourProps = {
  onSubmit: (tourName: string) => void
}

export const AddTourForm: FC<AddTourProps> = ({ onSubmit }) => {
  const [tourName, setTourName] = useState('')
  const styles = useStyles({ tourName })

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTourName(e.target.value)
  }

  return (
    <form className={styles.form}>
      <div className={styles.formFields}>
        <TextField
          id="add-tour-field"
          label="Tour Name"
          onChange={onChange}
          value={tourName}
          data-test="add-tour-text-field"
        />
      </div>
      <div className={styles.formSubmit}>
        <Link
          className={styles.addTourPointsLink}
          to="."
          color="primary"
          disabled={tourName === ''}
          onClick={() => {
            onSubmit(tourName)
          }}
          data-test="add-tour-points-link"
        >
          Add Tour Points <ArrowForwardIosIcon className={styles.arrowIcon} />
        </Link>
      </div>
    </form>
  )
}
