import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorScreen } from 'src/components/atoms/ErrorScreen'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import { ConfigState } from 'src/state/config'
import { ErrorState } from 'src/state/errors'
import useStoreon from 'storeon/react'

export const ErrorPage: FC = () => {
  const history = useHistory()

  const { dispatch, showError, errorMessage, isDraftMode } = useStoreon<
    ConfigState & ErrorState
  >('showError', 'isDraftMode', 'errorMessage')

  return (
    <>
      <Header
        onBack={() => {
          history.replace(isDraftMode ? '/draft' : '/')
          dispatch('error/reset')
        }}
        title="Back to Main Screen"
        variant="DEFAULT"
      />
      <Content
        title="Error Message"
        description="Oops, looks like there was a bit of an issue."
      >
        <ErrorScreen visible={showError} message={errorMessage} />
      </Content>
    </>
  )
}
