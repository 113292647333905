// @flow
import { makeStyles } from '@material-ui/core/styles'
import { hexToRGBA } from 'src/theme'

export type DestinationCardStyleProps = {
  minimize?: boolean
}

export default makeStyles(theme => ({
  destinationCard: {
    position: 'fixed',
    minWidth: '100%',
    maxWidth: '100%',
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
    color: theme.palette.text.secondary,
    backgroundColor: '#FFF',
    boxShadow: '0px 7px 24px 0px rgba(41,41,41,0.46)'
  },
  minimize: {
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    top: 0,
    right: 0,
    color: 'white',
    transform: (props: DestinationCardStyleProps) =>
      props.minimize ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
    width: 'fit-content',
    paddingRight: 0,
    paddingLeft: 0,
    transition: 'transform 0.2s linear',
    boxShadow: 'none'
  },
  minimizeLabel: {
    width: 'fit-content'
  },
  media: {
    height: (props: DestinationCardStyleProps) => (props.minimize ? 50 : 140),
    transition: 'height 0.2s linear'
  },
  cardMediaContainer: {
    position: 'relative'
  },
  textBackground: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 65,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0.05) 100%)'
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '75%',
    left: theme.spacing(2),
    bottom: 0,
    height: 48,
    // @ts-ignore
    color: theme.palette.text.dark,
    fontWeight: 'bold',

    '& > svg': {
      marginLeft: theme.spacing(1)
    }
  },
  cardTitleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'white',
    color: theme.palette.grey[900],
    textTransform: 'none',
    textAlign: 'left',
    lineHeight: '115%',
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    width: 'fit-content',
    marginBottom: (props: DestinationCardStyleProps) =>
      props.minimize ? theme.spacing(0) : theme.spacing(3),
    transform: (props: DestinationCardStyleProps) =>
      props.minimize ? `scale(0.8)` : `scale(1)`,
    transformOrigin: 'left',
    fontSize: 18,
    '&:hover': {
      background: "#e0e0e0",
    },
    '& svg': {
      marginLeft: theme.spacing(1.5),
      width: 32,
      height: 32
    }
  },
  eventTitle: {
    fontWeight: 500,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.7),
    padding: theme.spacing(0.75),
    fontSize: 14,
    borderRadius: 5
  },
  cardContent: {
    margin: `${theme.spacing(0.75)}px 0`,
    padding: (props: DestinationCardStyleProps) =>
      props.minimize ? `${theme.spacing(1)}px 0` : `${theme.spacing(2)}px 0`
  },

  tourActions: {
    display: 'flex',
    justifyContent: 'center',
    // @ts-ignore
    color: theme.palette.text.subtext,
    width: '100%',
    // margin: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
    '& > button': {
      marginRight: theme.spacing(2)
    },
    '& > button:last-child': {
      marginRight: 0
    }
  }
}))
