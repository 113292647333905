// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  editTourList: {
    width: '100%',
    padding: 0,
    margin: 0
  }
}))
