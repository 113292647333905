// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

const MenuIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M23.5 4.5H0.500016C0.224016 4.5 0 4.72402 0 5.00002C0 5.27602 0.224016 5.50003 0.500016 5.50003H23.5C23.776 5.50003 24 5.27602 24 5.00002C24 4.72402 23.776 4.5 23.5 4.5Z" />
        <path d="M23.5 11.5H0.500016C0.224016 11.5 0 11.724 0 12C0 12.276 0.224016 12.5 0.500016 12.5H23.5C23.776 12.5 24 12.276 24 12C24 11.724 23.776 11.5 23.5 11.5Z" />
        <path d="M23.5 18.5H0.500016C0.224016 18.5 0 18.724 0 19C0 19.276 0.224016 19.5 0.500016 19.5H23.5C23.776 19.5 24 19.276 24 19C24 18.724 23.776 18.5 23.5 18.5Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
export default MenuIcon
