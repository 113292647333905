// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  mediaButton: {
    height: '100%',
    width: '100%'
  },
  locationMedia: {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: 3,
    color: theme.palette.secondary.main,
    background: '#212120'
  },
  wide: {
    flex: 2,
    height: 175
  },
  tall: {
    flex: 3,
    height: 250
  },
  carousel: {
    width: window.innerWidth,
    height: 300
  }
}))
