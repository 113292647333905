/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { TourList } from 'src/components/templates/tours/TourList'
import { AmbassadorState } from 'src/state/ambassadors'
import { ConfigState } from 'src/state/config'
import { TourState } from 'src/state/tours'
import useStoreon from 'storeon/react'

export const TourListPage: FC = () => {
  const history = useHistory()

  const {
    dispatch,
    activeTour,
    destination,
    customTours,
    defaultTours,
    isLoadingAmbassadors,
    isLoadingDefaultTours,
    config,
    logoUrl
  } = useStoreon<TourState & AmbassadorState & ConfigState>(
    'activeTour',
    'destination',
    'customTours',
    'defaultTours',
    'isLoadingAmbassadors',
    'isLoadingDefaultTours',
    'config',
    'logoUrl'
  )

  useEffect(() => {
    dispatch('tours/load')
    dispatch('ambassadors/load')
  }, [dispatch])

  const tours = customTours.concat(defaultTours)

  return (
    <>
      <TourList
        schoolIcon={logoUrl}
        surveyLink={config?.urls.post_tour_survey_url}
        allowCustomTours={config?.features.custom_tour}
        universityCoordinates={config?.location}
        universityName={config?.name || ''}
        tours={tours}
        destination={destination}
        activeTour={activeTour}
        setAmbassador={ambassador =>
          dispatch('tours/set-ambassador', ambassador)
        }
        setActiveTour={(activeTour, destination) =>
          dispatch('tours/set-active', {
            activeTour,
            destination
          })
        }
        onTourCreate={title => {
          dispatch('tours/save', {
            id: `${Date.now()}${Math.random()}`.replace('.', ''),
            isCustom: true,
            title,
            locations: []
          })
        }}
        // this actually refers to the current destination
        onTourClick={id => history.push(`/explore/location/${id}`)}
        setNavOpen={isHidden => dispatch('navigation/isHidden', isHidden)}
        isLoadingTours={isLoadingDefaultTours || isLoadingAmbassadors}
        onTourEdit={id => history.push(`/tours/edit/${id}`)}
      />
    </>
  )
}
