// @flow
import { makeStyles } from '@material-ui/core/styles'

export type AmbassadorHeaderStyleProps = {
  profileImage?: string
}

export default makeStyles(theme => ({
  tourButton: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  sectionTitle: {
    fontWeight: 500,
    paddingBottom: theme.spacing(0.5)
  },
  sectionBody: {
    // @ts-ignore
    color: theme.palette.text.nonselected
  },
  section: {
    zIndex: 1,
    width: '100%',
    maxWidth: '90vw',
    margin: `${theme.spacing(2)}px auto`
  },
  divider: {
    width: '100%',
    height: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    opacity: 0.3
  },
  image: {
    width: 200,
    height: 200,
    borderRadius: 200,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: (props: AmbassadorHeaderStyleProps) =>
      `url(${props.profileImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: `4px solid ${theme.palette.primary.main}`
  },
  arrowLeft: {
    marginRight: theme.spacing(2)
  },
  arrowRight: {
    marginLeft: theme.spacing(2),
    transform: 'rotate(180deg)'
  },
  name: {
    fontWeight: 500,
    marginTop: theme.spacing(3)
  },
  major: {
    // @ts-ignore
    color: theme.palette.text.nonselected
  }
}))
