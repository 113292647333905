import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  contentWrapper: {
    width: '100%',
    height: '100%'
  },
  mainMenu: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    width: '100%',
    height: `calc(100% - ${theme.spacing(4)}px)`
  }
}))
