// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const HousingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M23.7442 9.75301L12.3678 1.42346C12.1485 1.26287 11.8504 1.26287 11.6311 1.42346L0.254681 9.75301C-0.0231933 9.95649 -0.0835683 10.3467 0.119916 10.6246C0.3234 10.9024 0.713634 10.9627 0.991462 10.7593L11.9994 2.69945L23.0074 10.7593C23.1184 10.8406 23.2474 10.8797 23.3753 10.8797C23.5673 10.8797 23.7568 10.7914 23.8789 10.6245C24.0824 10.3467 24.0221 9.95649 23.7442 9.75301Z" />
        <path d="M20.7303 10.9005C20.386 10.9005 20.1067 11.1796 20.1067 11.524V21.4498H15.1182V16.0317C15.1182 14.3123 13.7193 12.9135 12.0001 12.9135C10.2808 12.9135 8.88193 14.3124 8.88193 16.0317V21.4498H3.89335V11.5241C3.89335 11.1797 3.61412 10.9005 3.26977 10.9005C2.92543 10.9005 2.64619 11.1797 2.64619 11.5241V22.0734C2.64619 22.4178 2.92543 22.697 3.26977 22.697H9.50551C9.83345 22.697 10.1018 22.4436 10.1267 22.122C10.1282 22.1074 10.1291 22.0915 10.1291 22.0734V16.0317C10.1291 15 10.9684 14.1607 12.0001 14.1607C13.0317 14.1607 13.871 15.0001 13.871 16.0317V22.0734C13.871 22.0914 13.872 22.107 13.8735 22.1214C13.898 22.4433 14.1665 22.697 14.4946 22.697H20.7303C21.0747 22.697 21.3539 22.4178 21.3539 22.0734V11.5241C21.3539 11.1796 21.0747 10.9005 20.7303 10.9005Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
