// @flow
import Axios from 'axios'
import { MAPBOX_ACCESS_TOKEN } from 'src/settings'
import { MapCoordinates } from 'src/utils/types'

export type GeoJson = {
  type: 'Feature'
  properties: {}
  geometry: {
    type: 'LineString'
    coordinates: MapCoordinates
  }
}

export const getRoute = async (
  start: MapCoordinates,
  destination: MapCoordinates
) => {
  const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${start.lng},${start.lat};${destination.lng},${destination.lat}`
  const { data } = await Axios.get(url, {
    params: {
      steps: true,
      geometries: 'geojson',
      overview: 'full',
      annotations: 'distance,duration',
      access_token: MAPBOX_ACCESS_TOKEN
    }
  })
  const routes = data.routes
  const geojson: GeoJson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: routes[0].geometry.coordinates
    }
  }

  return { geojson, duration: routes[0].duration, distance: routes[0].distance }
}

export const getAddress = async (lng: number, lat: number) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`
  console.log('fetching')
  const { data } = await Axios.get(url, {
    params: {
      access_token: MAPBOX_ACCESS_TOKEN
    }
  })
  console.log(data)
  return data
}
