// @flow
import { darken, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  mapContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  navModeButtonOff: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: 50,
    height: 50,
    border: 'none',
    borderRadius: 50,
    backgroundColor: theme.palette.primary.main,
    '&:active': {
      backgroundColor: darken(theme.palette.primary.main, 0.2)
    },
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
  },
  navModeButtonOn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: 50,
    height: 50,
    borderRadius: 50,
    border: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:active': {
      backgroundColor: darken(theme.palette.secondary.main, 0.2)
    },
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
  },
  centerButton: {
    position: 'absolute',
    top: theme.spacing(2) + 60,
    right: theme.spacing(2),
    width: 50,
    height: 50,
    borderRadius: 50,
    border: 'none',
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    '&:active': {
      backgroundColor: darken(theme.palette.primary.main, 0.2)
    },
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
  }
}))
