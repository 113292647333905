// @flow
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import useStyles from 'src/components/molecules/TripDataGroup/index.styles'

type DataPoint = {
  label: string
  value: string
}

type TripDataGroupProps = {
  dataPoints: DataPoint[]
  size?: 'small' | 'default'
}

const TripDataGroup: FC<TripDataGroupProps> = ({
  dataPoints,
  size = 'default'
}: TripDataGroupProps) => {
  const styles = useStyles()
  return (
    <Grid container direction="row" justify="space-around" wrap="nowrap">
      {dataPoints.map(({ label, value }, index) => (
        <Grid key={index} container item direction="column">
          <Typography
            className={styles.tripDataLabel}
            variant="body2"
            component="h2"
          >
            {label}
          </Typography>
          <Typography
            color="secondary"
            className={styles.tripDataValue}
            variant={size === 'small' ? 'caption' : 'h6'}
            component="h3"
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default TripDataGroup
