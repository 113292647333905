// @flow
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { FullScreenLoading } from 'src/components/atoms/loading/FullScreen'
import LocationMedia from 'src/components/molecules/LocationMedia'
import Carousel from 'src/components/organisms/Carousel'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/explore/MediaDetails/index.styles'
import { Media } from 'src/utils/types'

type MediaDetailsProps = {
  initialMedia?: Media
  media: Media[]
  isLoading?: boolean
  onBack?: () => void
}

export const sortMedia = (initialMedia: Media, media: Media[]): Media[] => {
  const match = media.find(item => item.id === initialMedia.id) || media[0]
  const nonMatches = media.filter(item => item.id !== initialMedia.id)
  return [match, ...nonMatches]
}

export const MediaDetails: FC<MediaDetailsProps> = ({
  initialMedia,
  media,
  isLoading,
  onBack
}) => {
  const styles = useStyles()
  const sortedMedia = initialMedia ? sortMedia(initialMedia, media) : []
  return (
    <>
      <FullScreenLoading loading={!!isLoading} />
      <Header onBack={onBack} title="Media" variant="DEFAULT" />
      <Content title="Media Details" description="Media Details">
        <div className={styles.carouselWrapper}>
          <Carousel itemWidth={window.innerWidth}>
            {sortedMedia.map((mediaItem, index) => (
              <div className={styles.mediaCarouselItem} key={index}>
                <LocationMedia
                  key={index}
                  url={mediaItem.url}
                  alt={mediaItem.description}
                  data-test="location-media"
                />
                <Typography className={styles.mediaDescription} variant="body1">
                  {mediaItem.description}
                </Typography>
              </div>
            ))}
          </Carousel>
        </div>
      </Content>
    </>
  )
}
