// @flow
import { makeStyles } from '@material-ui/core/styles'

export type ExploreMediaStyles = {
  gallery: string
  galleryRow: string
}

export default makeStyles(theme => ({
  gallery: {
    width: '100%',
    margin: '0 auto',
    padding: `0 ${theme.spacing(1)}px `
  },
  galleryRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1)
    },
    '& > div:last-child': {
      marginRight: 0
    }
  }
}))
