// @flow

import { useMemo } from 'react'
import ReactGA from 'react-ga4'

export const useAnalytics = () => {
  let enhancedAnalytics = null
  let trackPage = null
  const win = window as any
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataLayer = useMemo(() => win.dataLayer || [], [])

  if (window?.location.hostname !== 'localhost') {
    enhancedAnalytics = (args: ReactGA.EventArgs) => ReactGA.event(args)
    trackPage = (path: string) => {
      ReactGA.set({ page: path })
      ReactGA.event('page_view', path)
    }
  } else {
    enhancedAnalytics = (args: ReactGA.EventArgs) => {}
    trackPage = (path: string) => {}
  }

  return {
    dataLayer,
    analytics: { logEvent: enhancedAnalytics, trackPage }
  }
}
