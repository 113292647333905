// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  carouselWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center'
  },
  mediaDescription: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  mediaCarouselItem: {
    width: '100%'
  }
}))
