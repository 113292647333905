// @flow
import React, { FC } from 'react'
import useStyles, {
  UserMarkerStyles
} from 'src/components/molecules/UserMarker/index.styles'

type UserMarkerProps = {
  direction?: number
}
const UserMarker: FC<UserMarkerProps> = () => {
  const styles: UserMarkerStyles = useStyles()
  return (
    <div className={styles.markerWrapper}>
      <div className={styles.beaconRipple} />
      <div className={styles.marker} />
      <div className={styles.arrow} />
    </div>
  )
}
export default UserMarker
