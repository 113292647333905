// @flow
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const ServicesIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.2025 11.2874C16.2008 11.2874 16.1992 11.2874 16.1975 11.2874H14.1877V9.27259C14.1904 8.86624 13.8631 8.53457 13.4568 8.53185C13.4551 8.53185 13.4535 8.53185 13.4518 8.53185H10.5481C10.1418 8.53185 9.81234 8.8613 9.81234 9.26766V11.2825H7.80246C7.3961 11.2798 7.06444 11.6069 7.06172 12.0133C7.06172 12.0166 7.06172 12.0199 7.06172 12.0232V14.922C7.06172 15.3283 7.39117 15.6578 7.79752 15.6578H9.81234V17.6677C9.80963 18.074 10.1368 18.4057 10.5432 18.4084C10.5449 18.4084 10.5465 18.4084 10.5481 18.4084H13.4469C13.8533 18.4084 14.1827 18.079 14.1827 17.6726V15.6578H16.1975C16.6039 15.6605 16.9356 15.3333 16.9383 14.9269C16.9383 14.9252 16.9383 14.9236 16.9383 14.922V12.0232C16.9383 11.6169 16.6089 11.2874 16.2025 11.2874ZM15.9506 14.6701H13.6938C13.4211 14.6701 13.2 14.8912 13.2 15.164V17.4207H10.8V15.164C10.8 14.8912 10.5789 14.6701 10.3062 14.6701H8.04938V12.2751H10.3062C10.5789 12.2751 10.8 12.054 10.8 11.7812V9.51951H13.1951V11.7763C13.1951 12.049 13.4162 12.2701 13.6889 12.2701H15.9506V14.6701Z" />
      <path d="M20.5926 4.83802H16.5235V3.09975C16.5491 2.30654 15.9268 1.64271 15.1336 1.6171C15.0931 1.6158 15.0527 1.61617 15.0123 1.61827H8.98765C8.19506 1.57697 7.51907 2.18599 7.47772 2.97852C7.47562 3.01889 7.47519 3.05932 7.47654 3.09975V4.82815H3.40741C1.83704 4.82815 0 5.72197 0 8.23555V18.9763C0 21.4899 1.83704 22.3837 3.40741 22.3837H20.5926C22.163 22.3837 24 21.4899 24 18.9763V8.24543C24 5.73185 22.1679 4.83802 20.5926 4.83802ZM8.4642 3.09975C8.4642 2.95654 8.4642 2.57136 8.99259 2.57136H15.0123C15.5407 2.57136 15.5407 2.9516 15.5407 3.09975V4.82815H8.4642V3.09975ZM23.0123 18.9763C23.0123 21.159 21.3235 21.396 20.5926 21.396H3.40741C2.68148 21.396 0.987654 21.159 0.987654 18.9763V8.24543C0.987654 6.06271 2.67654 5.82568 3.40741 5.82568H20.5926C21.3185 5.82568 23.0123 6.06271 23.0123 8.24543V18.9763V18.9763Z" />
    </svg>
  </SvgIcon>
)
