import persistState from '@storeon/localstorage'
import { ambassadorModule, AmbassadorState } from 'src/state/ambassadors'
import { categoryModule, CategoryState } from 'src/state/categories'
import { configModule, ConfigState } from 'src/state/config'
import { errorModule, ErrorState } from 'src/state/errors'
import { locationModule, LocationState } from 'src/state/locations'
import { mediaModule, MediaState } from 'src/state/media'
import { menuModule, MenuState } from 'src/state/menus'
import { navbarModule, NavbarState } from 'src/state/navbar'
import { navigationModule, NavigationState } from 'src/state/navigation'
import { tourModule, TourState } from 'src/state/tours'
import createStore from 'storeon'
export type State = ErrorState &
  MenuState &
  LocationState &
  TourState &
  MediaState &
  NavigationState &
  CategoryState &
  AmbassadorState &
  ConfigState &
  NavbarState

export const store = createStore<any>([
  errorModule,
  configModule,
  menuModule,
  locationModule,
  ambassadorModule,
  tourModule,
  mediaModule,
  navigationModule,
  categoryModule,
  navbarModule,
  persistState(['customTours']),
  process.env.NODE_ENV === 'development' && require('storeon/devtools')
])
