//@flow

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { store } from 'src/state/store'

firebase.initializeApp(
  JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
)

export const firestore = firebase.firestore()
export const storage = firebase.storage()

let unsubscribe = () => { }

export const getSchoolConfig = async () => {
  // parse the sub domain from the current url
  const parseUrl = /:\/\/([^/]+)/.exec(window.location.href)
  if (!parseUrl?.length) {
    throw new Error('couldnt parse a valid sub domain from the current url')
  }

  const splitHost = window.location.hostname.split('.')

  // stage site has an extra prefix (ex. stage.umich.campusexperienceapp.com)
  const subdomain = window.location.hostname.includes('stage.')
    ? splitHost[1]
    : splitHost[0]

  const isLocal = window.location.hostname.includes('localhost')

  //get a reference to the school with the given subdomain
  const results = await firestore
    .collection('schools')
    .where('liveConfig.sub_domain', '==', isLocal ? 'uri' : subdomain)
    .get()

  // throw an error in case the subdomain doesn't match a firestore record
  if (!results.docs?.length) {
    throw new Error(
      `a school with subdomain ${subdomain || 'null'} does not exist`
    )
  }

  const configDoc = results.docs[0]

  // watch for configuration changes and update state when necessary
  unsubscribe = configDoc.ref.onSnapshot(snap => {
    store.dispatch('config/loaded', snap.data())
  })

  const data = configDoc.data();
  console.log(data);

  // const overrides = {
  //   redirect_url: 'https://campus.serveo.net/ung',
  //   wordpress_api_url: 'https://campus.serveo.net/ung',
  //   wordpress_content_url: 'https://campus.serveo.net/ung',
  // }

  // Object.assign(data.draftConfig.urls, overrides);
  // Object.assign(data.liveConfig.urls, overrides);

  return data;
}

window.onunload = () => {
  unsubscribe()
}