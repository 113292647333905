// @flow
import { makeStyles } from '@material-ui/core/styles'

export type TourListStyleProps = {
  toursExist?: boolean
  navigationOn?: boolean
  smallBackButton?: boolean
}

export default makeStyles(theme => ({
  backButton: {
    border: 'none !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    right: theme.spacing(4),
    width: 45,
    height: 45,
    borderRadius: 35,
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[6]
  },
  tourListWrapper: {
    display: (props: TourListStyleProps) =>
      props.toursExist ? 'block' : 'none',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    overflowY: 'visible',
    overflowX: 'auto',
    overflow: (props: TourListStyleProps) =>
      props.navigationOn ? 'initial' : 'auto',
    webkitOverflowScrolling: 'touch',
    padding: `${theme.spacing(4)}px 0`,
    zIndex: theme.zIndex.modal
  },
  tourList: {
    position: 'relative',
    display: 'inline-flex',
    minHeight: (props: TourListStyleProps) => (props.navigationOn ? 0 : 325),
    padding: `0 ${theme.spacing(3)}px`,
    '& > div': {
      marginRight: theme.spacing(3)
    },
    '& > div:last-child': {
      marginRight: 0
    }
  }
}))
