// @flow
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import useStyles from 'src/components/molecules/TourCard/index.styles'
import { PickAmbassador } from 'src/components/organisms/PickAmbassador'
import { AmbassadorState } from 'src/state/ambassadors'
import { ConfigState } from 'src/state/config'
import { NavigationEvents } from 'src/state/navigation'
import { TourState } from 'src/state/tours'
import { Ambassador, Location } from 'src/utils/types'
import useStoreon from 'storeon/react'

type TourCardProps = {
  tourId: string
  coverImage: string
  title: string
  description: string
  onTourStart: (id: string) => void
  onTourEdit: (id: string) => void
  isCustom?: boolean
  isVisible?: boolean
  isLoading?: boolean
  onAmbassadorSelect: (ambassador?: Ambassador) => void
}

const TourCard: FC<TourCardProps> = ({
  tourId,
  isCustom,
  coverImage,
  title,
  description,
  onTourStart,
  onTourEdit,
  isVisible,
  onAmbassadorSelect
}) => {
  const styles = useStyles()
  const [openAmbassadors, setOpenAmbassadors] = useState(false)

  const {
    dispatch,
    ambassadorsByTour,
    activeAmbassador,
    config
  } = useStoreon<AmbassadorState & TourState & ConfigState & NavigationEvents>(
    'ambassadorsByTour',
    'activeAmbassador',
    'navigation/isHidden',
    'config'
  )
  useEffect(() => {
    dispatch('ambassadors/load/by-tour', tourId)
  }, [dispatch, tourId])

  const ambassadorsFilteredByTour: Ambassador[] = ambassadorsByTour[tourId] ?? [];
  const multipleAmbassadors = ambassadorsFilteredByTour.length > 1;

  const isGlenwood = config?.sub_domain === 'glenwoodsprings'

  return (
    <>
      {isVisible && !openAmbassadors && (
        <Card className={styles.card}>
          {/* the ts-ignore is here to force the alt onto the CardMedia */}
          {/* @ts-ignore */}
          <CardMedia
            className={styles.cardMedia}
            image={coverImage}
            title={''}
            alt=""
          />

          <CardContent className={styles.cardContent}>
            <Typography
              className={styles.cardTitle}
              color="inherit"
              variant="h6"
              component="h1"
            >
              {title}
            </Typography>
            <Typography className={styles.cardBody} variant="body2">
              {activeAmbassador
                ? `${activeAmbassador.name} will guide you on your tour.`
                : description}
            </Typography>
          </CardContent>
          <div className={styles.tourActions}>
            {isCustom && (
              <Button color="subtext" onClick={() => onTourEdit(tourId)}>
                EDIT TOUR
              </Button>
            )}
            <Button
              className={classNames(styles.actionButton, 'start-tour')}
              variant="contained"
              color="primary"
              onClick={() => onTourStart(tourId)}
              // disabled={!ambassadorsFilteredByTour.length}
            >
              {!!activeAmbassador || isGlenwood || !multipleAmbassadors
                ? 'START TOUR'
                : 'TOUR WITH ALL'}
            </Button>
            {!isGlenwood && multipleAmbassadors && (
              <Button
                // disabled={!ambassadorsFilteredByTour.length}
                className={styles.actionButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                  dispatch('navigation/isHidden', true)
                  setOpenAmbassadors(true)
                }}
              >
                {activeAmbassador ? 'CHANGE AMBASSADOR' : 'PICK AMBASSADOR'}
              </Button>
            )}
          </div>
        </Card>
      )}
      <PickAmbassador
        isOpen={openAmbassadors}
        ambassadors={ambassadorsFilteredByTour}
        onClose={() => {
          dispatch('navigation/isHidden', false)
          setOpenAmbassadors(false)
        }}
        onAmbassadorSelect={onAmbassadorSelect}
      />
    </>
  )
}

export const getTourDesc = (locations?: Location[]) => {
  const desc = locations
    ?.slice(0, 3)
    .map(loc => loc.name)
    .join(', ')
  return `${String(desc)}...`
}

export default TourCard