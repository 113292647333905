// @flow
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { Flex } from 'src/components/molecules/Flex'
import useStyles from 'src/components/organisms/PushNotification/index.styles'

type PushNotificationProps = {
  visible?: boolean
  message: string
  primaryAction: () => void | Promise<void>
  secondaryAction: () => void | Promise<void>
  primaryText?: string
  secondaryText?: string
}

export const PushNotification: FC<PushNotificationProps> = ({
  visible,
  message,
  primaryAction,
  secondaryAction,
  primaryText = 'Allow',
  secondaryText = 'Ignore'
}) => {
  const styles = useStyles()
  return (
    <div className={styles.notifRoot}>
      <Slide direction="down" in={visible} mountOnEnter unmountOnExit>
        <Box boxShadow={3} p={2} pb={1} className={styles.notifBody}>
          <Typography variant="body2" style={{ paddingBottom: 8 }}>
            {message}
          </Typography>
          <Flex container justifyContent="flex-end">
            <Button
              size="small"
              onClick={secondaryAction}
              style={{ marginRight: 12 }}
            >
              {secondaryText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={primaryAction}
            >
              {primaryText}
            </Button>
          </Flex>
        </Box>
      </Slide>
    </div>
  )
}
