// @flow
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import useStyles from 'src/components/organisms/ImageList/index.styles'

type ImageListItemProps = {
  onClick?: () => void
  fullHeight?: boolean
  coverImage: string
  label: string
}

export const ImageListItem: FC<ImageListItemProps> = ({
  onClick,
  label,
  coverImage,
  fullHeight
}) => {
  const styles = useStyles({ fullHeight, coverImage })
  return (
    <li>
      <CardActionArea className={styles.item} onClick={onClick}>
        <div className={styles.itemOverlay} />
        <Typography variant="h4" component="h2" className={styles.itemLabel}>
          {label}
        </Typography>
      </CardActionArea>
    </li>
  )
}

type ImageListProps = {
  children?: any
}

export const ImageList = React.forwardRef<HTMLUListElement, ImageListProps>(
  ({ children }, ref) => {
    const styles = useStyles()
    return (
      <ul ref={ref} className={styles.imageListContainer}>
        {children}
      </ul>
    )
  }
)
