// @flow
import { OutlinedInputClassKey } from '@material-ui/core'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import React, { FC } from 'react'
import useStyles from 'src/components/atoms/TextField/index.styles'

type InputClassProps = Partial<Record<OutlinedInputClassKey, string>>

const TextField: FC<TextFieldProps> = ({ id, ...props }) => {
  const styles = useStyles()
  return (
    <MuiTextField
      {...props}
      id={id}
      InputProps={{
        classes: ({
          input: styles.input,
          underline: styles.underline
        } as unknown) as InputClassProps
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
          shrink: styles.shrink,
          animated: styles.animated
        }
      }}
    />
  )
}
export default TextField
