// @flow
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px'
  },
  paragraph: {
    padding: '8px 0'
  },
  confirmButton: {
    marginTop: theme.spacing(2),
    width: '80%'
  }
}))
