import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  header: {
    color: '#FFF',
    paddingBottom: theme.spacing(0.5)
  },
  subheader: {
    // @ts-ignore
    color: theme.palette.text.nonselected,
    paddingBottom: theme.spacing(3)
  }
}))
