// @flow
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { FC, useState } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import { AmbassadorHeader } from 'src/components/organisms/AmbassadorHeader'
import Carousel from 'src/components/organisms/Carousel'
import useStyles from 'src/components/organisms/PickAmbassador/index.styles'
import { Ambassador } from 'src/utils/types'
type PickAmbassadorProps = {
  isOpen: boolean
  ambassadors: Ambassador[]
  onAmbassadorSelect: (ambassador?: Ambassador) => void
  onClose: () => void
}

export const PickAmbassador: FC<PickAmbassadorProps> = ({
  isOpen,
  onClose,
  ambassadors,
  onAmbassadorSelect
}) => {
  const styles = useStyles()
  const [activeAmbassador, setActiveAmbassador] = useState(0)

  return (
    <Dialog
      PaperProps={{ className: styles.ambassadorDialog }}
      id="select-ambassador-modal"
      onClose={onClose}
      fullScreen
      open={isOpen}
    >
      <DialogTitle disableTypography className={styles.dialogTitle}>
        Ambassadors
        <Button
          removeMargin
          color="inherit"
          onClick={() => {
            onAmbassadorSelect(undefined)
            onClose()
          }}
          className={styles.tourWithAllButton}
        >
          TOUR WITH ALL
        </Button>
      </DialogTitle>
      <Carousel
        key="category-carousel"
        initialTarget={activeAmbassador}
        itemWidth={400}
        onChange={index => setActiveAmbassador(index)}
      >
        {ambassadors.map((ambassador, i) => (
          <AmbassadorHeader
            key={ambassador.id}
            ambassador={ambassador}
            onAmbassadorSelect={val => {
              onAmbassadorSelect(val)

              onClose()
            }}
          />
        ))}
      </Carousel>
    </Dialog>
  )
}
