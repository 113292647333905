// @flow
import MuiButton from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CallOutlinedIcon from '@material-ui/icons/CallOutlined'
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LanguageIcon from '@material-ui/icons/Language'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import React, { FC, useState } from 'react'
import { Button } from 'src/components/atoms/buttons/Button'
import LocationAction from 'src/components/atoms/buttons/LocationAction'
import LocationPhoto from 'src/components/molecules/LocationMedia'
import { AmbassadorVideo } from 'src/components/organisms/AmbassadorVideo'
import Carousel from 'src/components/organisms/Carousel'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/explore/ExploreDetails/index.styles'
import Placeholder from 'src/images/image-placeholder.jpg'
import { Ambassador, Location, Media, Video } from 'src/utils/types'

type ExploreDetailsProps = {
  isGlenwood?: boolean
  locationItem?: Location
  allowCustomTours?: boolean
  media?: Media[]
  videos?: Video[]
  isLoading?: boolean
  onAddClick?: () => void
  hasActiveTour?: boolean
  onRemoveClick?: () => void
  onMediaClick: (id: string) => void
  ambassadors?: Ambassador[]
  activeAmbassador?: Ambassador
  onBack?: () => void
}

export const ExploreDetails: FC<ExploreDetailsProps> = ({
  media,
  videos,
  onBack,
  isGlenwood,
  isLoading,
  onAddClick,
  onRemoveClick,
  allowCustomTours,
  ambassadors,
  activeAmbassador,
  hasActiveTour,
  onMediaClick,
  locationItem
}) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | typeof undefined
  >()
  const [selectedAmbassadorId, setSelectedAmbassadorId] = useState<
    number | undefined
  >()
  if (!locationItem || isLoading) {
    return <div />
  }
  const {
    name = '',
    website,
    phone,
    email,
    cordinates,
    eventDescription,
    eventTitle,
    description = ''
  } = locationItem

  const onCloseAmbassadorDropdown = () => setAnchorEl(undefined)

  const filterVideos = (video: Video) =>
    selectedAmbassadorId
      ? video.ambassadorId === selectedAmbassadorId
      : activeAmbassador
      ? video.ambassadorId === activeAmbassador.id
      : true

  const selectedAmbassador = ambassadors?.find(
    ambassador => ambassador.id === selectedAmbassadorId
  )

  const ambassadorsWithVideos = ambassadors?.filter(ambassador =>
    videos?.find(vid => vid.ambassadorId === ambassador.id)
  )

  return (
    <>
      <Header
        onBack={onBack}
        title={hasActiveTour ? 'Back to Tour' : 'Explore'}
        variant="TRANSPARENT"
        className={styles.detailedHeader}
      />
      <Content
        title={`Explore: ${name}`}
        description={`Get directions, contact information, and more for ${name}.`}
      >
        <Carousel
          hideButtons
          key="media-carousel"
          itemWidth={window.innerWidth}
        >
          {media?.length ? (
            media?.map((mediaItem, index) => (
              <LocationPhoto
                key={index}
                url={mediaItem.url}
                alt={mediaItem.description}
                onClick={() => onMediaClick(mediaItem.id)}
              />
            ))
          ) : (
            <LocationPhoto url={Placeholder} alt="Alt text" />
          )}
        </Carousel>

        <Typography className={styles.locationName} variant="h3" component="h1">
          {name}
        </Typography>
        <div className={styles.mainActions}>
          {phone && (
            <LocationAction
              disabled={!phone}
              onClick={() => {
                window.open(`tel:${phone}`, '_self')
              }}
              Icon={CallOutlinedIcon}
              label="Call"
            />
          )}
          {cordinates.lat && cordinates.lng && (
            <LocationAction
              disabled={!cordinates.lat || !cordinates.lng}
              onClick={() => {
                window.open(
                  `maps://maps.google.com/maps?daddr=${cordinates.lat},${cordinates.lng}&amp;ll=`
                )
              }}
              Icon={DirectionsOutlinedIcon}
              label="Directions"
            />
          )}
          {website && (
            <LocationAction
              disabled={!website}
              onClick={() => {
                window.location.href = website
              }}
              Icon={LanguageIcon}
              label="Website"
            />
          )}

          {email && (
            <LocationAction
              disabled={!email}
              onClick={() => {
                window.location.href = `mailto:${String(
                  email
                )}?subject=Student%Inquiry%20here`
              }}
              Icon={MailOutlineIcon}
              label="Email"
            />
          )}
        </div>
        {!hasActiveTour && allowCustomTours ? (
          <div className={styles.tourActions}>
            <Typography
              className={styles.tourActionsHeader}
              variant="body1"
              component="h2"
            >
              TOUR ACTIONS
            </Typography>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={onRemoveClick}
                >
                  REMOVE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAddClick}
                  aria-label="add"
                >
                  ADD
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}
        {eventTitle ? (
          <div className={styles.descriptionWrapper}>
            <Typography
              className={styles.tourActionsHeader}
              variant="body1"
              component="h2"
            >
              {eventTitle.toUpperCase()}
            </Typography>
            <Typography
              className={styles.description}
              variant="body2"
              dangerouslySetInnerHTML={{ __html: eventDescription }}
            />
          </div>
        ) : (
          ''
        )}
        {videos?.length ? (
          <>
            <div className={styles.videos}>
              {!isGlenwood && (
                <>
                  <Typography
                    className={styles.tourActionsHeader}
                    variant="body1"
                    component="h2"
                  >
                    AMBASSADOR VIDEOS
                  </Typography>
                  <MuiButton
                    onClick={e => setAnchorEl(e.currentTarget)}
                    aria-controls="ambassador-video-filter"
                    aria-haspopup="true"
                    className={styles.ambassadorDropdown}
                    variant="outlined"
                    color="primary"
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {selectedAmbassador?.name ||
                      activeAmbassador?.name ||
                      'Choose Ambassador'}
                  </MuiButton>{' '}
                </>
              )}

              {videos?.filter(filterVideos).map(vid => (
                <AmbassadorVideo
                  key={vid.id}
                  title={`${vid.title} - ${name}`}
                  thumbnail={vid.thumbnail}
                  desc={vid.description}
                  url={vid.url}
                />
              ))}
            </div>
            <Menu
              id="ambassador-video-filter"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={onCloseAmbassadorDropdown}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  setSelectedAmbassadorId(undefined)
                  onCloseAmbassadorDropdown()
                }}
                style={{ color: 'black' }}
              >
                All
              </MenuItem>
              {ambassadorsWithVideos?.map(ambassador => (
                <MenuItem
                  key={ambassador.id}
                  onClick={() => {
                    setSelectedAmbassadorId(ambassador.id)
                    onCloseAmbassadorDropdown()
                  }}
                  style={{ color: 'black' }}
                >
                  {ambassador.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          ''
        )}
        {description ? (
          <div className={styles.descriptionWrapper}>
            <Typography
              className={styles.tourActionsHeader}
              variant="body1"
              component="h2"
            >
              DESCRIPTION
            </Typography>
            <Typography className={styles.description} variant="body2">
              {description || 'No description for this content.'}
            </Typography>
          </div>
        ) : (
          ''
        )}
      </Content>
    </>
  )
}
